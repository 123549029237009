import {IField} from "../components/dataset/IField";


export interface IPpapDetail18 {
    ppapno: string;
    re_01: boolean;
    re_02: boolean;
    re_03: boolean;
    re_04: boolean;
    re_05: boolean;
    re_06: boolean;
    re_07: boolean;
    re_08: boolean;
    re_09: boolean;
    re_10: boolean;
    re_11: boolean;
    note_declarable: boolean;
    note_regulations: boolean;
    comment_note: string;
    comment1: string;
    comment2: string;
    supplierdrawingno: string;
    supplierpartno: string;
    supplierdate: string;
    ppap_warrant_disposition: number;
    ppap_warrant_comment: string;
}


export const ppapDetail18Columns: IField<IPpapDetail18> [] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDERNO',
        primaryKey: true,
        rules: ['pk', 'mandatory'],
        insert: 'hide'
    },
    {
        fieldName: 're_01',
        dataType: 'boolean',

        label: ''
    },
    {
        fieldName: 're_02',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_03',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_04',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_05',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_06',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_07',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_08',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_09',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_10',
        dataType: 'boolean',
        label: ''
    },
    {
        fieldName: 're_11',
        dataType: 'boolean',
        input: 'checkbox',
        label: ''
    },
    {
        fieldName: 'comment1',
        dataType: 'string',
        label: ''
    },
    {
        fieldName: 'comment2',
        dataType: 'string',
        label: ''
    }
];