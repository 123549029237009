import * as React from 'react';



export class NotFoundView extends React.Component{
    render(){
        return(
            <h1 style={{color:'black'}}>Not Found (404)</h1>
        )
    }
}