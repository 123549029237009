import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from 'classnames';
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {ISidebarEntry} from "./SidebarMenu";


@inject('rootStore')
@observer
export class SidebarItem extends React.Component<ISidebarEntry, {}> {

    @observable
    tooltipOpen: boolean = false;

    @action.bound
    toggleTooltip() {
        this.tooltipOpen = !this.tooltipOpen;
    }

    onClick = () => {
        const {rootStore: {appStore: {navigate, closeSidebar}}, route, toggle} = this.props;
        if (toggle) {
            toggle();
        } else {
            navigate(route.name);
            closeSidebar();
        }
    };


    render() {
        const {rootStore: {routerStore, langStore: {lang}}, route, isOpen,className} = this.props;

        const classes = classNames({
            'selected': routerStore.routerState.routeName === route.name
        },className);

        const icon = isOpen ? "angle-down" : "angle-right";

        return (
            <>
                <li id={route.name} className={classes} onClick={this.onClick}>
                    <div className="icon">
                        {route.icon ?
                            <FontAwesomeIcon icon={["fad", route.icon]}/> : null
                        }
                    </div>

                    <div className="label"> {lang[route.label]}</div>
                    {route.children ?
                        <FontAwesomeIcon className="indicator" icon={icon}/> :
                        <div className="indicator"/>}
                </li>

            </>

        )
    }
}