import {BaseStore, IBaseStore} from "./base-store";
import {IProject, projectColumns} from "../models/Project";
import {action, computed, observable, runInAction} from "mobx";
import {Dataset} from "../components/dataset/dataset";
import {IProjectActivity, projectActivityColumns, ProjectActivityTyp} from "../models/ProjectActivity";
import {actionColumns, ActionTyp, IAction, projectActionColumns} from "../models/Action";
import {dsState, IDataset} from "../components/dataset/IDataset";
import moment from "moment";
import {IProjectDocument, projectDocumentColumns} from "../models/ProjectDocument";
import {ISupplier, supplierColumns} from "../models/Supplier";
import {customerColumns, ICustomer} from "../models/Customer";
import axios from "axios";
import {IRootStore} from "../routes/root-store";
import {RouterState} from "mobx-state-router";
import * as R from "ramda";
import {Status, STATUS_ALL} from "../models/Status";
import {enumToArray} from "../components/lib/enumToArray";
import {IProjectMember, projectMemberColumns} from "../models/ProjectMember";


export interface IProjectStore extends IBaseStore<IProject> {
    dsProjectMember: IDataset<IProjectMember>;
    dsProjectActivity: IDataset<IProjectActivity>;
    dsAction: IDataset<IAction>;
    dsProjectDocument: IDataset<IProjectDocument>;
    dsSupplier: IDataset<ISupplier>;
    dsCustomer: IDataset<ICustomer>;
    //
    documentByElementFilter: boolean;
    toggleDockumentFilter: () => Promise<void>;
    //
    actionByActivityFilter: boolean;
    toggleActionFilter: () => Promise<void>;
    //
    uploadFile: (acceptFile: any[]) => Promise<void>;
    nextActivityno: () => string;
    canCreateDevelopment: boolean;
    canDeleteActivity: boolean;
    createDevelopment: () => Promise<void>;
    saveOwner: string;
    statusActivityOptionsFunc: () => number[];

    dsExtraProject: IDataset<IProject>;
    importMemberfromProject: (projectno: string) => Promise<void>;


}


export class ProjectStore extends BaseStore<IProject> {

    /**
     *  dient als Zwischenspeicher des aktuellen Owners, wenn Form in Edit Mode geht und der Owner geändert wird.
     */
    saveOwner: string;

    @action.bound
    dsOnAfterInsert(ds: IDataset<IProject>) {
        const {authStore: {username}} = this.rootStore;
        ds.actual.createdby = username;
        ds.actual.createdat = moment().format();
    }


    @action.bound
    dsOnAfterEdit(ds: IDataset<IProject>) {
        this.saveOwner = ds.actual.owner;
    }

    //
    @observable
    dsProjectMember: IDataset<IProjectMember>;

    //

    @observable
    dsProjectActivity: Dataset<IProjectActivity>;

    /**
     *  Sucht die nächste freie Activityno basierend auf der letzten
     *  * Dazu wird 10 aufgeschlagen
     *  Rückgabe als 00x0
     */
    nextActivityno = () => {
        let x: number = (this.dsProjectActivity.data
            .filter((d) => {
                return !isNaN(parseInt(d.activityno))
            })
            .reduce((accu, d) => {
                return Number(d.activityno) > accu ? Number(d.activityno) : accu
            }, 0));

        return (Math.floor(x / 10) * 10 + 10).toString().padStart(4, '0');
    }


    @action.bound
    dsProjectActivityOnAfterInsert(ds: IDataset<IProjectActivity>) {
        const {authStore: {username}} = this.rootStore;
        ds.actual.activityno = this.nextActivityno();
        ds.actual.createdby = username;
        ds.actual.createdat = moment().format();
    };


    @observable
    dsAction: Dataset<IAction>;

    @action.bound
    dsActionOnAfterInsert(ds: IDataset<IAction>) {
        const {authStore: {username}} = this.rootStore;
        ds.actual.typ = ActionTyp.PROJECTTask;
        ds.actual.createdby = username;
        ds.actual.createdat = moment().format();
        ds.actual.key1 = this.dsProjectActivity.actual.projectno;
        ds.actual.key2 = this.dsProjectActivity.actual.activityno;
        ds.actual.projectno = this.dsProjectActivity.actual.projectno;
        ds.actual.activityno = this.dsProjectActivity.actual.activityno;
    }


    @observable
    dsProjectDocument: IDataset<IProjectDocument>;


    @action.bound
    dsProjectDocumentonAfterOpen(ds: IDataset<IProjectDocument>) {
        ds.data = ds.data.slice().sort((a, b) => {
            if (a.activityno < b.activityno) {
                return -1
            }
            if (a.activityno > b.activityno) {
                return -1
            }
            if (parseInt(a.documentno) < parseInt(b.documentno)) {
                return -1
            }
            if (parseInt(a.documentno) > parseInt(b.documentno)) {
                return 1
            }
            return 0
        })
    }


    @observable
    dsSupplier: IDataset<ISupplier>;

    @observable
    dsCustomer: IDataset<ICustomer>;

    @observable
    documentByElementFilter: boolean = true;

    @observable
    actionByActivityFilter: boolean = true;

    @observable
    dsExtraProject: IDataset<IProject>;


    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/project/', projectColumns);
        //

        //
        this.ds.onAfterInsert = this.dsOnAfterInsert;
        this.ds.onAfterEdit = this.dsOnAfterEdit;

        // Für den Statusfilter STATUS_ALL hinzufügen
        let status = this.cdsFilter.columns
            .find(column => column.fieldName === 'status');
        status.options = enumToArray(Status);
        status.options.unshift(STATUS_ALL);
        status.defaultValue = STATUS_ALL;
        //
        this.dsProjectMember = new Dataset<IProjectMember>('/gridApi/projectmember/', R.clone(projectMemberColumns));
        this.dsProjectMember.setMasterSource(this.ds, [{field: 'projectno', masterField: 'projectno'}]);

        //
        this.dsProjectActivity = new Dataset<IProjectActivity>('/gridApi/projectactivity/', projectActivityColumns);
        this.dsProjectActivity.setMasterSource(this.ds, [{field: 'projectno', masterField: 'projectno'}]);
        this.dsProjectActivity.onAfterInsert = this.dsProjectActivityOnAfterInsert;

        //
        this.dsAction = new Dataset<IAction>('/gridApi/action/', projectActionColumns);
        this.dsAction.setMasterSource(this.dsProjectActivity, this.actionFilter);
        this.dsAction.onAfterInsert = this.dsActionOnAfterInsert;
        //
        this.dsProjectDocument = new Dataset<IProjectDocument>('/gridApi/projectdocument/', projectDocumentColumns);
        this.dsProjectDocument.setMasterSource(this.dsProjectActivity, this.documentFilter);
        this.dsProjectDocument.onAfterOpen = this.dsProjectDocumentonAfterOpen;

        this.dsSupplier = new Dataset<ISupplier>('/gridApi/supplier/', supplierColumns);
        this.dsSupplier.setMasterSource(this.ds, [
            {
                field: 'supplierno',
                masterField: 'supplierno'
            }
        ]);

        this.dsCustomer = new Dataset<ICustomer>('/gridApi/customer/', customerColumns);
        this.dsCustomer.setMasterSource(this.ds, [
            {
                field: 'customerno',
                masterField: 'customerno'
            }
        ]);


        this.dsExtraProject = new Dataset<IProject>('/gridApi/project/', projectColumns);

    }

    @action.bound
    async openDetails() {
        this.saveOwner = '';
        // Projectmember Auswahl auf aktuelles Project setzen.
        this.dsProjectMember.columns.find(column => column.fieldName === 'username').selectdlg.url = '/gridApi/projectuser/' + this.ds.actual?.projectno;
        // Projectmember Auswahl auf aktuelles Project setzen
        this.dsProjectActivity.columns.find(column => column.fieldName === 'responsible').selectdlg.filter = {projectno: this.ds.actual.projectno};

        await this.dsProjectMember.open();
        await this.dsSupplier.open();
        await this.dsCustomer.open();
        await this.dsAction.open();
        await this.dsProjectActivity.open();
        await this.dsProjectDocument.open();
    }

    @action.bound
    closeDetails() {
        this.saveOwner = '';
        this.dsAction.close();
        this.dsProjectDocument.close();
        this.dsProjectActivity.close();
        this.dsCustomer.close();
        this.dsSupplier.close();
        this.dsProjectMember.close();
    }

    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        this.dsCheck.filter = {projectno: toState.params.projectno};
        await this.dsCheck.open();
        const rv: boolean = this.dsCheck.data.length === 1;
        this.dsCheck.close();
        return rv;
    }

    /**
     * Beim Rücksprung von Action zum Project, fehlt die Activityno.
     * Um den Cursor der Activitäten richtig setzen zu können, brauchen wir diese aber.
     *
     * @param no
     */
    @action.bound
    async locatefromAction(no: string) {
        let tempAction = new Dataset<IAction>('/gridApi/action/', actionColumns);
        tempAction.filter = {no: no}
        await tempAction.open();
        this.dsProjectActivity.locate(['activityno'], {activityno: tempAction.actual.activityno});
        await tempAction.close();
        this.dsAction.locate(['no'], {no: no})
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.initializeAsync();
        await this.loadFilter('project');
        switch (toState.routeName) {
            case 'projecttable':
                await this.open(this.cdsFilter.actual);
                if (fromState.routeName === 'project') {
                    this.ds.locate(this.ds.pkFields, fromState.params)
                }
                break;
            case 'project':
                await this.open(toState.params as any);
                await this.openDetails();

                switch (fromState.routeName) {
                    case 'projectactivity':
                        this.dsProjectActivity.locate(this.dsProjectActivity.pkFields, fromState.params);
                        break;
                    case 'audit':
                        this.dsProjectActivity.locate(['link'], {link: fromState.params.auditno});
                        break;
                    case 'ppaporder':
                        this.dsProjectActivity.locate(['link'], {link: fromState.params.orderno});
                        break;
                    case 'action':
                        await this.locatefromAction(fromState.params.no)
                        break;
                    case 'inspection':
                        this.dsProjectActivity.locate(['link'], {link: fromState.params.inspectionno});
                        break;
                }
                // aus richtung User kommend....
                if (toState.queryParams.activityno) {
                    this.dsProjectActivity.locate(['activityno'], {activityno: toState.queryParams.activityno});
                    break;
                }
                break;
            case 'projectactivity':
                await this.open(R.pick(this.ds.pkFields as string[], toState.params) as any);
                await this.openDetails();
                this.dsProjectActivity.locate(this.dsProjectActivity.pkFields, toState.params);
                break;
        }
    }


    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'projectactivity') {
            /*if (this.dsProjectActivity.state === dsState.dsEdit) {
                this.dsProjectActivity.cancel();
            }*/
            /*if (this.rootStore.authStore.username !== '') {
                await this.dsProjectActivity.post();
            }*/
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'project') {
            if (this.ds.state === dsState.dsEdit) {
                this.ds.cancel();
            }
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'projecttable') {
            this.close();
            await Promise.resolve();
        }
    }

    @action.bound
    async toggleDockumentFilter() {
        this.documentByElementFilter = !this.documentByElementFilter;
        this.dsProjectDocument.setMasterSource(this.dsProjectActivity, this.documentFilter);
        await this.dsProjectDocument.refresh(0);
    }


    @computed
    get documentFilter() {
        if (this.documentByElementFilter) {
            return [
                {
                    field: 'projectno',
                    masterField: 'projectno'
                },
                {
                    field: 'activityno',
                    masterField: 'activityno'
                }
            ]
        } else {
            return [
                {
                    field: 'projectno',
                    masterField: 'projectno'
                }
            ]
        }
    }

    @action.bound
    async uploadFile(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/upload/projectdocument';
        const file = acceptFile[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectno', this.dsProjectActivity.actual.projectno);
        formData.append('activityno', this.dsProjectActivity.actual.activityno.toString());
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        await axios.post(url, formData, config);
        runInAction(async () => {
            //await this.dsProjectActivity.refresh(this.dsProjectActivity.cursor);
            await this.dsProjectDocument.refresh(-1);
            this.dsProjectDocument.last();

        })
    }

    @computed
    get actionFilter() {
        if (this.actionByActivityFilter) {
            return [
                {
                    field: 'projectno',
                    masterField: 'projectno'
                },
                {
                    field: 'activityno',
                    masterField: 'activityno'
                }

            ]
        } else {
            return [
                {
                    field: 'projectno',
                    masterField: 'projectno'
                },
            ]
        }
    }

    @action.bound
    async toggleActionFilter() {
        this.actionByActivityFilter = !this.actionByActivityFilter;
        this.dsAction.setMasterSource(this.dsProjectActivity, this.actionFilter);
        await this.dsAction.refresh(0);
    }

    /**
     * kopiert die aktuelle Activity
     *
     */
    @action.bound
    async createDevelopment() {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('id_token')}`
            }
        };
        const nextActivityno = (parseInt(this.dsProjectActivity?.actual.activityno) + 5).toString().padStart(4, '0')

        await axios.post('/gridApi/copy/projectactivity/' + this.dsProjectActivity.actual.projectno + '/' + this.dsProjectActivity.actual.activityno + '/' + nextActivityno, undefined, config);
        await this.dsProjectActivity.refresh(this.dsProjectActivity.cursor);
        this.dsProjectActivity.last();
    }


    /**
     * Überprüfung ob activityno clonable ist, dh. cativityno durch 10 teilbar
     *
     *
     */
    @computed
    get canCreateDevelopment(): boolean {
        if (!this.dsProjectActivity.actual || this.dsProjectActivity.actual?.activitytyp !== ProjectActivityTyp.AUDIT || this.dsProjectActivity.actual?.status !== Status.APPROVED) {
            return false;
        }
        return !this.dsProjectActivity.data.find((activity => this.dsProjectActivity.actual.activityno === activity.refactivityno));
    }

    @computed
    get canDeleteActivity(): boolean {
        return !!!this.dsProjectActivity.data.find((activity => this.dsProjectActivity.actual?.activityno === activity.refactivityno));
    }

    statusActivityOptionsFunc = () => {
        const {authStore: {username}} = this.rootStore;
        if (username === this.ds.actual?.owner) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED, Status.NOTAPPROVED, Status.APPROVED];
        } else if (username === this.dsProjectActivity.actual?.responsible) {
            return [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
        } else {
            return []
        }
    }

    @action.bound
    async importMemberfromProject(projectno: string): Promise<void> {

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('id_token')}`
            }
        };
        const url = '/gridApi/copy/projectmember/' + projectno + '/' + this.ds.actual.projectno;
        await axios.post(url, undefined, config);
        await runInAction(async () => {
            await this.dsProjectMember.refresh(this.dsProjectMember.cursor);
        });
    }


}
