import {IField} from "../components/dataset/IField";


export interface IUsergroup {
    usergroupno: string;
    [key: string]: string | number;
}

export const usergroupColumns: IField<IUsergroup>[] = [
    {
        fieldName: 'usergroupno',
        dataType: 'string',
        label: 'USERGROUP_USERGROUPNO',
        primaryKey: true,
        rules:['pk'],
    },
];