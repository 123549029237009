import * as React from 'react';
import {DbGrid} from "../../../components/dbgrid/db-grid";
import {inject, observer} from "mobx-react";
import {IRootStore} from "../../../routes/root-store";
import {BaseCardGridMenuStandard} from "../grid/base-card-grid-menu-standard";
import {IDataset} from "../../../components/dataset/IDataset";
import styled from "styled-components";
import {cl_gray100} from "../../_theme/colors";

interface IBaseCardTabGrid {
    rootStore?: IRootStore;
    className?: string;
    id: string;
    ds: IDataset<any>;
    singleRouteName?: string;
    onRowDoubleClicked?: any;
    menu?: any;
    height?: number;
    disabled?: boolean;
    menuInsert?: any;
    hideInsert?: boolean;
    hideDelete?: boolean;
}


@inject('rootStore')
@observer
export class _BaseCardTabGrid extends React.Component<IBaseCardTabGrid, {}> {

    gridApi: any;


    onDefaultRowDoubleClicked = () => {
        let {rootStore: {routerStore}, ds, singleRouteName} = this.props;
        if (singleRouteName) {
            routerStore.goTo(singleRouteName, ds.pkValues);
        }
    };

    onGridReady = (params: any) => {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };

    autoFit = () => {
        this.gridApi.sizeColumnsToFit()
    };


    render() {
        const {className, ds, onRowDoubleClicked, menu, id, disabled, menuInsert, hideInsert, hideDelete} = this.props;
        return (

            <div className={className}>
                {menu ?
                    menu(this.autoFit) :
                    <BaseCardGridMenuStandard
                        id={id}
                        ds={ds}
                        autoFit={this.autoFit}
                        disabled={disabled}
                        menuInsert={menuInsert}
                        hideInsert={hideInsert}
                        hideDelete={hideDelete}
                    />
                }
                <div className="grid">
                    <DbGrid
                        ds={ds}
                        onGridReady={this.onGridReady}
                        onRowDoubleClicked={onRowDoubleClicked ? onRowDoubleClicked : this.onDefaultRowDoubleClicked}
                    />
                </div>
            </div>

        )
    }
}


export const BaseCardTabGrid = styled(_BaseCardTabGrid)`
      display: flex;
      height: ${props => props.height ? props.height + 'px' : '100%'};
      //padding-top:2px;

      .grid-menu {
        flex: 0 1 2rem;
        background-color: ${cl_gray100};

        .btn {
          padding: 0;
          width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          margin: 2px;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        }
      }

      .grid {
        flex: 1 1 auto;
        max-width: 100%;
        overflow: auto;
        border:0;
        font-family:"Source Sans Pro", sans-serif;
      }
    

`;
