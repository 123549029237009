import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../../_base/base-view";
import {PpapReportBasepage} from "../../ppap-report-basepage";
import moment from "moment";
import {statusText} from "../../../../../models/Status";
import {cl_blue} from "../../../../_theme/colors";
import {ppapSRText} from "../../../../../models/PpapStandardElement";




@inject('rootStore')
@observer
export class PpapReportProductQualityPlan extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {ppapStore: {dsPpapOrderElement}, langStore: {lang}}} = this.props;
        return (
            <>
                <PpapReportBasepage headline="1) Product Quality Plan"/>
                <table style={{width: '100%'}}>
                    <thead>
                    <tr>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>
                            {lang.PPAP_ELEMENTNO}
                        </td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PPAP_ELEMENT}
                        </td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PPAP_SUBMISSION}
                        </td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PPAP_RESPONSIBLE_SUPPLIER}
                        </td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PROJECTTASK_DUEDATE}
                        </td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PPAP_STATUS}
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        dsPpapOrderElement.data.map((element, i) =>
                            <tr key={i}>
                                <td style={{border: '1px solid black'}}>{element.elementno}</td>
                                <td style={{border: '1px solid black'}}>{element.element}</td>
                                <td style={{border: '1px solid black'}}>{lang[ppapSRText(element.sr)]}</td>
                                <td style={{border: '1px solid black'}}>{element.responsible}</td>
                                <td style={{border: '1px solid black'}}>{element.duedate ? moment(element.duedate).format('YYYY-MM-DD') : ''}</td>
                                <td style={{border: '1px solid black'}}>{lang[statusText(element.status)]}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </>
        )
    }
}