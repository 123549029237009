import {darken, lighten} from "polished";

export const cl_gray100 = '#f8f9fa';
export const cl_gray200 = '#e9ecef';
export const cl_gray300 = '#dee2e6';
export const cl_gray400 = '#ced4da';
export const cl_gray500 = '#6c757d';
export const cl_gray600 = '#666666';
export const cl_gray700 = '#495057';
export const cl_gray800 = '#343a40';
export const cl_gray900 = '#212529';

export const cl_white = '#ffffff';
export const cl_red = darken(0.2, '#fc5c65');//rgb(252,92,101);//'#bb0000';
export const cl_green = darken(0.1, '#26de81');//'#2b7d2b';
export const cl_yellow = darken(0.15, '#fed330');//'#e78c07';

//export const cl_blue = '#427cac';


export const cl_dark_blue = '#1d3448';
export const cl_blue = darken(0.1, '#276BA6');
export const cl_light_blue = '#45aaf2';
export const cl_lighter_blue = lighten(0.5, '#276BA6');

export const cl_black = '#000000';

export const cl_card_blue = '#2C4E6C'

