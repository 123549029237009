import {BaseStore, IBaseStore} from "./base-store";
import {actionColumns, ActionTyp, IAction} from "../models/Action";
import {RootStore} from "../routes/root-store";
import {action, computed, observable, reaction, runInAction} from "mobx";
import {RouterState} from "mobx-state-router";
import {Status, STATUS_ALL} from "../models/Status";
import {dsState, IDataset} from "../components/dataset/IDataset";
import moment from "moment";
import {actionImageColumns, IActionImage} from "../models/ActionImage";
import {Dataset} from "../components/dataset/dataset";
import axios from "axios";
import {auditColumns, IAudit} from "../models/Audit";
import * as R from 'ramda';
import {resizeImage} from "../lib/resize-image";
import {authorizer} from "../components/dataset/authorizer";


export interface IActionStore extends IBaseStore<IAction> {
    doAccept: () => Promise<void>;
    doReject: () => Promise<void>;
    dsActionImage: IDataset<IActionImage>;
    uploadFile: (acceptFile: any[]) => Promise<void>;
    images: any[];

}

export class ActionStore extends BaseStore<IAction> implements IActionStore {
    @observable
    projectno: string = '';

    @observable
    dsAudit: IDataset<IAudit>;

    @observable
    dsActionImage: IDataset<IActionImage>;

    @action.bound
    dsOnAfterInsert(ds: IDataset<IAction>) {
        const {authStore: {username}} = this.rootStore;
        ds.actual.createdby = username;
        ds.actual.taskowner = username;
        ds.actual.createdat = moment().format();
    }

    constructor(rootStore: RootStore) {
        super(rootStore, '/gridApi/action/', R.clone(actionColumns));

        this.dsAudit = new Dataset<IAudit>('/gridApi/audit/', auditColumns);

        this.dsActionImage = new Dataset<IActionImage>('/gridApi/actionimage/', actionImageColumns);
        this.dsActionImage.setMasterSource(this.ds, [
            {
                field: 'no',
                masterField: 'no'
            }]
        );

        this.ds.onAfterInsert = this.dsOnAfterInsert;

        // StatusColumn im Filter anpassen.
        let status = this.cdsFilter.columns
            .find(column => column.fieldName === 'status');
        status.options = [STATUS_ALL, Status.PLANNED, Status.INPROGRESS, Status.COMPLETED, Status.NOTAPPROVED, Status.APPROVED];
        status.defaultValue = STATUS_ALL;
    }


    @action.bound
    async openDetails() {
        await this.dsActionImage.open();
        switch (this.ds.actual.typ) {
            case ActionTyp.AUDITTask:
                this.dsAudit.filter = {auditno: this.ds.actual.key1}
                await this.dsAudit.open();
                runInAction(() => {
                    this.projectno = this.dsAudit.actual?.projectno;
                })
                break;
            // Todo: die anderen Typen auch entsprechend behandeln.
            default:
                // darf nicht sein.
                break;
        }
    }

    /**
     * Je nachdem ob Projectbezug ist, Responsible filtern
     *
     */
    disposeMe = reaction(() => this.projectno, (projectno) => {
        if (projectno) {
            this.ds.columns.find((column) => column.fieldName === 'responsible')
                .selectdlg.url = '/gridApi/projectuser/' + projectno;
        } else {
            this.ds.columns.find((column) => column.fieldName === 'responsible')
                .selectdlg.url = '/gridApi/user/';
        }
    })

    @action.bound
    async closeDetails() {
        await this.dsAudit.close();
        runInAction(() => {
            this.projectno = ''
        })
        await this.dsActionImage.close();
    }


    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        this.dsCheck.dataUrl = '/gridApi/action/'
        this.dsCheck.filter = {no: toState.params.no};
        await this.dsCheck.open();
        const rv = this.dsCheck.data.length === 1;
        this.dsCheck.close();
        return rv;
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.initializeAsync();
        switch (toState.routeName) {
            case 'actiontable':
                await this.loadFilter('action');
                await this.open(this.cdsFilter.actual);
                if (fromState.routeName === 'action') {
                    // datatype korrigieren
                    fromState.params.no = parseInt(fromState.params['no']) as any;
                    this.ds.locate(this.ds.pkFields, fromState.params);
                }
                break;
            case 'action':
                await this.open(toState.params as any);
                await this.openDetails();
                runInAction(() => {
                    if (this.ds.actual.taskowner === this.rootStore.authStore.username) {
                        if (this.ds.actual.status === Status.COMPLETED) {
                            let column = this.ds.columns.find(column => column.fieldName === 'status');
                            column.options = [Status.INPROGRESS, Status.COMPLETED, Status.NOTAPPROVED, Status.COND_APPROVED, Status.APPROVED];
                        }
                    } else if (this.ds.actual.responsible === this.rootStore.authStore.username) {
                        if (this.ds.actual.status !== Status.APPROVED) {
                            let column = this.ds.columns.find(column => column.fieldName === 'status');
                            column.options = [Status.PLANNED, Status.INPROGRESS, Status.COMPLETED];
                        }
                    }
                });
                break;
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'action') {
            await this.closeDetails();
            if (this.ds.state === dsState.dsInsert || this.ds.state === dsState.dsEdit) {
                if (this.rootStore.authStore.username !== '') {
                    await this.ds.post();
                }

            }
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'actiontable') {
            this.close();
            await Promise.resolve();
        }
    }

    @action.bound
    async doAccept() {
        this.ds.edit();
        this.ds.actual.status = Status.APPROVED;
        await this.ds.post();
    }

    @action.bound
    async doReject() {
        this.ds.edit();
        this.ds.actual.status = Status.INPROGRESS;
        await this.ds.post();
    }

    @action.bound
    async uploadFile(acceptFile: any[]): Promise<void> {
        const url = '/gridApi/actionimage/fileupload';
        let file: File = acceptFile[0];
        if (acceptFile[0].type === 'image/jpeg') {
            file = await resizeImage(file);
        }
        const formData = new FormData();
        formData.append('type', 'actionimage');
        formData.append('file', file);
        formData.append('no', this.ds.actual.no);
        const config = authorizer();
        config.headers['content-type'] = 'multipart/form-data';
        await axios.post(url, formData, config);
        await runInAction(async () => {
            await this.dsActionImage.refresh(this.dsActionImage.cursor);
            this.dsActionImage.last();
        });
    }

    @computed
    get images(): any[] {
        return this.dsActionImage
            .data
            .map(image => {
                return {
                    src: '/gridApi/image/actionimage/' + image.no + '/' + image.image,
                    altText: image.image,
                    caption: image.image
                }
            })
    }

}