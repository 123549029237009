import * as React from "react";
import {observer} from "mobx-react";
import {IPpapDetail18} from "../../../../../models/PpapDetail18";
import {dsState, IDataset} from "../../../../../components/dataset/IDataset";
import {action} from "mobx";

const CheckBox = ({checked, onChange}: any) =>
    <input type="checkbox" checked={checked} onChange={onChange}/>


interface IPpapWarrantDisposition {
    ds: IDataset<IPpapDetail18>;
}

@observer
export class PpapWarrantDisposition extends React.Component<IPpapWarrantDisposition, any> {


    onChange = (value: number) => {
        const {ds} = this.props;
        return action(() => {
            if (ds.state === dsState.dsEdit) {
                ds.actual.ppap_warrant_disposition = ds.actual.ppap_warrant_disposition === value ? -1 : value;
            }
        })
    }


    render() {
        const {ds} = this.props;
        return (
            <>
                <span style={{fontWeight: 'bold'}}>PPAP Warrant Disposition:</span>
                <br/>
                <CheckBox checked={ds.actual?.ppap_warrant_disposition === 1} onChange={this.onChange(1)}/>
                &nbsp;Product and process approved as submitted<br/>
                <CheckBox checked={ds.actual?.ppap_warrant_disposition === 2} onChange={this.onChange(2)}/>
                &nbsp;Conditionally approved<br/>
                <CheckBox checked={ds.actual?.ppap_warrant_disposition === 3} onChange={this.onChange(3)}/>
                &nbsp;Not approved<br/>
            </>)
    }
}