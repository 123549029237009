import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../../_base/base-view";
import {computed} from "mobx";
import {cl_blue} from "../../../../_theme/colors";
import moment from "moment";

const TableRow = ({label, st}: { label: string, st: string }) =>
    <tr>
        <td><h2>{label}</h2></td>
        <td><h2 style={{color: cl_blue}}>{st}</h2></td>
    </tr>

const LeerZeile = ({anzahl}: { anzahl: number }) => {
    return (
        <>
            {[...Array(anzahl).keys()].map((x, i) => <p key={i}>&nbsp;</p>)}
        </>
    )
}


@inject('rootStore')
@observer
export class GembaReportPage1 extends React.Component<IBaseView, {}> {

    @computed
    get logo() {
        const {rootStore: {gembaStore: {dsCustomer}}} = this.props;
        return dsCustomer.actual?.logo ? "/gridApi/customerlogo/download/" + dsCustomer.actual.customerno + "/" + dsCustomer.actual.logo : "";
    }

    render() {
        const {rootStore: {gembaStore: {ds, dsProject}}} = this.props;
        return (
            <>
                <LeerZeile anzahl={5}/>
                {this.logo ?
                    <img
                        style={{width: '100%', height: '200px', objectFit: 'contain'}}
                        src={this.logo}
                        alt='logo'/> :
                    null
                }
                <LeerZeile anzahl={2}/>
                <h1 style={{textAlign: 'center'}}>Gemba-Report</h1>
                <LeerZeile anzahl={10}/>
                <table style={{width: '100%'}}>
                    <tbody>
                    <TableRow label={'Project No'} st={dsProject.actual?.projectno}/>
                    <TableRow label={'Project'} st={dsProject.actual?.project}/>
                    <TableRow label={'Gemba No'} st={ds.actual?.gembano}/>
                    <TableRow label={'Gemba'} st={ds.actual?.gemba}/>
                    <TableRow label={'Supplier No'} st={dsProject.actual?.supplierno}/>
                    <TableRow label={'Supplier'} st={dsProject.actual?.supplier1}/>
                    <TableRow label={'Date'}
                              st={moment(ds.actual?.startdate).format('DD-MM-YYYY') + ' - ' + moment(ds.actual?.duedate).format('DD-MM-YYYY')}/>
                    <TableRow label={'Responsible'} st={ds.actual?.responsible}/>
                    </tbody>
                </table>
                <LeerZeile anzahl={2}/>
                <img
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '10%',
                        objectFit: 'cover'
                    }}
                    src='/img/favicon.png'
                    alt='logo'
                />
            </>
        )
    }

}