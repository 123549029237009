import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../../_base/base-view";
import {PpapReportBasepage} from "../../ppap-report-basepage";
import {cl_blue} from "../../../../_theme/colors";


@inject('rootStore')
@observer
export class PpapReportPartFamily extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {ppapStore: {dsPpapFamily}, langStore: {lang}}} = this.props;
        return (
            <>
                <PpapReportBasepage headline="PPAP Part Family"/>
                <p/>
                {/*<table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '15%'}}>
                            <span style={{fontWeight: "bold"}}>PPAP Part Family</span>
                        </td>
                        <td style={{width: '25%'}}>
                            <span style={{color: cl_blue}}>{dsPpapFamily.actual?.ppap_family}</span>
                        </td>
                        <td style={{width: '25%'}}>
                            <span style={{fontWeight: "bold"}}>&nbsp;</span>
                        </td>
                        <td style={{width: '25%'}}>
                            <span style={{color: cl_blue}}>&nbsp;</span>
                        </td>
                    </tr>
                    </tbody>
                </table>*/}
                <p/>
                <table style={{width: '100%'}}>
                    <thead>
                    <tr>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black',
                            width: '10%'
                        }}>{lang.PRODUCT_PRODUCTNO}</td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black',
                        }}>{lang.PRODUCT_REVISION}</td>
                       {/* <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black',
                        }}>{lang.PRODUCT_REVISIONDATE}</td>*/}
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PRODUCT_DESCRIPTION1}</td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PRODUCT_DRAWINGNO}
                        </td>
                        <td style={{
                            backgroundColor: cl_blue,
                            color: 'white',
                            border: '1px solid black'
                        }}>{lang.PRODUCT_SPEZIFICATION}
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        dsPpapFamily.data.map((product, index) =>
                            <tr key={index}>
                                <td style={{border: '1px solid black'}}>{product.productno}</td>
                                <td style={{border: '1px solid black'}}>{product.revision}</td>
                                {/* <td style={{border: '1px solid black'}}>{product.revision_date}</td>*/}
                                <td style={{border: '1px solid black'}}>{product.description1}</td>
                                <td style={{border: '1px solid black'}}>{product.drawingno}</td>
                                <td style={{border: '1px solid black'}}>{product.spezification}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </>
        )
    }
}