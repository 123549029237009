import * as React from 'react';
import {IBaseView} from "../../../_base/base-view";
import {inject, observer} from "mobx-react";
import {PpapOrderLeftSelectElement} from "./ppap-order-left-select-element";
import {DbGrid} from "../../../../components/dbgrid/db-grid";
import {CardWithTab, ITab} from "../../../_base/styled/card-with-tab";
import {MenuInsert} from "../../../_base/menu/menu-insert";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {CsvButtonUpload} from "../../../_base/csv/csv-button-upload";
import {action} from "mobx";
import axios from "axios";
import {authorizer} from "../../../../components/dataset/authorizer";


@inject('rootStore')
@observer
export class PpapOrderLeftView extends React.Component<IBaseView, {}> {

    @action.bound
    async uploadCsv(rawdata: Array<any>) {
        const {rootStore: {ppapStore: {ds, dsPpapFamily}}} = this.props;
        const fields = rawdata[0].data;
        const productnoCol = fields.findIndex((st: string) => st === 'productno')
        if (productnoCol > -1) {
            const data = rawdata.filter((zeile: any, i: number) => i > 0);
            let newElements = data.map((record: any) => {
                let rv: any = {}
                rv['productno'] = record.data[productnoCol]
                rv['ppapno'] = ds.actual.ppapno
                return rv
            })
                .filter(record => record.productno && record.ppapno);

            await Promise.all(newElements.map((element) => {
                return axios.post('/gridApi/ppappartfamily/', element, authorizer())
            }));
            dsPpapFamily.close();
            await dsPpapFamily.open();
        }


        /*

        let newElements = data.map((record: any) => {
            let rv: any = {}
            fields.forEach((f: string, i: number) => {

                rv[fields[i]] = record.data[i]
            })

            return rv
        });
        newElements.forEach((element) => {
            axios.post('/gridApi/product/', element, authorizer())
        });
        ds.close();
        await ds.open();*/
    }


    getTabs = (): ITab[] => {
        const {
            rootStore: {
                ppapStore: {ds, dsPpapFamily, dsPpapOrderElement},
                authStore: {username, config}
            }
        } = this.props;
        return [
            {
                title: "PPAP_PRODUCT_QUALITY_PLAN",
                icon: "book-open",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsPpapOrderElement}/>
                    </div>
                ),
                menu: ds.actual?.owner === username ? (<PpapOrderLeftSelectElement/>) : null
            },
            {
                title: "PRODUCT_PPAP_FAMILY",
                icon: "tablets",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsPpapFamily}/>
                    </div>
                ),
                menu: config.ppap_family_typ === 1 && username === ds.actual?.owner ?
                    <>
                        <MenuInsert id={"PPAP_FAMILY_btn-insert"} ds={dsPpapFamily}/>
                        <MenuDelete id={"PPAP_FAMILY_btn-delete"} ds={dsPpapFamily}/>
                        <CsvButtonUpload onUpload={this.uploadCsv}/>
                    </> :
                    null
            }
        ];

    }

    render() {
        return (
            <CardWithTab tabs={this.getTabs()}/>
        )
    }
}

