import * as React from "react";
import {inject, observer} from "mobx-react";
import {AuditCheckbox} from "./audit-checkbox";
import {action} from "mobx";
import {AuditScore} from "../../../models/AuditQuestion";
import styled from "styled-components";
import {IAuditRating} from "./audit-rating";

@inject('rootStore')
@observer
class _AuditRatingVda63 extends React.Component<IAuditRating, {}> {


    onChangeScore(score: number) {
        return action(() => {
            const {rootStore: {authStore: {username}}, audit, question, readOnly} = this.props;
            if (username === audit?.leadauditor && !readOnly) {
                question.score = question.score === score ? AuditScore.UNRATED : score;
                question.notapplicable = false;
            }
        })
    }

    @action.bound
    onChangeApplicable() {
        const {rootStore: {authStore: {username}}, audit, question, readOnly} = this.props;

        if (username === audit?.leadauditor && !readOnly) {
            question.notapplicable = !question.notapplicable;
            if (question.notapplicable) {
                question.qm = -1;
                question.praxis = -1;
                question.score = AuditScore.NOT_APPLICABLE;
            } else {
                question.score = AuditScore.UNRATED;
            }
        }
    }

    render() {
        const {rootStore: {langStore: {lang}}, className, question} = this.props;
        return (
            <div className={className}>
                <table>
                    <thead>
                    <tr>
                        <td>0</td>
                        <td>4</td>
                        <td>6</td>
                        <td>8</td>
                        <td>10</td>
                        <td>{lang['N_A']}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <AuditCheckbox checked={question?.score === 0}
                                           onChange={this.onChangeScore(0)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.score === 4}
                                           onChange={this.onChangeScore(4)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.score === 6}
                                           onChange={this.onChangeScore(6)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.score === 8}
                                           onChange={this.onChangeScore(8)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.score === 10}
                                           onChange={this.onChangeScore(10)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.notapplicable}
                                           onChange={this.onChangeApplicable}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export const AuditRatingVda63 = styled(_AuditRatingVda63)`
    table{
            margin-top:0.5rem;
            margin-bottom:0.5rem;
            width: 100%;
            text-align: center;
            vertical-align: middle;
          } 
    
          thead{
              border-bottom: 1px solid black;
          }
          
          tbody{
          
              tr{ height: 2rem;
                      th{
                          text-align: left;
                      }
              }
          }

`;

