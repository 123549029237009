import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../_base/base-view";
import {TableForm} from "../../_base/styled/table-form";
import {DbField} from "../../../components/dbform/db-field";
import {TableLayout} from "../../_base/styled/layout/table-layout";
import {Col, Row} from "reactstrap";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";


@inject('rootStore')
@observer
export class GembaTemplateDetailView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {gembaTemplateStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <div style={{maxHeight: '50%'}}>
                    <TableForm icon="cogs" title="GEMBA_TEMPLATE" ds={ds}
                               hideMenu={!hasUserRight(UsergroupRight.UR_GEMBA_TEMPLATE_EDIT)}>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="gembatemplate"/>
                                <DbField ds={ds} field="area_cat"/>
                                {/*<DbField ds={ds} field="element_cat"/>*/}
                            </Col>
                            <Col>
                                <DbField ds={ds} field="description"/>
                            </Col>
                        </Row>
                    </TableForm>
                </div>
            </TableLayout>
        )
    }

}