import {IconButton} from "../../../_base/styled/icon-button";
import * as React from "react";
import {Dialog} from "../../../../components/dialog/Dialog";
import {IBaseView} from "../../../_base/base-view";
import {inject, observer} from "mobx-react";
import {action, observable, runInAction} from "mobx";
import {FieldSelectRadio} from "../../../../components/dbform/field/field-select-radio";
import {uploadReportConfiguration} from "./ppap-upload-tab/upload-report-configuration";
import {Status} from "../../../../models/Status";


@inject('rootStore')
@observer
export class PpapElementStateBtn extends React.Component<IBaseView, {}> {

    // sichert den Status, wenn z.B. Dlg auf Status geändert und dann gecanceled wird.
    saveStatus: number = null;

    @observable
    isOpen: boolean = false;

    @action.bound
    doOpen() {
        const {rootStore: {ppapStore: {dsPpapOrderElement}}} = this.props;
        this.isOpen = true;
        this.saveStatus = dsPpapOrderElement.actual.status;
    }

    @action.bound
    async open() {


        this.doOpen();
    }

    @action.bound
    async onOk() {
        // Eigentlich müsste man das Edit im open schalte.
        // Aber aus irgendeinem Voodoo geht dann der Dialog nicht auf,
        // also speicherung auf actual erst beim ok.
        const {rootStore: {ppapStore: {dsPpapOrderElement}}} = this.props;
        const status = dsPpapOrderElement.actual.status;
        dsPpapOrderElement.edit();
        dsPpapOrderElement.actual.status = status;
        await dsPpapOrderElement.post();
        runInAction(() => {
            this.isOpen = false;
        });
    }

    @action.bound
    onCancel() {
        const {rootStore: {ppapStore: {dsPpapOrderElement}}} = this.props;
        dsPpapOrderElement.actual.status = this.saveStatus;
        dsPpapOrderElement.cancel();
        this.isOpen = false;
    }

    @observable
    disabledOptions: number[] = [];


    /**
     * Element soll nur abgeschlossen werden können, wenn:
     * 1) Mindest ein Upload erfolgt ist.
     * 2) Alle Pflichtfelder ausgefüllt sind.
     */
    @action.bound
    opened() {
        const {rootStore: {ppapStore: {dsPpapOrderElement, dsPpapOrderDocument}}} = this.props;
        // kein Document => kein complete
        if (dsPpapOrderDocument.data.length === 0) {
            this.disabledOptions = [Status.COMPLETED, Status.COND_APPROVED, Status.APPROVED];
            return;
        }
        const config = uploadReportConfiguration(dsPpapOrderElement.actual.elementno)
        let completed = config.mandatoryList.reduce<any>((accu, field) => {
            return accu && dsPpapOrderDocument.data.reduce((docAccu, doc) => {
                let rv = !!(docAccu && doc[field]);
                if (config.responsible) {
                    rv = rv && !!doc['responsible'] && !!doc['responsible_function'] && !!doc['responsible_company'];
                }
                return rv
            }, accu)
        }, true);
        if (!completed) {
            this.disabledOptions = [Status.COMPLETED, Status.COND_APPROVED, Status.APPROVED];
            return;
        }
        this.disabledOptions = [];
    }

    render() {
        const {rootStore: {ppapStore: {dsPpapOrderElement, statusElementOptionsFunc}}} = this.props;
        return (
            <>
                <IconButton
                    id={'state'}
                    onClick={this.open}>
                    State
                </IconButton>
                <Dialog
                    title={'State'}
                    isOpen={this.isOpen}
                    onOk={this.onOk}
                    onCancel={this.onCancel}
                    onOpened={this.opened}
                >
                    <FieldSelectRadio
                        actual={dsPpapOrderElement.actual}
                        column={dsPpapOrderElement.columns.find((column) => column.fieldName === 'status')}
                        optionsFunc={statusElementOptionsFunc}
                        disabledOptions={this.disabledOptions}
                    />
                </Dialog>
            </>
        )
    }
}
