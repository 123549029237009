import React from 'react';
import {Col, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import {action} from "mobx";
import {IBaseView} from "../../_base/base-view";
import {InspectionCheckbox} from "./inspection-collect-checkbox";
import {DbField} from "../../../components/dbform/db-field";
import {InspectionScore} from "../../../models/InspectionQuestion";


@inject('rootStore')
@observer
export class InspectionCollectRating extends React.Component<IBaseView, {}> {


    @action.bound
    onChangeApplicable() {
        const {rootStore: {inspectionStore: {dsInspectionQuestion}}} = this.props;
        dsInspectionQuestion.actual.score = InspectionScore.NOT_APPLICABLE;
    }


    @action.bound
    onChangeNO() {
        const {rootStore: {inspectionStore: {dsInspectionQuestion}}} = this.props;
        dsInspectionQuestion.actual.score = InspectionScore.NO_COMPLIANCE;

    }

    @action.bound
    onChangeFULL() {
        const {rootStore: {inspectionStore: {dsInspectionQuestion}}} = this.props;
        dsInspectionQuestion.actual.score = InspectionScore.FULL_COMPLIANCE;
    }


    render() {

        const {rootStore: {inspectionStore: {dsInspectionQuestion}, langStore: {lang}}} = this.props;
        return (
            <>
                <Row>
                    <Col>
                        {lang.AUDIT_NO}<br/>
                        <InspectionCheckbox
                            checked={dsInspectionQuestion.actual?.score === InspectionScore.NO_COMPLIANCE}
                            onChange={this.onChangeNO}/>
                    </Col>
                    <Col>
                        {lang.AUDIT_YES}<br/>
                        <InspectionCheckbox
                            checked={dsInspectionQuestion.actual?.score === InspectionScore.FULL_COMPLIANCE}
                            onChange={this.onChangeFULL}/>
                    </Col>
                    <Col>
                        {lang['AUDIT_RATING_NA']}<br/>
                        <InspectionCheckbox
                            checked={dsInspectionQuestion.actual?.score === InspectionScore.NOT_APPLICABLE}
                            onChange={this.onChangeApplicable}/>
                    </Col>
                </Row>
                <Row style={{paddingTop: '1rem'}}>
                    <Col>
                        <DbField ds={dsInspectionQuestion} field="extra01"/>
                    </Col>
                    <Col>
                        <DbField ds={dsInspectionQuestion} field="extra02"/>
                    </Col>
                </Row>
            </>
        )
    }
}