import {IBaseView} from "../../../_base/base-view";
import {DbForm} from "../../../../components/dbform/db-form";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../../components/dbform/db-field";
import {ImageListView} from "../../../_shared/report/image/image-list-view";
import * as React from "react";
import {inject, observer} from "mobx-react";
import {PosNegButtons} from "./pos-neg-buttons";

@inject('rootStore')
@observer
export class GembaDetailObservation extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {gembaStore: {dsGembaFinding, dsImage}}} = this.props;
        if (dsGembaFinding.cursor > -1) {
            return (
                <div style={{padding: '2rem'}}>
                    <DbForm ds={dsGembaFinding}>
                        <PosNegButtons/>
                        <Row>
                            <Col md={6}>
                                <DbField ds={dsGembaFinding} field="area"/>
                            </Col>
                            <Col md={6}>
                                <DbField ds={dsGembaFinding} field="keyword"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <ImageListView dsImage={dsImage}
                                                   imageBaseUrl="/gridApi/image/gembafindingimage/"
                                                   key1="gembano"
                                                   key2="findingno"/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={dsGembaFinding} field="finding"/>
                            </Col>
                        </Row>
                    </DbForm>
                </div>
            )
        } else {
            return <div></div>
        }
    }
}