import * as React from "react";
import {inject, observer} from "mobx-react";
import {DbForm, FormMode, TcolCount} from "../../../components/dbform/db-form";
import {IDataset} from "../../../components/dataset/IDataset";
import {BaseCard, IBaseCard} from "../card/base-card";
import {FormBody} from "./form-body";
import {Alert} from "reactstrap";


interface IBaseFormCard extends IBaseCard {
    ds: IDataset<any>;
    colCount?: TcolCount;
    formMode?: FormMode;
    row?: boolean;
    message?: string;
}

interface IMessage{
    message:string;
}

const Message=({message}:IMessage)=>
    message?
    <Alert color="danger">
        {message}
    </Alert> : null;


@inject('rootStore')
@observer
export class BaseCardForm extends React.Component<IBaseFormCard, {}> {
    render() {
        const {ds, className, colCount, formMode, children, row, height, message, ...cardProps} = this.props;
        return (
            <BaseCard {...cardProps}>
                <FormBody height={message ? height + 70 : height}>
                    <DbForm ds={ds} colCount={colCount} formMode={formMode} row={row}>
                        {children}
                    </DbForm>
                    <Message message={message}/>
                </FormBody>
            </BaseCard>
        )
    }
}