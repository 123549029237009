import React from "react";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../../_base/base-view";
import {IPpapOrderElement} from "../../../../../models/PpapOrderElement";
import {action, runInAction} from "mobx";
import {StyledTable} from "./styled-table";
import {DocumentEditor} from "./document-editor";
import {dsState, IDataset} from "../../../../../components/dataset/IDataset";
import {FieldText, IFieldName} from "./upload-report-configuration";
import {IPpapOrderDocument} from "../../../../../models/PpapDocument";
import {Participating} from "./participating";
import {Responsible} from "./responsible";
import {DropUploadArea} from "./drop-upload-area";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {cl_gray500} from "../../../../_theme/colors";
import {includes} from "../../../../../components/lib/Includes";


interface IUploadReport extends IBaseView {
    element: IPpapOrderElement;
    headLine: string;
    fieldList: IFieldName[];
    mandatoryList: IFieldName[];
    hideCursor?: boolean;
    responsible?: boolean;
    participating?: boolean;
    dsDocument: IDataset<IPpapOrderDocument>;
}


@inject('rootStore')
@observer
export class PpapUploadFileTab extends React.Component<IUploadReport, {}> {

    getHrefDoc = (document: IPpapOrderDocument) => {
        return '/gridApi/ppaporderdocument/' + document.ppapno + '/' + document.elementno + '/' + document.document
    }

    // when der Cursor durch Clicken versetzt wird. => automatisches Speichern.
    @action.bound
    onClickCursor(index: number) {
        return action(async () => {
            const {dsDocument} = this.props;
            if (dsDocument.cursor !== index) {
                if (dsDocument.state === dsState.dsEdit) {
                    await dsDocument.post();
                    runInAction(() => {
                        dsDocument.cursor = index;
                        dsDocument.edit();
                    })
                } else {
                    dsDocument.cursor = index;
                }
            }
        })
    }

    extraEmptyLines = (anzahl: number) => {
        return anzahl >= 3 ?
            null :
            anzahl === 2 ?
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr> :
                anzahl === 1 ?
                    <>
                        <tr>
                            <td colSpan={2} style={{border: 0}}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{border: 0}}>&nbsp;</td>
                        </tr>
                    </> :
                    null

    }

    renderRetainHint = () => {
        const {hideCursor, element} = this.props;
        return element.sr === 200 && !!!hideCursor ? (
                <div style={{
                    margin: '1rem',
                    padding: '0.5rem',
                    backgroundColor: cl_gray500,
                    color: 'white',
                    fontWeight: 'bold'
                }}>Please upload in minimum a snip of the document header for evidence</div>
            ) :
            null
    }

    render() {
        const {
            rootStore: {ppapStore: {uploadUploadFile}},
            headLine,
            fieldList,
            mandatoryList,
            hideCursor,
            responsible,
            participating,
            dsDocument,
            element
        } = this.props;

        return fieldList ? (
                <>
                    {this.renderRetainHint()}

                    {dsDocument.data
                        .filter((document) => document.elementno === element?.elementno)
                        .map((document, index) =>
                            <div key={index} style={{
                                backgroundColor: dsDocument?.actual === document ? !hideCursor ? '#b7e4ff' : 'white' : 'white'
                            }}>
                                <div
                                    style={{padding: '1rem'}}
                                    onClick={this.onClickCursor(index)}
                                >
                                    {fieldList.length > 0 ?
                                        <StyledTable width={'100%'}>
                                            <thead>
                                            <tr>
                                                <td style={{width: '33%'}}>{headLine}</td>
                                                <td style={{width: '33%'}}/>
                                                <td style={{width: '34%'}}/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {fieldList.map((field, fieldIndex) =>
                                                <tr key={fieldIndex}>
                                                    <td>
                                                        {FieldText[field]}
                                                    </td>
                                                    <td>


                                                        <DocumentEditor
                                                            document={document}
                                                            fieldName={field}
                                                            readonly={!(dsDocument.state === dsState.dsEdit && dsDocument?.actual === document)}
                                                            mandatory={includes(mandatoryList, field)}
                                                        />
                                                    </td>
                                                    {
                                                        fieldIndex === 0 ?
                                                            <td
                                                                rowSpan={fieldList.length <= 3 ? 3 : fieldList.length}
                                                                style={{textAlign: 'center', verticalAlign: 'middle'}}
                                                            >
                                                                <FontAwesomeIcon size={'3x'}
                                                                                 icon={['fad', 'file-pdf']}/>
                                                                <br/>
                                                                <a href={this.getHrefDoc(document)}
                                                                   target={"_blank"}
                                                                   rel="noreferrer"
                                                                >
                                                                    {document.document}
                                                                </a>
                                                            </td> :
                                                            null
                                                    }
                                                </tr>
                                            )}
                                            {
                                                this.extraEmptyLines(fieldList.length)
                                            }

                                            </tbody>
                                        </StyledTable> :
                                        <StyledTable width={'100%'}>
                                            <thead>
                                            <tr>
                                                <td>{headLine}</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td
                                                    rowSpan={fieldList.length <= 3 ? 3 : fieldList.length}
                                                    style={{textAlign: 'center', verticalAlign: 'middle'}}
                                                >
                                                    <FontAwesomeIcon size={'3x'} icon={['fad', 'file-pdf']}/>
                                                    <br/>
                                                    <a href={this.getHrefDoc(document)}
                                                       target={"_blank"}
                                                       rel="noreferrer"
                                                    >
                                                        {document.document}
                                                    </a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </StyledTable>
                                    }
                                    {responsible ?
                                        <Responsible
                                            document={document}
                                            readonly={!(dsDocument.state === dsState.dsEdit && dsDocument?.actual === document)}
                                        /> :
                                        null
                                    }
                                    {
                                        participating ?
                                            <Participating
                                                document={document}
                                                readonly={!(dsDocument.state === dsState.dsEdit && dsDocument?.actual === document)}
                                            /> :
                                            null
                                    }
                                </div>

                            </div>
                        )}
                    {!hideCursor ?
                        <DropUploadArea id={"PPAP_ELEMENT_COMMENT_btn-insert"}
                                        onDrop={action(async (acceptFile: any[]) => {
                                            if (dsDocument.state === dsState.dsEdit) {
                                                await dsDocument.post(); //#1134
                                            }
                                            runInAction(async()=>{
                                                await uploadUploadFile(acceptFile)
                                                }
                                            )

                                        })}
                                        disabled={dsDocument.cursor === undefined}
                                        accept="application/pdf"/> :
                        null
                    }
                </>
            ) :
            <div></div>
    }
}