import {IField} from "../components/dataset/IField";
import {ILangKeys} from "../language/ILang";
import {enumToArray} from "../components/lib/enumToArray";
import {customerColumns} from "./Customer";
import {productColumns} from "./Product";
import {supplierColumns} from "./Supplier";
import {dateCellRenderer} from "../components/dataset/IDataset";
import {userColumns} from "./User";
import {projectColumns} from "./Project";
import {ppapStandardColumns} from "./PpapStandard";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {Status, statusText} from "./Status";
import {StatusRenderer} from "./cell-renderers/StatusRenderer";
import {DuedateRenderer} from "./_helper/duedate-renderer";
import {StartdateRenderer} from "./_helper/startdate-renderer";


export enum IPPAPRisklevel {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3
}

export const ppapRisklevelText = (level: IPPAPRisklevel): ILangKeys => {
    switch (level) {
        case IPPAPRisklevel.LOW:
            return 'PPAP_RISKLEVEL_LOW';
        case IPPAPRisklevel.MEDIUM:
            return 'PPAP_RISKLEVEL_MEDIUM';
        case IPPAPRisklevel.HIGH:
            return 'PPAP_RISKLEVEL_HIGH';
        default:
            return 'Unknown';

    }
};


export interface IPpapOrder {
    ppapno: string;
    ppap: string;
    standardno: string;
    level: number;
    owner: string;
    status: number;
    customerno: string;
    supplierno: string;
    productno: string;
    eclrevision: string;
    ecldate: string;
    createdby: string;
    createdat: string;
    startdate: string;
    duedate: string;
    projectno: string;
    responsiblesup: string;
    responsiblecust: string;
    ppap_family: string;
    gantt: boolean;
    submissiondate: string;
    purchaseno: string;
    ppaprevision: string;
}

export const ppapOrderColumns: IField<IPpapOrder> [] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDERNO',
        primaryKey: true,
        //rules: ['pk','mandatory']
        insert: 'hide'
    },
    {
        fieldName: 'ppap',
        dataType: 'string',
        label: 'PPAP',
        insert: 'show'
    },
    {
        fieldName: 'standardno',
        dataType: 'string',
        label: 'PPAP_STANDARD',
        insert: 'show',
        rules: ['mandatory'],
        //defaultValue: 'APQP4Wind',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/ppapstandard/',
            columns: ppapStandardColumns,
            InserRight: UsergroupRight.UR_PPAP_TEMPLATE_INSERT
        }
    },
    {
        fieldName: 'level',
        dataType: 'number',
        label: 'PPAP_RISKLEVEL',
        insert: 'show',
        rules: ['number', 'mandatory'],
        input: 'catalog',
        textFunc: ppapRisklevelText,
        options: enumToArray(IPPAPRisklevel),
        defaultValue: IPPAPRisklevel.HIGH,
        //readOnly: true,
    },
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        insert: 'hide',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/project/',
            columns: projectColumns,
            InserRight: UsergroupRight.UR_PROJECT_PROJECT_INSERT
        }
    },
    {
        fieldName: 'startdate',
        label: 'PROJECTTASK_STARTDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: StartdateRenderer,
            //cellStyle: dateCellStyle,
            width: 100
        },

        input: 'calendar'
    },
    {
        fieldName: 'duedate',
        label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        grid: {
            cellRenderer: DuedateRenderer,
            width: 100,
        },
        input: 'calendar',
        calendar: {
            popperPlacement: 'top'
        }
    },
    {
        fieldName: 'status',
        dataType: 'number',
        label: 'PPAP_STATUS',
        input: 'catalog',
        textFunc: statusText,
        options: enumToArray(Status),
        defaultValue: Status.PLANNED,
        useAsFilter: true,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100
        },
    },
    {
        fieldName: 'customerno',
        label: 'CUSTOMER_CUSTOMERNO',
        dataType: 'string',
        rules: ['mandatory'],
        insert: 'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/customer/',
            columns: customerColumns,
            InserRight: UsergroupRight.UR_BASE_CUSTOMER_INSERT
        }
    },
    {
        fieldName: 'supplierno',
        label: 'SUPPLIER_SUPPLIERNO',
        dataType: 'string',
        rules: ['mandatory'],
        insert: 'show',
        readOnly: true,
        input: 'selectdlg',
        useAsFilter: true,
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT
        }
    },
    {
        fieldName: 'productno',
        label: 'PRODUCT_PRODUCTNO',
        dataType: 'string',
        rules: ['mandatory'],
        insert: 'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/product/',
            columns: productColumns,
            InserRight: UsergroupRight.UR_BASE_PRODUCT_INSERT
        }
    },
    {
        fieldName: 'eclrevision',
        dataType: 'string',
        label: 'PPAP_ECLREVISION',
        hide: 'table',
    },
    {
        fieldName: 'ecldate',
        dataType: 'string',
        label: 'PPAP_ECLDATE',
        hide: 'table',
        grid: {
            cellRenderer: DuedateRenderer,
            width: 100,
        },
        input: 'calendar',
        calendar: {
            popperPlacement: 'top'
        }
    },
    {
        fieldName: 'owner',
        dataType: 'string',
        label: 'PPAP_OWNER',
        insert: 'show',
        input: 'selectdlg',
        rules: ['mandatory'],
        useAsFilter: true,
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        }
    },
    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
        hide: 'table',
    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        hide: 'table',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
    },


    {
        fieldName: 'responsiblesup',
        dataType: 'string',
        label: 'PPAP_RESPONSIBLE_SUPPLIER',
        insert: 'show',
        input: 'selectdlg',
        rules: ['mandatory'],
        selectdlg: {
            url: '/gridApi/user/',
            filter: (actual: any) => {
                return {supplierno: actual.supplierno}
            },
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        }
    },
    {
        fieldName: 'responsiblecust',
        dataType: 'string',
        label: 'PPAP_RESPONSIBLE_CUSTOMER',
        insert: 'show',
        input: 'selectdlg',
        rules: ['mandatory'],
        selectdlg: {
            url: '/gridApi/user/',
            filter: (actual: any) => {
                return {customerno: actual.customerno}
            },
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        }
    },
    {
        fieldName: 'ppap_family',
        dataType: 'string',
        label: 'PRODUCT_PPAP_FAMILY',
        readOnly: true,
    },
    {
        fieldName: 'gantt',
        dataType: 'boolean',
        label: 'PROJECTTASK_GANTT',
        defaultValue: true,
        input: 'checkbox',
        hide: 'table',
    },
    {
        fieldName: 'submissiondate',
        dataType: 'string',
        label: 'PPAP_SUBMISSION_DATE',
        input: 'calendar',
        hide: 'table',
        grid: {
            cellRenderer: dateCellRenderer,
        },
    },
    {
        fieldName: 'ppaprevision',
        dataType: 'string',
        label: 'PPAP_REVISION',
        hide: 'table',
    },
    {
        fieldName: 'purchaseno',
        dataType: 'string',
        label: 'PPAP_PURCHASENO',
        hide: 'table',
    },


];