import * as React from 'react';
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {Col, Progress, Row} from "reactstrap";
import {IBaseView} from "../../_base/base-view";
import {InspectionCollectAreaList} from "./inspection-collect-area-list";
import {AuditCollectElementList} from "./inspection-collect-element-list";

const ProgressBar = ({value, text}: any) =>
    <>
        <div className="text-center"><b>{text}</b></div>
        <Progress style={{backgroundColor: '#ced4da'}} value={value}/>
    </>;


@inject('rootStore')
@observer
class _InspectionCollectOverview extends React.Component<IBaseView, {}> {


    render() {
        const {rootStore: {inspectionStore: {ds, progressValue, progressText}, langStore: {lang}}, className} = this.props;
        return (
            <div className={className}>
                <Row>
                    <Col>
                        <p><b>{lang.INSPECTION_INSPECTOR}</b> : {ds.actual?.inspector}</p>
                        <p><b>{lang.PROJECTTASK_DUEDATE}</b> : {ds.actual?.duedate}</p>


                    </Col>
                    <Col>
                        <p><b>{lang.SUPPLIER_SUPPLIERNO}</b> : {ds.actual?.customerno}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ProgressBar value={progressValue} text={lang.PROGRESS + " : " + progressText}/>
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col sm={6}>
                        <InspectionCollectAreaList/>
                    </Col>
                    <Col sm={6}>
                        <AuditCollectElementList/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export const InspectionCollectOverview = styled(_InspectionCollectOverview)`
   padding:0.5rem;
   padding-bottom:0;
   background-color:white;
   color:black;
   
   
   
   
   
   .bereich{
   display:block;
   cursor:pointer;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow:hidden;
   }
   
   p{
   margin-bottom:0.375rem;
   }
`;