import React from 'react';
import styled from "styled-components";


const _RightMenu = ({className, menu}: any) => {
    return (
        <div className={className}>
            {menu}
        </div>
    )
};


export const RightMenu = styled(_RightMenu)`
  flex: 0 0 4.5rem;
`;