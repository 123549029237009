import React from 'react';
import styled from "styled-components";


const _LeftMenu = ({className, menu}: any) => {
    return (
        <div className={className}>
            {menu}
        </div>
    )
};

export const LeftMenu = styled(_LeftMenu)`
  flex: 0 0 5rem;
`;