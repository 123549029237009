import {IField} from "../components/dataset/IField";
import {DateRenderer} from "./_helper/date-renderer";
import {PpapCommentRenderer} from "./cell-renderers/PpapCommentRenderer";

export interface IPpapElementComment {
    ppapno: string;
    elementno: number;
    commentno: number;
    user: string;
    timestamp: string;
    comment: string;
    document: string;
}

export const ppapElementCommentColumns: IField<IPpapElementComment> [] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDER',
        primaryKey: true,
        rules: ['pk', 'mandatory'],
        hide: 'table',
        insert: 'hide',
    },
    {
        fieldName: 'elementno',
        dataType: 'number',
        label: 'PPAP_ELEMENTNO',
        primaryKey: true,
        rules: ['number', 'pk'],
        hide: 'table',
        insert: 'hide',
        grid: {
            width: 50
        }
    },
    {
        fieldName: 'commentno',
        dataType: 'number',
        label: 'PPAP_COMMENTNO',
        primaryKey: true,
        rules: ['number', 'pk'],
        hide: 'table',
        insert: 'hide',
        grid: {
            width: 50
        }
    },

    {
        fieldName: 'user',
        dataType: 'string',
        label: 'USER_USERNAME',
    },
    {
        fieldName: 'timestamp',
        label: 'DATE',
        dataType: 'string',
        input: 'calendar',
        grid: {
            cellRenderer: DateRenderer,
            width: 100,
        },
    },
    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'ACTION_COMMENT',
        input: 'textarea',
        insert: 'show',
        grid: {
            flex: 200,
            autoHeight: true,
            wrapText: true,
            cellStyle: {whiteSpace: 'pre-wrap', wordBreak: 'normal', lineHeight: '26px'},
            cellRenderer: PpapCommentRenderer
        }
    },
    {
        fieldName: 'document',
        label: 'PPAPDOCUMENT_DOCUMENT',
        dataType: 'string',
        insert: 'hide',
        hide: 'always',
        grid: {
            //cellRenderer:(params:any)=>'<a href="/gridApi/ppaporderdocument/'+params.data.ppapno+'/'+params.data.elementno+'/'+params.value+'" download >'+ params.value+'</a>',
        }
    },
];