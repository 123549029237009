import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";
import {TableGrid} from "../../_base/styled/table-grid";
import {TableLayout} from "../../_base/styled/layout/table-layout";
import {BaseTableFilterMenu} from "../../_base/table-filter/base-table-filter-menu";
import {CsvButtonDownload} from "../../_base/csv/csv-button-download";
import {CsvButtonUpload} from "../../_base/csv/csv-button-upload";
import {action} from "mobx";
import axios from "axios";
import {authorizer} from "../../../components/dataset/authorizer";

@inject('rootStore')
@observer
export class ProductTableView extends React.Component<IBaseView, {}> {


    @action.bound
    async uploadCsv(rawdata: Array<any>) {
        /**
         *  Insert/Update
         *  Falls productno schon existiert werden Felder geupdated.
         */
        const {rootStore: {productStore: {ds}}} = this.props;
        const fields = rawdata[0].data;
        const data = rawdata.filter((zeile: any, i: number) => i > 0)

        let newElements = data.map((record: any) => {
            let rv: any = {}
            fields.forEach((f: string, i: number) => {
                rv[fields[i]] = record.data[i]
            })

            return rv
        });
        for (const element of newElements) {
            if (element['productno']) {
                const g = await axios.get('gridApi/product/?productno=' + element['productno'], authorizer());
                if (g.data.data.length === 1) {
                    await axios.put('/gridApi/product/' + element['productno'], element, authorizer())
                } else {
                    await axios.post('/gridApi/product/', element, authorizer())
                }
            }
        }
        ds.close();
        await ds.open();
    }

    render() {
        const {rootStore: {productStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="products"
                    icon="database"
                    title='PRODUCTS'
                    ds={ds}
                    singleRouteName='product'
                    hideInsert={!hasUserRight(UsergroupRight.UR_BASE_PRODUCT_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_BASE_PRODUCT_DELETE)}
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                            <>
                                <BaseTableFilterMenu storeName="productStore" filtername="product"/>
                                <CsvButtonUpload onUpload={this.uploadCsv}/>
                                <CsvButtonDownload ds={ds}/>
                            </>
                            :
                            null


                    }
                />
            </TableLayout>
        )
    }
}