import * as React from 'react';
import {IBaseView} from "../../../../_base/base-view";
import {inject, observer} from "mobx-react";
import {BoxWithTree} from "../../../../_base/styled/box-with-tree";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuditDetailTree} from "./audit-detail-tree";
import {AuditDetailMainLeftMenu} from "./audit-detail-main-left-menu";


@inject('rootStore')
@observer
export class AuditDetailMainLeftElement extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {langStore: {lang}}} = this.props;
        return (
            <BoxWithTree
                header={
                    <>
                        <div className="left">
                            <FontAwesomeIcon icon={["fad", "book-open"]}/>
                            &nbsp;&nbsp;
                            {lang['INSPECTION_PLAN_QUESTIONS']}
                        </div>
                    </>
                }
                menu={<AuditDetailMainLeftMenu/>}
                body={<AuditDetailTree/>}
            />
        )
    }
}