import {IField} from "../components/dataset/IField";
import {catalogColumns} from "./Catalog";
import {includes} from "../components/lib/Includes";


export interface IGembaTemplate {
    gembatemplate: string;
    description: string;
    area_cat: string;
    element_cat: string;
}


export const gembaTemplateColumns: IField<IGembaTemplate>[] = [
    {
        fieldName: 'gembatemplate',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_TEMPLATE',
        primaryKey: true,
        rules: ['pk'],
        insert: 'show',
    },
    {
        fieldName: 'description',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_DESCRIPTION',
        insert: 'show',
    },
    {
        fieldName: 'area_cat',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_AREA_CAT',
        insert: 'hide',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/catalog/?catalogtyp=700',
            columns: catalogColumns.filter((field) => includes(['catalogno', 'description1'], field.fieldName)),
            field: 'catalogno',
        }
    },
    {
        fieldName: 'element_cat',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_ELEMENT_CAT',
        insert: 'hide',
        hide: 'always',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/catalog/?catalogtyp=800',
            columns: catalogColumns.filter((field) => includes(['catalogno', 'description1'], field.fieldName)),
            field: 'catalogno',
        }

    },
]


