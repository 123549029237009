import {BaseStore, IBaseStore} from "./base-store";
import {IPpapStandard, ppapStandardColumns} from "../models/PpapStandard";
import {action, observable} from "mobx";
import {IDataset} from "../components/dataset/IDataset";
import {PpapStandardElement, ppapStandardElementColumns} from "../models/PpapStandardElement";
import {Dataset} from "../components/dataset/dataset";
import {RouterState} from "mobx-state-router";


export interface IPpapStandardStore extends IBaseStore<IPpapStandard> {
    dsPpapMatrixDetail: IDataset<PpapStandardElement>;

}

export class PpapStandardStore extends BaseStore<IPpapStandard> implements IPpapStandardStore {

    @observable
    dsPpapMatrixDetail: IDataset<PpapStandardElement>;

    constructor(rootStore: any) {
        super(rootStore, '/gridApi/ppapstandard/', ppapStandardColumns);

        this.dsPpapMatrixDetail = new Dataset<PpapStandardElement>('/gridApi/ppapstandardelement/', ppapStandardElementColumns);
        this.dsPpapMatrixDetail.setMasterSource(this.ds, [{field: 'standardno', masterField: 'standardno'}]);
    }


    @action.bound
    async openDetails() {
        await this.dsPpapMatrixDetail.open();
    }

    @action.bound
    closeDetails() {
        this.dsPpapMatrixDetail.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        if (toState.routeName === 'ppapstandardtable') {
            await this.open();
            if (fromState.routeName === 'audit') {
                this.ds.locate(this.ds.pkFields, fromState.params)
            }
        } else if (toState.routeName === 'ppapstandard') {
            await this.open(toState.params as any);
            //this.ds.edit();
            await this.openDetails();
            if (fromState.routeName === 'ppapstandardelement') {
                this.dsPpapMatrixDetail.locate(this.dsPpapMatrixDetail.pkFields, {
                    standardno: fromState.params.standardno,
                    elementno: parseInt(fromState.params.elementno)
                });
            }
        } else if (toState.routeName === 'ppapstandardelement') {
            // Todo hier saubere Lsg.
            // standardno direkt belegen ist unschön
            await this.open({standardno: toState.params.standardno} as any);
            await this.openDetails();

            this.dsPpapMatrixDetail.locate(this.dsPpapMatrixDetail.pkFields, {
                standardno: toState.params.standardno,
                elementno: parseInt(toState.params.elementno)
            });
            /*this.dsPpapMatrixDetail.edit();*/
        }
    }


    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'ppapstandardelement') {
            /*await this.dsPpapMatrixDetail.post();*/
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'ppapstandard') {
            this.closeDetails();
            await this.ds.post();
            this.close();
        } else if (fromState.routeName === 'ppapstandardtable') {
            this.close();
            await Promise.resolve();
        }
    }


    @action.bound
    afterOpen(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'ppapstandard' && toState.routeName === 'ppapstandardtable') {
            this.ds.locate(this.ds.pkFields, fromState.params)
        }
    }

}