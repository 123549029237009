import {IField} from "../components/dataset/IField";


export interface ICustomer {
    customerno: string;
    customer1: string;
    customer2: string;
    description1: string;
    description2: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    logo: string;
    icon: string;
}


export const customerColumns: IField<ICustomer>[] = [
    {
        fieldName: 'customerno',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMERNO',
        primaryKey:true,
        rules:['pk']
    },
    {
        fieldName: 'customer1',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMER1',
        insert: 'show',
        rules: ['mandatory']
    },
    {
        fieldName: 'customer2',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMER2',
    },
    {
        fieldName: 'description1',
        dataType: 'string',
        label: 'CUSTOMER_DESCRIPTION1'
    },
    {
        fieldName: 'description2',
        dataType: 'string',
        label: 'CUSTOMER_DESCRIPTION2'
    },
    {
        fieldName: 'street',
        dataType: 'string',
        label: 'CUSTOMER_STREET'
    },
    {
        fieldName: 'postcode',
        dataType: 'string',
        label: 'CUSTOMER_POSTCODE'
    },
    {
        fieldName: 'city',
        dataType: 'string',
        label: 'CUSTOMER_CITY'
    },
    {
        fieldName: 'country',
        dataType: 'string',
        label: 'CUSTOMER_COUNTRY'
    },
];