import * as React from 'react';
import styled from "styled-components";
import {Button, CardFooter, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IBaseView} from "../../_base/base-view";
import {InspectionScore} from "../../../models/InspectionQuestion";


@inject('rootStore')
@observer
class _InspectionCollectFooter extends React.Component<IBaseView, {}> {
    render() {
        const {rootStore: {inspectionStore: {dsInspectionQuestion, nextElement, prevElement, actArea, actElement}}, className} = this.props;
        return (
            <CardFooter className={className}>
                <Row>
                    <div className={"left"}>
                        <Button
                            color="primary"
                            style={{borderRadius: '50%'}}
                            onClick={async () => {
                                await dsInspectionQuestion.post();
                                prevElement();
                                /*
                                await dsAuditQuestion.prev();
                                dsAuditQuestion.edit()*/
                            }}
                        >
                            <FontAwesomeIcon icon="arrow-left"/>
                        </Button>
                    </div>
                    <div className={"center"}>
                        {dsInspectionQuestion.data
                            .filter(question => question.area === actArea && question.element === actElement && question.score !== InspectionScore.UNRATED).length}
                        /
                        {dsInspectionQuestion.data
                            .filter(question => question.area === actArea && question.element === actElement).length}
                    </div>
                    <div className={"right"}>
                        <Button
                            color="primary"
                            style={{borderRadius: '50%'}}
                            onClick={async () => {
                                await dsInspectionQuestion.post();
                                nextElement();
                                /*
                                dsAuditQuestion.next();
                                dsAuditQuestion.edit();*/
                            }}
                        >
                            <FontAwesomeIcon icon="arrow-right"/>
                        </Button>
                    </div>
                </Row>
            </CardFooter>
        )
    }
}

export const InspectionCollectFooter = styled(_InspectionCollectFooter)`
   padding:0.5rem;
   background-color:${props => props.theme.cardHeaderBg};
   color:${props => props.theme.cardHeaderColor};
   .center{
       flex: 1 1 auto;
       text-align:center;
       margin:auto;
       font-size:1rem;
   }
   .left{
    flex: 0 0 5rem;
    padding-left:1rem;
   }
   
   .right{
    flex: 0 0 4rem;
   }
`;