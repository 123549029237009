import * as XLSX from 'xlsx';
import {IDataset} from "../../components/dataset/IDataset";


export const exporter = (ds: IDataset<any>, filename: string, tabsheetName: string) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(tabsheetName);
    wb.Sheets[tabsheetName] = XLSX.utils.json_to_sheet(ds.data);
    XLSX.writeFile(wb, filename + '.xlsx', {})

}