import {ILangKeys} from "../../language/ILang";
import * as email_validator from 'email-validator';

type IValidatorRuleFn = (val: string, val2: string) => true | ILangKeys;


export interface IValidatorRules {
    pk: IValidatorRuleFn;
    mandatory: IValidatorRuleFn;
    number: IValidatorRuleFn;
    dates: IValidatorRuleFn;
    integer: IValidatorRuleFn;
    equalto: IValidatorRuleFn;
}


class ValidatorRules implements IValidatorRules {

    pk = (val: string) => {
        return val === '' ? 'ERROR_PRIMARY_KEY_CANNOT_BE_EMPTY' : true;
    };

    mandatory = (val: string) => {
        return val === '' || val === null ? 'ERROR_MANDATORY_FIELD_CANNOT_BE_EMPTY' : true;
    };

    number = (val: string) => {
        const num = Number(val); // tries to convert value to a number. useful if value is coming from form element
        if (!isNaN(num)) {
            return true;
        } else {
            return 'ERROR_INVALID_NUMERIC_VALUE';
        }
    };

    dates = (val: string) => {
        return val ? true : ''
    };

    // Ganzzahl erwartet
    integer = (val: string) => {
        return val === '' || val === null || (String(parseInt(val, 10)) === String(val)) ? true : 'ERROR_INVALID_INTEGER_VALUE';
    };

    // email
    email = (val: string) => {
        return val === '' || email_validator.validate(val) ? true : 'ERROR_INVALID_INTEGER_VALUE';
    };

    // equalto:fieldName
    equalto = (val: string, val2: any) => {

        return val === val2 ? true : 'ERROR_VALUE_IS_NOT_EQUAL';
    };

}

export const validatorRules = new ValidatorRules();


