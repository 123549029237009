import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";
import {TableGrid} from "../../_base/styled/table-grid";
import {TableLayout} from "../../_base/styled/layout/table-layout";

@inject('rootStore')
@observer
export class SupplierTableView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {supplierStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="suppliers"
                    icon="database"
                    title='SUPPLIERS'
                    ds={ds}
                    singleRouteName='supplier'
                    hideInsert={!hasUserRight(UsergroupRight.UR_BASE_SUPPLIER_INSERT)}
                    hideDelete={!hasUserRight(UsergroupRight.UR_BASE_SUPPLIER_DELETE)}
                />
            </TableLayout>
        )
    }
}