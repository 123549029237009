import {
    cl_blue,
    cl_card_blue,
    cl_dark_blue,
    cl_gray200,
    cl_gray300,
    cl_gray400,
    cl_red,
    cl_white
} from "./colors";
import {lighten} from "polished";





export const theme = {

    bg: cl_white, // Hintergrund der Cards etc.
    bgBody: cl_gray300, // allgemeiner Hintergrund
    bgHover: cl_gray400, // Standard Hover Farbe
    //
    color: cl_blue, // Schriftfarbe
    border: '1px solid ' + cl_gray400, // Borderrad der Cards
    borderRadius: '0.5rem',
    //
    boxShadow: '5px 5px 10px   rgba(0, 0, 0, 0.15)', // Shadow der Cards
    boxShadowInset: 'inset 5px 5px 10px   rgba(0, 0, 0, 0.15)', // Shadow für Header und Sidebar als Inset
    //
    gutter: '1rem', // Spaltbreite


    box: {
        header: {
            backgroundColor: cl_white,
            color: cl_blue,
            padding: '0.5rem 0.75rem',
            fontSize: '1.125rem',
            fontWeight: 500,
        },
        menu: {
            flex: '0 0 37px',
            padding: '0.125rem 0.75rem',
            justifyContent: 'flex-end',
        },

    },

    // Dialoge
    modal: {
        backgroundColor: lighten(0.2, cl_blue)
    },

    // Layout Header
    header: {
        color: cl_blue,
        bg: cl_white,
        minHeight: '2.5rem',
    },


    // Altes Zeug

    card: {
        border: '1px solid ' + cl_gray400,
        borderRadius: '0.25rem',
        cardHeader: {},
        cardBody: {}
    },

    danger: cl_red,
    cardHeaderBg: cl_card_blue,
    cardHeaderButtonBg: cl_dark_blue,
    cardHeaderButtonColor: cl_white,
    cardHeaderColor: 'white',
    cardRadius: '0.25rem',
    cardGridButtonBg: cl_blue,
    cardGridButtonColor: cl_white,

    main: {
        bg: cl_gray200,
        color: cl_blue,
        borderBottom: '1px solid #a5b1c2',
        gutterSizeRem: 1,
    },
};

