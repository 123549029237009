import {RouterState, RouterStore} from "mobx-state-router";
import {IBaseStore} from "../stores/base-store";


/**
 * Check if Token exists, if not redirect to login
 *
 * @param fromState
 * @param toState
 * @param routerStore
 */
export const checkForUserSignedIn = (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
    const {
        rootStore: {authStore}
    } = routerStore;
    if (authStore.username) {
        return Promise.resolve();
    } else {
        authStore.setSignInRedirect(toState);
        return Promise.reject(new RouterState('login'));
    }
};

/**
 * @param userright : must be true to go ahead
 * @param storeName : optional store, where we finde 'beforeEnter' method, checking additional conditions.
 */
export const checkUserRight = (userright: number, storeName?: string) =>
    async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
        const {rootStore: {authStore}} = routerStore;
        if (authStore.username === '' && authStore.usernameFromToken() === '') {
            authStore.setSignInRedirect(toState);
            await Promise.reject(new RouterState('login'));
        } else {
            if (authStore.username !== authStore.usernameFromToken()) {
                authStore.username = authStore.usernameFromToken();
                await authStore.loadUser();
            }
            if (!authStore.hasUserRight(userright)) {
                await Promise.reject(new RouterState('nopermission'));
            } else {
                if (storeName) {
                    const store = routerStore.rootStore[storeName];
                    const permission = await store.beforeEnter(fromState, toState);
                    if (!permission) {
                        await Promise.reject(new RouterState('nopermission'));
                    }
                } else {
                    await Promise.resolve();
                }
            }
        }
    }


export const onEnter = (storeName: string) => {
    return async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
        const store: IBaseStore<any> = routerStore.rootStore[storeName];
        await store.onEnter(fromState, toState);
    }
};

export const onExit = (storeName: string) => {
    return async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
        const store = routerStore.rootStore[storeName];
        await store.onExit(fromState, toState);
    }
};