import {IField} from "../components/dataset/IField";


export interface IPpapDetail04 {
    ppapno: string;
    ecl01: string;
    ecldate01: string;
    description01: string;
    reason01: string;
    ecl02: string;
    ecldate02: string;
    description02: string;
    reason02: string;
    ecl03: string;
    ecldate03: string;
    description03: string;
    reason03: string;
    ecl04: string;
    ecldate04: string;
    description04: string;
    reason04: string;
    ecl05: string;
    ecldate05: string;
    description05: string;
    reason05: string;
    ecl06: string;
    ecldate06: string;
    description06: string;
    reason06: string;
    ecl07: string;
    ecldate07: string;
    description07: string;
    reason07: string;
}

export const ppapDetail04Columns: IField<IPpapDetail04> [] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDERNO',
        primaryKey: true,
        rules: ['pk', 'mandatory'],
        insert: 'hide'
    },
];