import {IField} from "../components/dataset/IField";
import {ProductDocumentsAttachmentRender} from "./cell-renderers/ProductDocumentsAttachmentRender";


export interface IProjectDocument {
    projectno: string;
    activityno: string;
    documentno: string;
    document: string;
}

export const projectDocumentColumns:IField<IProjectDocument>[]=[
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        primaryKey: true,
        rules:['pk'],
        hide: 'table',
        insert: 'hide',
    },
    {
        fieldName: 'activityno',
        label: 'PROJECTTASK_TASKNO',
        dataType: 'string',
        //hide:'always',
        primaryKey: true,
        rules: ['pk'],
        grid:{
            width:80
        }
    },
    {
        fieldName: 'documentno',
        label: 'PROJECTDOCUMENT_DOCUMENTNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        insert: 'show',
        grid: {
            width: 60,
        }
    },
    {
        fieldName: 'document',
        label: 'PROJECTDOCUMENT_DOCUMENT',
        dataType: 'string',
        insert: 'show',
        grid: {
            cellRenderer: ProductDocumentsAttachmentRender,
            flex: 2
        }
    },
];
