import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../../_base/base-view";
import {StyledTable} from "../../../order/right/ppap-upload-tab/styled-table";
import {TableField} from "../../helper/FormEditor";
import {dsState} from "../../../../../components/dataset/IDataset";


export const PpapReportPartHistory = inject('rootStore')(observer((props: IBaseView) => {
            const {rootStore: {ppapStore: {dsPpapDetail04}}} = props;
            return (
                <StyledTable width={'100%'}>
                    <thead>
                    <tr>
                        <td style={{width: '12%'}}>
                            ECL
                        </td>
                        <td style={{width: '12%'}}>
                            ECL Date
                        </td>
                        <td>
                            Description of Change
                        </td>
                        <td>
                            Change Reason
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {[...Array(7).keys()].map((v, i) =>
                        <tr key={i}>
                            <td>
                                <TableField
                                    ds={dsPpapDetail04} fieldName={"ecl0" + (i + 1).toString()}
                                    readonly={!(dsPpapDetail04.state === dsState.dsEdit)}
                                    maxLength={30}
                                />
                            </td>
                            <td>
                                <TableField
                                    ds={dsPpapDetail04} fieldName={"ecldate0" + (i + 1).toString()}
                                    readonly={!(dsPpapDetail04.state === dsState.dsEdit)}
                                    maxLength={30}/>
                            </td>
                            <td>
                                <TableField
                                    ds={dsPpapDetail04} fieldName={"description0" + (i + 1).toString()}
                                    maxLength={64}
                                    readonly={!(dsPpapDetail04.state === dsState.dsEdit)}
                                />
                            </td>
                            <td>
                                <TableField
                                    ds={dsPpapDetail04} fieldName={"reason0" + (i + 1).toString()}
                                    maxLength={64}
                                    readonly={!(dsPpapDetail04.state === dsState.dsEdit)}/>
                            </td>
                        </tr>
                    )}

                    </tbody>
                </StyledTable>
            )
        }
    )
)
