import * as React from 'react';
import {IBaseView} from "../base-view";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";
import {ILangKeys} from "../../../language/ILang";
import {IconName} from "@fortawesome/fontawesome-common-types";
import classnames from "classnames";
import {cl_blue, cl_white} from "../../_theme/colors";


export interface ITab {
    title: ILangKeys;
    icon: IconName;
    content: JSX.Element;
    menu?: JSX.Element;
}

interface ICardWithTab extends IBaseView {
    tabs: ITab[];
}

@inject('rootStore')
@observer
class _CardWithTab extends React.Component<ICardWithTab, {}> {

    @observable
    activeTab: number = 0;

    @action.bound
    setActiveTab(tab: number) {
        this.activeTab = tab;
    }

    render() {
        const {rootStore: {langStore: {lang}}, className, tabs} = this.props;
        return (
            <div className={className}>
                <div className="cardheader">
                    {tabs.map((tab, i) =>
                        <div key={i} className={classnames({tab, active: this.activeTab === i})}
                             onClick={() => {
                                 this.setActiveTab(i)
                             }}>
                            <FontAwesomeIcon icon={["fad", tab.icon]}/>
                            &nbsp;&nbsp;
                            {lang[tab.title]}
                        </div>
                    )}
                </div>
                <div className="menu">
                    {tabs.find((tab, i) => this.activeTab === i)?.menu ?? null}
                </div>
                <div className="cardbody">
                    {tabs.find((tab, i) => this.activeTab === i)?.content ?? null}
                </div>
            </div>
        )
    }
}


export const CardWithTab = styled(_CardWithTab)`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.card.borderRadius};
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.bg};
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border};
  box-shadow: ${props => props.theme.boxShadow};


  .cardheader {
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    background-color: ${cl_white};
    color: ${props => props.theme.color};
    border-bottom: ${props => props.theme.border};
    padding: 0;


    > .tab {
      flex: 0 0 auto;
      padding: ${props => props.theme.box.header.padding};
      border-right: ${props => props.theme.border};
      //height: 100%;
      cursor: pointer;
      background-color: ${props => props.theme.bg};
      font-size: ${props => props.theme.box.header.fontSize};
      user-select: none;
      font-weight: ${props => props.theme.box.header.fontWeight};

      :hover {
        background-color: ${props => props.theme.color};
        color: ${props => props.theme.bg};
      }

    }


    .active {

      padding: ${props => props.theme.box.header.padding};
      padding-bottom: 0.25rem;
      border-bottom: 0.25rem solid ${cl_blue};
      background-color: ${cl_white};
      font-weight: ${props => props.theme.box.header.fontWeight};

      background-color: ${props => props.theme.bgBody};
      color: ${props => props.theme.color};
        //border-bottom: 4px solid ${cl_blue};

      :hover {
        background-color: ${props => props.theme.bgBody};
        color: ${props => props.theme.color};
          //border-bottom: 4px solid ${cl_blue};
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    flex: ${props => props.theme.box.menu.flex};
    border-bottom: ${props => props.theme.border};
    padding: ${props => props.theme.box.menu.padding};
    justify-content: ${props => props.theme.box.menu.justifyContent};
  }

  .cardbody {
    //display:block;  
    position: relative;
    flex: 1 1 100%;
    background-color: white;
    height: 100%;
    overflow-y: auto;

    h5, label, input, textarea {
      color: ${props => props.theme.color};
    }

    .ag-root-wrapper {
      border: 0;
    }
  }

  .formmenu {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0.5rem;

    button {
      background-color: ${props => props.theme.bg};
      border-style: none;
    }

`;