import React from "react";
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {TableLayout} from "../../_base/styled/layout/table-layout";
import {TableGrid} from "../../_base/styled/table-grid";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";
import {BaseTableFilterMenu} from "../../_base/table-filter/base-table-filter-menu";

@inject('rootStore')
@observer
export class LogbookTableView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {logbookStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="logbook"
                    icon="book-open"
                    title='LOGBOOK'
                    ds={ds}
                    hideInsert={true}
                    hideDelete={true}
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                            <BaseTableFilterMenu storeName="logbookStore"
                                                 filtername="logbook"/> :
                            null
                    }
                />
            </TableLayout>)
    }
}