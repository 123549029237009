import * as R from 'ramda';
import * as React from "react";
import {computed} from 'mobx';
import {observer, inject} from "mobx-react";
import {AgGridReact} from "ag-grid-react";
import {IRootStore} from "../../routes/root-store";
import {IDataset} from "../dataset/IDataset";
import {IField} from "../dataset/IField";
import {fieldToColDef} from "./fieldtocoldef";
import {IGroupDef, makeGroupDef} from "../dataset/groupDef";

// ag-grid
import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css';

interface DBGridProps<T> {
    rootStore?: IRootStore;
    className?: string;
    ds: IDataset<T>;
    groupDef?: IGroupDef[];
    onRowDoubleClicked?: any; // was ist der korrekte Typ?
    onRowChanged?: (cursor: number) => void;
    //onColumsResized?:(e:any)=>void;
    onGridReady?: (params: any) => void;
}


@inject('rootStore')
@observer
export class DbGrid<T> extends React.Component<DBGridProps<T>, undefined> {


    constructor(props: DBGridProps<T>) {
        super(props);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.setCursorSelection = this.setCursorSelection.bind(this);
    }

    gridApi: any;
    columnApi: any;

    onGridReady(params: any) {
        let {onGridReady} = this.props;
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.setCursorSelection();
        if (onGridReady) {
            onGridReady(params);
        }
    }

    // sample.Cursor update caused by Grid's selection ( mouse-click or similar)
    onRowSelected(event: any) {
        let {ds} = this.props;
        if (event.node.selected) {
            ds.setCursor(event.node.rowIndex);
        }
    }

    //
    onModelUpdated() {
        this.setCursorSelection();
    }

    // set the selection of Grid to sample.Cursor position
    setCursorSelection() {
        let {ds, onRowChanged} = this.props;
        if (ds.cursor !== null && this.gridApi) {
            this.gridApi.forEachNode((node: any, i: number) => {
                if (i === ds.cursor) {
                    node.setSelected(i === ds.cursor, true);
                    if (onRowChanged) {
                        onRowChanged(ds.cursor);
                    }
                }
            });
        }
    }

    // translation of grids built-in text
    static localeTextFunc(key: string, defaultValue: string) {
        //let lang = this.props.session.lang;
        //let value = lang['GRID_' + key];
        return defaultValue;
    }


    // mapping our IField[] into ag-grid's ColDef[]
    @computed
    get colDefs(): any[] {
        const {rootStore: {langStore: {lang}}, ds, groupDef} = this.props;
        let columns: IField<T>[] = R.clone(ds.columns)
        columns = columns.filter((c: IField<T>) => ((c.hide !== 'table') && (c.hide !== 'always')))
            .map(fieldToColDef(lang));
        return groupDef ? makeGroupDef(columns, groupDef, lang) : columns;

    }


    render() {
        let {ds, onRowDoubleClicked} = this.props;
        this.setCursorSelection();
        return (
            <div className="ag-theme-balham" style={{
                position: 'relative',
                height: "100%",
                border: 0,
                fontFamily: 'Source Sans Pro, sans-serif',
                color: 'black'
            }}>
                <AgGridReact
                    //reactUi={true}
                    rowSelection="single"
                    suppressCellFocus={true}
                    //enableFilter={false}
                    onRowSelected={this.onRowSelected}
                    onRowDoubleClicked={onRowDoubleClicked}
                    onModelUpdated={this.onModelUpdated}
                    rowData={ds.data ? ds.data.slice() : ds.data}
                    //onColumnResized={onColumsResized}
                    columnDefs={this.colDefs}
                    onGridReady={this.onGridReady}
                    /* localeTextFunc={DbGrid.localeTextFunc.bind(this)}*/
                    overlayNoRowsTemplate="<span></span>"
                    //getRowHeight={() => 28}
                />
            </div>
        );
    }
}



