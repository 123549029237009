import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import ReactToPrint from "react-to-print";
import {Button} from "reactstrap";
import {PrintContainer} from "../../_shared/report/print-container";
import {InspectionReportHeader} from "./Inspection-report-header";
import {InspectionReportResult} from "./inspection-report-result";


@inject('rootStore')
@observer
export class InspectionReport extends React.Component<IBaseView, {}> {

    componentRef: any;


    render() {
        //const {rootStore: {inspectionStore: {getCatValue, dsInspectionQuestion}}} = this.props;


        return (
            <div>
                <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => this.componentRef}
                    pageStyle={":global {\n" +
                    "    .patchPrintStyle {\n" +
                    "        section {\n" +
                    "            display: block !important;\n" +
                    "        }\n" +
                    "        article {\n" +
                    "            height: 100% !important;\n" +
                    "        }\n" +
                    "    }\n" +
                    "}"}
                />
                <PrintContainer ref={(el: any) => (this.componentRef = el)}>
                    <InspectionReportHeader/>
                    <InspectionReportResult/>
                </PrintContainer>

            </div>

        );
    }
}