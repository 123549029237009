import styled from "styled-components";
import {Card, CardBody, CardHeader} from "reactstrap";
import * as React from "react";
import {IBaseView} from "../base-view";
import {cl_gray500} from "../../_theme/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IDataset} from "../../../components/dataset/IDataset";
import {inject, observer} from "mobx-react";
import {ILangKeys} from "../../../language/ILang";
import {IconName} from "@fortawesome/fontawesome-common-types";
import {DbForm} from "../../../components/dbform/db-form";
import {TableFormMenu} from "./table-form-menu";
import {action, observable} from "mobx";
import {TableEditMenu} from "./table-edit-menu";

export interface ITableForm extends IBaseView {
    open?: boolean;
    icon: IconName;
    title: ILangKeys;
    keyTitle?: string;
    ds?: IDataset<any>; // für offline mode
    extraMenu?: JSX.Element;
    hideMenu?: boolean;
    ownerField?: string;
    onClickOwner?: () => Promise<any>;
}

@inject('rootStore')
@observer
class _TableForm extends React.Component<ITableForm, {}> {

    @observable
    open: boolean = false;

    @action.bound
    toggle() {
        this.open = !this.open;
    }


    constructor(props: ITableForm) {
        super(props);
        this.open = props.open ?? true;
    }

    render() {
        const {
            rootStore: {langStore: {lang}},
            className,
            title,
            keyTitle,
            children,
            icon,
            ds,
            extraMenu,
            hideMenu,
            ownerField,
            onClickOwner
        } = this.props;
        return (
            <Card className={className}>
                <CardHeader className="cardheader">
                    <div className="left">
                        <FontAwesomeIcon icon={["fad", icon]}/> &nbsp;&nbsp;&nbsp;
                        {lang[title]}&nbsp;&nbsp;<span className="keyTitle">{keyTitle ?? ''}</span>
                        &nbsp;
                        &nbsp;
                        {ds ? ds.actual?.[ownerField] ?
                            <div className="owner" onClick={onClickOwner}>
                                &nbsp;
                                &nbsp;
                                <FontAwesomeIcon icon={["fad", "user"]}/>
                                &nbsp;
                                {ds.actual?.[ownerField]}
                                &nbsp;&nbsp;
                            </div>
                            : ''
                            : ''
                        }
                        &nbsp;
                    </div>
                    <div className="right">
                        <TableFormMenu open={this.open} toggle={this.toggle} extraMenu={extraMenu}/>
                    </div>
                </CardHeader>
                {this.open ?
                    <div className="menu">
                        {ds ? <TableEditMenu ds={ds} hidden={hideMenu}/> : null}

                    </div>
                    :
                    null
                }
                {this.open ?
                    <CardBody className="cardbody">
                        {ds ?
                            <DbForm ds={ds}>
                                {children}
                            </DbForm> :
                            children
                        }
                    </CardBody> :
                    null}
            </Card>
        )
    }

}

export const TableForm = styled(_TableForm)`
  color: black;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: visible; // geändert damit PPAP Druckmenü sichtbar bleibt.

  background-color: ${props => props.theme.bg};
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border};
  box-shadow: ${props => props.theme.boxShadow};

  .cardheader {
    border-radius: ${props => props.theme.borderRadius};
    justify-content: space-between;
    align-items: center;
    display: flex;

    padding: ${props => props.theme.box.header.padding};
    font-size: ${props => props.theme.box.header.fontSize};
    font-weight: ${props => props.theme.box.header.fontWeight};
    background-color: ${props => props.theme.box.header.backgroundColor};
    color: ${props => props.theme.box.header.color};
    border-bottom: 0;
    cursor: default;

    .keyTitle {
      font-weight: ${props => props.theme.box.header.fontWeight};
    }

    .left {
      .owner {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        vertical-align: middle;
        color: ${props => props.theme.bg};
        background-color: ${props => props.theme.color};
        border-radius: 1rem;
        padding-bottom: 1px;
        margin-bottom: 0.25rem;
      }
    }

    .right {
      font-weight: 100;
      color: ${cl_gray500};

      .owner {
        display: inline - block;
        font-size: 12px;
        font-weight: 500;
        vertical-align: middle;
        color: ${props => props.theme.bg};
        background-color: ${props => props.theme.color};
        border-radius: 1rem;
        padding-bottom: 1px;
        margin-bottom: 0.25rem;
      }

    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    flex: ${props => props.theme.box.menu.flex};
    border-top: ${props => props.theme.border};
    padding: ${props => props.theme.box.menu.padding};
    justify-content: ${props => props.theme.box.menu.justifyContent};
  }

  .cardbody {
    display: block;
    position: relative;
    border-top: ${props => props.theme.border};
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem 0.75rem; //${props => props.theme.box.header.padding};


    label, input, textarea {
      color: ${props => props.theme.color};
    }


    .formmenu {
      display: inline - block;
      position: absolute;
      top: 0;
      right: 0.5rem;

      button {
        background-color: ${props => props.theme.bg};
        border-style: none;: focus {
        outline: none;
      }
      }
    }


  }

`;