import * as React from "react"
import {IBaseView} from "../_base/base-view";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {Col, Row} from "reactstrap";
import {IconButton} from "../_base/styled/icon-button";
import {action} from "mobx";
import {DbField} from "../../components/dbform/db-field";
import {BaseCardForm} from "../_base/form/base-card-form";


@inject('rootStore')
@observer
class _AuthRequestPasswordLinkView extends React.Component<IBaseView, {}> {


    @action.bound
    async onSendLink() {
        const {rootStore: {routerStore, authStore: {cdsLogin, sendRequestPasswordLinkMail}}} = this.props;
        //console.log(cdsLogin.validator.check(cdsLogin.actual));
        await sendRequestPasswordLinkMail(cdsLogin.actual.email);
        routerStore.goTo('login')

    }


    render() {
        const {
            rootStore: {
                authStore: {cdsLogin},
                langStore: {lang},
            },
            className
        } = this.props;
        return (
            <div className={className}>
                <Row style={{alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                    <Col xl={3} lg={4} md={6} sm={6}>
                        <BaseCardForm
                            title={'LOGIN_FORGOT_PASSWORD'}
                            height={160}
                            ds={cdsLogin}
                            footerContent={
                                <Row style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    height: '100%',
                                    width: '100%',
                                    justifyContent: 'center',
                                    backgroundColor: 'white',
                                    margin: 0
                                }}>
                                    <div style={{flex: '0 0 auto'}}>
                                        <IconButton id="login-btn" color="primary"
                                                    onClick={this.onSendLink}>{lang.LOGIN_SEND_LINK_TO_EMAIL}</IconButton>
                                    </div>
                                </Row>
                            }
                        >
                            <p>Enter your email and we will send you a link to reset your password</p>
                            <DbField ds={cdsLogin} field="email"/>
                        </BaseCardForm>
                    </Col>
                </Row>
            </div>
        )
    }
}


export const AuthRequestPasswordLinkView = styled(_AuthRequestPasswordLinkView)`
  background-color: ${props => props.theme.bg};
  background-image: url("/img/background.jpg");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
`;