import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {Col, Container, Row} from "reactstrap";
import {BaseCardForm} from "../../_base/form/base-card-form";
import {DbField} from "../../../components/dbform/db-field";
import {BaseCardGrid} from "../../_base/grid/base-card-grid";
import {action} from "mobx";
import styled from "styled-components";
import {cl_gray300} from "../../_theme/colors";
import {BaseCardImage} from "../../_base/image/base-card-image";
import {InspectionCollectRating} from "../collect/inspection-collect-rating";
import {InspectionScore} from "../../../models/InspectionQuestion";
import {RoundHeaderBtn} from "../../_base/styled/round-header-btn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const QuestionMenu = inject('rootStore')((props: IBaseView) => {
    const {rootStore: {inspectionStore: {dsInspectionQuestion},}} = props;
    return (
        <>
            <RoundHeaderBtn onClick={dsInspectionQuestion.prev}> <FontAwesomeIcon icon="caret-left"/></RoundHeaderBtn>
            <RoundHeaderBtn onClick={dsInspectionQuestion.next}><FontAwesomeIcon icon="caret-right"/></RoundHeaderBtn>
        </>
    )
})


const Score = styled.div`
  background-color: ${cl_gray300};
`;


@inject('rootStore')
@observer
export class InspectionQuestionView extends React.Component<IBaseView, {}> {

    @action.bound
    goToAction() {
        const {rootStore: {routerStore, inspectionStore: {dsAction,}}} = this.props;
        routerStore.goTo('action', {no: dsAction.actual.no})
    }

    render() {
        const {inspectionStore: {dsInspectionQuestion, dsAction, images, uploadFile, dsImage}, langStore: {lang}} = this.props.rootStore;
        return (
            <Container fluid>
                <Row style={{paddingTop: '1rem'}}>
                    <Col md={6}>
                        <BaseCardForm
                            title='AUDIT_TEMPLATE_QUESTION'
                            ds={dsInspectionQuestion}
                            showBackButton={true}
                            rightHeaderMenu={<QuestionMenu/>}>
                            <Row>
                                <Col>
                                    <DbField ds={dsInspectionQuestion} field="inspectionno"/>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    <DbField ds={dsInspectionQuestion} field="area"/>
                                </Col>
                                <Col md={6}>
                                    <DbField ds={dsInspectionQuestion} field="element"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <DbField ds={dsInspectionQuestion} field="question"/>
                                </Col>
                                <Col md={6}>
                                    <DbField ds={dsInspectionQuestion} field="requirements"/>
                                </Col>
                            </Row>
                        </BaseCardForm>
                    </Col>
                    <Col md={6}>
                        <BaseCardForm title='AUDIT_ANSWER' ds={dsInspectionQuestion}>
                            <Row>

                                <Col>
                                    <DbField ds={dsInspectionQuestion} field="questionno"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <DbField ds={dsInspectionQuestion} field="answer"/>
                                </Col>
                                <Col md={6}>
                                    <DbField ds={dsInspectionQuestion} field="evidence"/>
                                </Col>
                            </Row>
                            <Row style={{alignItems: 'center'}}>
                                <Col sm={10}>
                                    <InspectionCollectRating/>
                                </Col>
                                <Col sm={2} style={{textAlign: 'center', fontSize: '1rem'}}>
                                    <Row/>
                                    <Score>
                                        {lang.AUDIT_SCORE}<br/>
                                        {dsInspectionQuestion.actual?.score === InspectionScore.NOT_APPLICABLE ?
                                            lang['AUDIT_RATING_NA'] :
                                            dsInspectionQuestion.actual?.score === -1 ?
                                                '' :
                                                dsInspectionQuestion.actual?.score === -2 ?
                                                    '' :
                                                    dsInspectionQuestion.actual?.score}
                                    </Score>

                                </Col>
                            </Row>
                        </BaseCardForm>
                    </Col>
                </Row>
                <Row style={{paddingTop: '1rem'}}>
                    <Col md={6}>
                        <BaseCardImage title="IMAGES" height={300} images={images} uploadFile={uploadFile}
                                       ds={dsImage}/>
                    </Col>
                    <Col md={6}>
                        <BaseCardGrid
                            id="action"
                            title='ACTIONS'
                            ds={dsAction}
                            onRowDoubleClicked={this.goToAction}
                            //rightHeaderMenu={<AuditActionMenu/>}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}