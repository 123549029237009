import * as React from 'react';
import {ISidebarEntry} from "./SidebarMenu";
import {inject, observer} from "mobx-react";
import {action} from "mobx";
import {SidebarItem} from "./SidebarItem";


@inject('rootStore')
@observer
export class SidebarDropdown extends React.Component<ISidebarEntry, {}> {


    @action.bound
    toggle() {
        const {rootStore: {authStore: {toggelOpenRoute}, appStore: {sidebarOpen, openSidebar}}, route} = this.props;
        if (!sidebarOpen) {
            openSidebar()
        }
        route.isOpen = !route.isOpen;
        toggelOpenRoute(route)
    }


    render() {
        const {rootStore: {authStore: {userRights}}, route} = this.props;

        return (

            <ul className={route.isOpen ? "open" : ""}>
                <SidebarItem route={route} isOpen={route.isOpen} toggle={this.toggle}/>
                {
                    route.isOpen
                        ?
                        route.children
                            .filter((route) => route.isNavBar)
                            .filter(route => route.userRight ? userRights[route.userRight] : false)
                            .map((route, i) => <SidebarItem className="child" key={i} route={route}/>)
                        :
                        null
                }
            </ul>


        )
    }
}