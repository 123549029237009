import {IBaseView} from "../../../_base/base-view";
import React from "react";
import {inject, observer} from "mobx-react";
import {CardWithTab, ITab} from "../../../_base/styled/card-with-tab";
import {PpapUploadFileTab} from "./ppap-upload-tab/ppap-upload-file-tab";
import {Status} from "../../../../models/Status";
import {uploadReportConfiguration} from "./ppap-upload-tab/upload-report-configuration";
import {PpapUploadMenu} from "./ppap-upload-tab/ppap-upload-menu";
import {PpapElementTab} from "./ppap-element-tab";
import {TableEditMenu} from "../../../_base/styled/table-edit-menu";
import {PpapBlogTab} from "./ppap-blog-tab";
import {MenuFileUpload} from "../../../_base/menu/menu-file-upload";
import {MenuInsert} from "../../../_base/menu/menu-insert";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {
    PpapReportTeamFeasibilityCommitment
} from "../../report/pages/02-team-feasibility-commitment/ppap-report-team-feasibility-commitment";
import ReactToPrint from "react-to-print";
import {IconButton} from "../../../_base/styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PpapReportPartHistory} from "../../report/pages/04-part-history/ppap-report-part-history";
import {
    PpapReportCustomerEngineeringApproval
} from "../../report/pages/05-customer_engineering_approval/ppap-report-customer-engineering-approval";
import {
    PpapReportPartSubmissionWarrant
} from "../../report/pages/18-part-submission-warrant/ppap-report-part-submission-warrant";
import {PpapReportBasepage} from "../../report/ppap-report-basepage";


const pageStyle = `
  @page {
   size: auto;
   margin: 20mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

@inject('rootStore')
@observer
export class PpapOrderRightView extends React.Component<IBaseView, {}> {

    printRef: any = null;

    getUploadTab = (): ITab => {
        const {
            rootStore: {
                ppapStore: {
                    dsPpapOrderElement,
                    dsPpapOrderDocument,
                },
                authStore: {username}
            }
        } = this.props;

        return {
            title: "PPAP_UPLOADS",
            icon: "file",
            content: (
                <PpapUploadFileTab
                    element={dsPpapOrderElement.actual}
                    dsDocument={dsPpapOrderDocument}
                    hideCursor={
                        username !== dsPpapOrderElement.actual?.responsible ||
                        dsPpapOrderElement.actual?.status === Status.APPROVED ||
                        dsPpapOrderElement.actual?.status === Status.COND_APPROVED
                    }
                    {...uploadReportConfiguration(dsPpapOrderElement.actual?.elementno)}
                />
            ),
            menu: <PpapUploadMenu/>
        }
    }

    getElementTab = (): ITab => {
        const {
            rootStore: {
                ppapStore: {
                    ds,
                    dsPpapOrderElement,
                },
                authStore: {username}
            }
        } = this.props;
        return {
            title: "PPAP_ELEMENT",
            icon: "check-square",
            content: (<PpapElementTab/>),
            menu: (<TableEditMenu ds={dsPpapOrderElement}
                                  hidden={
                                      (
                                          username !== ds.actual?.owner &&
                                          username !== dsPpapOrderElement.actual?.responsible
                                      ) ||
                                      (
                                          username === dsPpapOrderElement.actual?.responsible &&
                                          (
                                              dsPpapOrderElement.actual?.status === Status.APPROVED ||
                                              dsPpapOrderElement.actual?.status === Status.COND_APPROVED
                                          )
                                      )

                                  }/>)
        }
    }


    getBlogTab = (): ITab => {
        const {
            rootStore: {
                ppapStore: {
                    ds,
                    dsPpapOrderElement,
                    dsPpapElementComment,
                    uploadBlogFile
                },
                authStore: {username}
            }
        } = this.props;
        return {
            title: "PPAP_BLOG",
            icon: "comments",
            content: (<PpapBlogTab/>),
            menu: (
                <div>
                    {(username === dsPpapOrderElement.actual?.responsible || username === ds.actual?.owner) ?
                        <>
                            <MenuFileUpload
                                id={"PPAP_ELEMENT_COMMENT_btn-insert"}
                                onDrop={uploadBlogFile}
                                iconName="paperclip"
                                //disabled={dsPpapElementComment.cursor === undefined}
                            />
                            <MenuInsert
                                id="id_ppap_comment"
                                ds={dsPpapElementComment}
                            />
                            <MenuDelete
                                id="id_ppap_comment"
                                ds={dsPpapElementComment}
                                disabled={dsPpapElementComment.cursor === undefined || (username !== dsPpapElementComment.actual.user)}
                            />
                        </>
                        : null}
                </div>
            )
        }

    }

    getFormTab = (): ITab => {
        const {
            rootStore: {
                ppapStore: {
                    dsPpapOrderElement,
                    dsPpapDetail02,
                    dsPpapDetail04,
                    dsPpapDetail05,
                    dsPpapDetail18,
                },
                authStore: {username}
            }
        } = this.props;
        switch (dsPpapOrderElement.actual?.elementno) {
            case 2:
                return {
                    title: "PPAP_TEMPLATE",
                    icon: "file-edit",
                    content: (
                        <div style={{margin: '1rem'}} ref={(ref) => {
                            this.printRef = ref
                        }}>
                            <PpapReportBasepage element={dsPpapOrderElement.actual}>
                                <PpapReportTeamFeasibilityCommitment/>
                            </PpapReportBasepage>
                        </div>),
                    menu: username === dsPpapOrderElement.actual?.responsible ?
                        <>
                            <ReactToPrint
                                content={() => this.printRef}
                                trigger={() => <IconButton><FontAwesomeIcon icon={["fad", "print"]}/></IconButton>}
                                pageStyle={pageStyle}
                            />
                            <TableEditMenu ds={dsPpapDetail02} hidden={false}/>
                        </> :
                        null
                }

            case 4:
                return {
                    title: "PPAP_TEMPLATE",
                    icon: "file-edit",
                    content: (
                        <div style={{margin: '1rem'}} ref={(ref) => {
                            this.printRef = ref
                        }}>
                            <PpapReportBasepage element={dsPpapOrderElement.actual}>
                                <PpapReportPartHistory/>
                            </PpapReportBasepage>
                        </div>),
                    menu: username === dsPpapOrderElement.actual?.responsible ?
                        <>
                            <ReactToPrint
                                content={() => this.printRef}
                                trigger={() =>
                                    <IconButton>
                                        <FontAwesomeIcon icon={["fad", "print"]}/>
                                    </IconButton>}
                                pageStyle={pageStyle}
                            />
                            <TableEditMenu ds={dsPpapDetail04} hidden={false}/>
                        </> :
                        null
                }

            case  5:
                return {
                    title: "PPAP_TEMPLATE",
                    icon: "file-edit",
                    content: (
                        <div style={{margin: '1rem'}} ref={(ref) => {
                            this.printRef = ref
                        }}>
                            <PpapReportBasepage element={dsPpapOrderElement.actual}>
                                <PpapReportCustomerEngineeringApproval/>
                            </PpapReportBasepage>
                        </div>),
                    menu: username === dsPpapOrderElement.actual?.responsible ?
                        <>
                            <ReactToPrint
                                content={() => this.printRef}
                                trigger={() => <IconButton><FontAwesomeIcon icon={["fad", "print"]}/></IconButton>}
                                pageStyle={pageStyle}
                            />

                            <TableEditMenu ds={dsPpapDetail05} hidden={false}/>
                        </> :
                        null
                }

            case 18 :
                return {
                    title: "PPAP_TEMPLATE",
                    icon: "file-edit",
                    content: (
                        <div style={{margin: '1rem'}} ref={(ref) => {
                            this.printRef = ref
                        }}>
                            <PpapReportPartSubmissionWarrant element={dsPpapOrderElement.actual}/>
                        </div>),
                    menu: username === dsPpapOrderElement.actual?.responsible ?
                        <>
                            <ReactToPrint
                                content={() => this.printRef}
                                trigger={() => <IconButton><FontAwesomeIcon icon={["fad", "print"]}/></IconButton>}
                                pageStyle={pageStyle}
                            />
                            <TableEditMenu ds={dsPpapDetail18} hidden={false}/>
                        </> :
                        null
                }
            default:
                return null;
        }
    }


    getTabs = (): ITab[] => {
        const {rootStore: {ppapStore: {ds}, authStore: {username}}} = this.props;
        let tabs: ITab[] = [];
        tabs.push(this.getUploadTab());
        if (username === ds.actual?.owner) {
            tabs.push(this.getElementTab());
        }
        tabs.push(this.getBlogTab());
        const formTab = this.getFormTab();
        if (formTab) {
            tabs.push(formTab);
        }
        return tabs;
    }


    render() {
        return (
            <CardWithTab
                tabs={this.getTabs()}
            />
        )
    }
}