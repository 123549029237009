import * as React from 'react';
import {IBaseView} from "../../../../_base/base-view";
import {inject, observer} from "mobx-react";
import {BarGraph} from "../../../../../components/graphic/bar-graph";
import {AuditScore} from "../../../../../models/AuditQuestion";


interface IAuditStatisticArea extends IBaseView {
    orientation: 'vertical' | 'horizontal';
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
    labelRotate?: number;
}


@inject('rootStore')
@observer
export class AuditStatisticArea extends React.Component<IAuditStatisticArea, {}> {

    render() {
        const {
            rootStore: {auditStore: {getCatValue, ds, dsAuditQuestion}},
            orientation,
            top,
            left,
            right,
            bottom,
            labelRotate
        } = this.props;
        const data = getCatValue(
            dsAuditQuestion.data,
            'area',
            'score',
            (x: any) => x.score !== AuditScore.UNRATED && x.score !== AuditScore.NOT_APPLICABLE
        );
        return (
            <BarGraph
                orientation={orientation}
                title=""
                data={data}
                top={top}
                left={left}
                bottom={bottom}
                right={right}
                labelRotate={labelRotate}
                ratingmethod={ds.actual?.ratingmethod}
            />
        )
    }

}