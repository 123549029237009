import * as React from 'react';
import {RouterView} from "mobx-state-router";
import {viewMap} from "../../../routes/viewmap";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {IBaseView} from "../../_base/base-view";

@inject('rootStore')
@observer
class _Main extends React.Component<IBaseView, any> {
    render() {
        const {rootStore: {routerStore}, className} = this.props;
        return (
            <div className={className}>
                <RouterView routerStore={routerStore} viewMap={viewMap}/>
            </div>
        )
    }
}

export const Main = styled(_Main)`
  background-color: ${props => props.theme.main.bg};
  display: flex;
  flex: 1 1 auto;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0; // important for unfolding sidebar
  overflow-x: hidden;
  overflow-y: auto;

  .ag-theme-balham {
    font-size: 0.75rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


    .ag-root {
      border: 0;
    }

    .ag-header {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: ${props => props.theme.color};
      background-color: white;
    }

    .ag-header-cell-text {
      color: ${props => props.theme.color};
    }

    .ag-ltr {
      .ag-cell {
        color: ${props => props.theme.color};
      }
    }
  }
`;