import * as React from 'react';
import {IFieldProps} from "../i-db-field-props";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "../../dialog/Dialog";
import {DbGrid} from "../../dbgrid/db-grid";
import {action, observable, runInAction} from "mobx";
import {Dataset} from "../../dataset/dataset";
import {inject, observer} from "mobx-react";
import {MenuInsert} from "../../../views/_base/menu/menu-insert";

interface IFieldLookupdlgButton<T> extends IFieldProps<T> {
    onOK?: () => void;
}


@inject('rootStore')
@observer
export class FieldLookupdlgButton extends React.Component<IFieldLookupdlgButton<any>, {}> {

    @observable
    isDialogOpen: boolean;

    @observable
    lookupDs: Dataset<any>;


    /**
     * The ds.filter is used as defaultvalue for the lookupDs
     *
     * @param ds
     */
    @action.bound
    onAfterInsert(ds:any){
        Object.keys(this.lookupDs.filter)
            .forEach((field:string)=>{
                ds.actual[field]=this.lookupDs.filter[field]
            })
    }

    constructor(props: IFieldLookupdlgButton<any>) {
        super(props);
        // warum werden url und columns noch nicht gesetzt?
        this.lookupDs = new Dataset<any>('', []);
        this.lookupDs.onAfterInsert = this.onAfterInsert;
    }

    componentWillUnmount() {
        this.lookupDs.close();
    }

    @action.bound
    onOkClick() {
        let {actual, column, onOK} = this.props;
        actual[column.fieldName] = this.lookupDs.actual[column.selectdlg.field ? column.selectdlg.field : column.fieldName];
        if (onOK) {
            onOK();
        }
        //await ds.post();
        //ds.edit();
        this.isDialogOpen = false;
    };

    @action.bound
    onCancelClick() {
        this.isDialogOpen = false;
    };

    @action.bound
    async onOpenClick() {
        let {actual, column} = this.props;
        this.lookupDs.dataUrl = column.selectdlg.url;
        this.lookupDs.columns = column.selectdlg.columns;
        // @ts-ignore
        this.lookupDs.filter = typeof column.selectdlg.filter === 'function' ? column.selectdlg.filter(column.ds, column.fieldName) :
            column.selectdlg.filter ? column.selectdlg.filter : {};

        await this.lookupDs.open();
        runInAction(() => {
            //console.log(column.selectdlg.field ? column.selectdlg.field : column.fieldName);
            //console.log(ds.actual[column.fieldName])
            this.lookupDs.locate([column.selectdlg.field ? column.selectdlg.field : column.fieldName], {[column.selectdlg.field ? column.selectdlg.field : column.fieldName]: actual[column.fieldName]});
            this.isDialogOpen = true;
        });
    };

    render() {
        let {readOnly} = this.props;
        return (
            <>
                <Button
                    onClick={this.onOpenClick}
                    disabled={readOnly}
                    size="sm">
                    <FontAwesomeIcon icon="caret-down"/>
                </Button>
                <Dialog
                    title='Dataselect'
                    isOpen={this.isDialogOpen}
                    onCancel={this.onCancelClick}
                    onOk={this.onOkClick}
                    extraButtons={(<MenuInsert ds={this.lookupDs} id="selectinsert"/>)}
                >
                    <div style={{height: "300px"}}>
                        <DbGrid ds={this.lookupDs} onRowDoubleClicked={this.onOkClick}/>
                    </div>
                </Dialog>
            </>
        )
    }
}