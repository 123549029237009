import {IField} from "../components/dataset/IField";
import {customerColumns} from "./Customer";
import {UsergroupRight} from "./rights/enum-usergrouprights";

export interface IProductCustomer {
    productno: string;
    customerno: string;
}


export const productCustomerColumns: IField<IProductCustomer>[] = [
    {
        fieldName: 'productno',
        dataType: 'string',
        label: 'PRODUCT_PRODUCTNO',
        primaryKey: true,
        insert:'hide',
        rules:['pk'],
        hide:'table'
    },
    {
        fieldName: 'customerno',
        dataType: 'string',
        label: 'CUSTOMER_CUSTOMERNO',
        rules:['pk'],
        primaryKey: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/customer/',
            columns: customerColumns,
            InserRight: UsergroupRight.UR_BASE_CUSTOMER_INSERT
        }
    },
];
