import * as React from 'react';
import {ITableForm, TableForm} from "../../../_base/styled/table-form";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../../components/dbform/db-field";
import {UsergroupRight} from "../../../../models/rights/enum-usergrouprights";
import {action} from "mobx";
import {inject, observer} from "mobx-react";
import {dsState} from "../../../../components/dataset/IDataset";


@inject('rootStore')
@observer
export class ProjectDetailForm extends React.Component<ITableForm, {}> {

    @action.bound
    gotoCustomer() {
        const {rootStore: {routerStore, projectStore: {ds,}}} = this.props;
        routerStore.goTo('customer', {
            customerno: ds.actual.customerno,
        })
    }

    @action.bound
    gotoSupplier() {
        const {rootStore: {routerStore, projectStore: {ds}}} = this.props;
        routerStore.goTo('supplier', {
            supplierno: ds.actual.supplierno,
        })
    }


    render() {
        const {
            rootStore: {
                projectStore: {ds, dsSupplier, dsCustomer, saveOwner, statusActivityOptionsFunc},
                authStore: {username, hasUserRight},
            },
            keyTitle,
            extraMenu,
            open

        } = this.props;
        return (
            <TableForm
                icon="project-diagram"
                title='PROJECT'
                ds={ds}
                keyTitle={keyTitle}
                open={open}
                hideMenu={!(username === ds.actual?.owner || (ds.state === dsState.dsEdit && username === saveOwner))}
                extraMenu={extraMenu}
                ownerField={"owner"}
            >
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="projectno"/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field="project"/>
                            </Col>
                        </Row>
                        <DbField ds={ds} field="comment"/>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="owner"/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field="status" optionsFunc={statusActivityOptionsFunc}/>
                            </Col>
                        </Row>
                        <DbField ds={ds} field="projectgroup"/>
                        <DbField ds={ds} field="subgroup01"/>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <DbField
                                    ds={ds}
                                    field="customerno"
                                    onClickLabel={ds.actual?.customerno && hasUserRight(UsergroupRight.UR_BASE_CUSTOMER) ? this.gotoCustomer : null}
                                    readOnly={true}
                                />
                                <DbField ds={dsCustomer} field="customer1"/>
                               {/* <DbField
                                    ds={ds}
                                    field="customerresponsible"
                                    onClickLabel={ds.actual?.customerno && hasUserRight(UsergroupRight.UR_BASE_CUSTOMER) ? this.gotoCustomer : null}
                                    readOnly={ds.actual?.customerno === '' || !!ds.actual?.customerno}
                                />*/}
                            </Col>
                            <Col>
                                <DbField ds={ds}
                                         field="supplierno"
                                         onClickLabel={ds.actual?.supplierno && hasUserRight(UsergroupRight.UR_BASE_SUPPLIER) ? this.gotoSupplier : null}
                                         readOnly={true}
                                />
                                <DbField ds={dsSupplier} field="supplier1"/>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="startdate"/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field="duedate"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field="createdby"/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field="createdat"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableForm>
        )
    }
}