import * as React from "react";
import {IDbFieldProps} from "./i-db-field-props";
import {dsState, IDataset} from "../dataset/IDataset";
import {inject, observer} from "mobx-react";
import {IField} from "../dataset/IField";
import {Field} from "./field/field";


@inject('rootStore')
@observer
export class DbField<T> extends React.Component<IDbFieldProps<T>, {}> {
    calcReadOnly = (column: IField<any>, ds: IDataset<any>, prop: boolean): boolean => {
        return (
            (ds.state !== dsState.dsInsert && column.readOnly) ||
            (ds.state === dsState.dsInactive) ||
            (ds.state === dsState.dsBrowse) ||
            (ds.state === dsState.dsPending) ||
            (ds.state === dsState.dsEdit && column.primaryKey) ||
            prop
        );
    };

    render() {
        const {rootStore: {langStore: {lang}}, ds, field, optionsFunc} = this.props;
        // field hat vorrand vor column
        const column = field ? ds.columns.find((column) => column.fieldName === field) : this.props.column;
        if (!column) {
            console.log('WARNING: column for field : ' + field + ' not found')
        }
        const readOnly = this.calcReadOnly(column, ds, this.props.readOnly);
        const error = ds.validator.errors.find(error => error.field === column.fieldName)

        return (
            <Field
                {...this.props}
                actual={ds.actual}
                column={column}
                readOnly={readOnly}
                optionsFunc={optionsFunc}
                error={lang[error?.text]}
            />
        )
    }
}