import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {AuditCollectCollectView} from "./audit-collect-collect-view";
import {IAuditQuestion} from "../../../../models/AuditQuestion";
import {acceptImageMime} from "../../../../lib/image-mime";


@inject('rootStore')
@observer
export class AuditCollectCollectOfflineView extends React.Component<IBaseView, {}> {


    onClickQuestion = (question: IAuditQuestion) => async () => {
        const {rootStore: {auditOfflineStore: {setActQuestionno}}} = this.props;
        setActQuestionno(question.questionno);
        await Promise.resolve();
    }


    render() {
        const {
            rootStore: {
                auditOfflineStore: {
                    auditOffline: {audit, auditQuestions},
                    actArea,
                    actElement,
                    actQuestionno,
                    nextElement,
                    prevElement,
                    images,
                    uploadFile,
                    deleteFile,
                    canDeleteFile
                }
            }
        } = this.props;
        return (
            <AuditCollectCollectView
                audit={audit}
                questions={auditQuestions}
                actArea={actArea}
                actElement={actElement}
                nextElement={nextElement}
                prevElement={prevElement}
                post={async () => {
                    await Promise.resolve();
                }}
                actQuestionno={actQuestionno}
                onClickQuestion={this.onClickQuestion}
                images={images}
                uploadFile={uploadFile}
                accept={acceptImageMime}
                deleteFile={deleteFile}
                canDeleteFile={canDeleteFile}
            />
        )
    }
}