import * as React from 'react';
import {IBaseView} from "../../../../_base/base-view";
import {IconButton} from "../../../../_base/styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";
import {Dialog} from "../../../../../components/dialog/Dialog";
import {action, observable, runInAction} from "mobx";
import {IArea} from "../../../../../stores/audit-online-store";
import {auditQuestionColumns, IAuditQuestion} from "../../../../../models/AuditQuestion";
import {IDataset} from "../../../../../components/dataset/IDataset";
import {Dataset} from "../../../../../components/dataset/dataset";
import * as R from "ramda";


interface IAuditSelectArea extends IBaseView {
    area: IArea;
}

const AreaCheckBox = inject('rootStore')(observer((props: IAuditSelectArea) => {
    const {rootStore: {auditStore: {ds, toggleArea}}, area} = props;
    return (
        <div>
            <input
                type="checkbox"
                id={area.label}
                name={area.label}
                checked={area.enabled}
                onChange={action(
                    () => {
                        area.enabled = !area.enabled;
                        toggleArea(ds.actual.auditno, area.label, area.enabled)
                    }
                )}
            />
            <label htmlFor={area.label}>&nbsp;&nbsp;{area.label}</label>
        </div>
    )
}))


@inject('rootStore')
@observer
export class AuditSelectArea extends React.Component<IBaseView, {}> {

    @observable
    isopen: boolean = false;


    @observable
    dsAuditQuestion: IDataset<IAuditQuestion>;

    @observable
    areas: IArea[] = [];

    constructor(props: IBaseView) {
        super(props);

        this.dsAuditQuestion = new Dataset<IAuditQuestion>('/gridApi/auditquestion/', auditQuestionColumns);
    }

    @action.bound
    async onOpened() {
        const {rootStore: {auditStore: {ds}}} = this.props;
        this.dsAuditQuestion.filter = {auditno: ds.actual.auditno}
        await this.dsAuditQuestion.open();
        runInAction(() => {
            this.areas = R.uniq(this.dsAuditQuestion.data
                .map(question => {
                    return {label: question.area, completed: true, enabled: !question.unselected}
                })
            );
        })


    }

    @action.bound
    onOpen() {
        this.isopen = true;
    }

    @action.bound
    onCancel() {
        const {rootStore: {auditStore: {dsAuditQuestion}}} = this.props;
        this.isopen = false;
        dsAuditQuestion.refresh(dsAuditQuestion.cursor);
    }


    @action.bound
    onOk() {
        const {rootStore: {auditStore: {dsAuditQuestion}}} = this.props;
        this.isopen = false;
        dsAuditQuestion.refresh(dsAuditQuestion.cursor);

    }


    render() {
        const {rootStore: {langStore: {lang}}} = this.props;
        return (
            <>
                <IconButton onClick={this.onOpen}>
                    <FontAwesomeIcon icon={["fad", "layer-group"]}/>
                </IconButton>
                <Dialog
                    title={lang.AUDIT_AREAS}
                    isOpen={this.isopen}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    onOpened={this.onOpened}
                    hideCancel={true}
                    hideOk={true}
                >
                    <div style={{height: '400px', overflowY: 'auto'}}>
                        {this.areas.map(
                            (area, i) =>
                                <AreaCheckBox area={area} key={i}/>
                        )
                        }
                    </div>
                </Dialog>
            </>
        )
    }
}