import *as React from 'react';
import {inject, observer} from "mobx-react";
import {ILangKeys} from "../../../language/ILang";
import {Col, FormGroup, Input, InputGroup} from "reactstrap";
import {FieldLabel} from "../field-label";
import {IFieldProps} from "../i-db-field-props";
import {FieldUploaddlgButton} from "./field-uploaddlg-button";
import {action} from "mobx";


@inject('rootStore')
@observer
export class FieldUploaddlg extends React.Component<IFieldProps<any>, {}> {

    openClick = () => {
        console.log('test')
    };

    @action.bound
    onChange(e: any) {
        let {column, actual} = this.props;
        if (e.target.value === '' && column.dataType === 'number') {
            actual[e.target.name] = null;
        } else {
            actual[e.target.name] = e.target.value;
        }
    }

    renderInput() {
        const {column, readOnly, actual} = this.props;
        const value =
            actual ?
                actual[column.fieldName] !== null ?
                    actual[column.fieldName] :
                    '' :
                '';
        return (
            <InputGroup>
                <Input id={column.fieldName as string}
                       name={column.fieldName as string}
                       type="text"
                       disabled={true}
                       autoComplete="off"
                       value={value}
                       onChange={this.onChange}
                       bsSize="sm"

                />
                {!readOnly ?
                    /*<InputGroupAddon addonType="append">*/
                    <FieldUploaddlgButton {...this.props}/>


                    /*</InputGroupAddon>*/ :
                    null}
                {/*{error ? <FormFeedback>{lang[error.text]}</FormFeedback> : null}*/}
            </InputGroup>
        )
    }


    render() {
        const {rootStore: {langStore: {lang}}, column, row} = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : column.fieldName as string;
        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey}/>
                {row ? (<Col sm={9}> {this.renderInput()}</Col>) : this.renderInput()}
            </FormGroup>
        )
    }
}