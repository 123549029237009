import {IField} from "../components/dataset/IField";


export interface IPasswordForgot {
    newpassword1: string;
    newpassword2: string;
}

export const _passwordForgotColumns: IField<IPasswordForgot>[] = [
    {
        fieldName: "newpassword1",
        dataType: 'string',
        input: 'password',
        label: 'USER_NEW_PASSWORD',
        rules: ['mandatory']
    },
    {
        fieldName: "newpassword2",
        dataType: 'string',
        input: 'password',
        label: 'USER_CONFIRMATION_PASSWORD',
        rules: ['mandatory', ['equalto', 'newpassword1']]
    }
];