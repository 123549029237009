import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";

import {AuditCollectCollectView} from "./audit-collect-collect-view";
import {IAuditQuestion} from "../../../../models/AuditQuestion";


@inject('rootStore')
@observer
export class AuditCollectCollectOnlineView extends React.Component<IBaseView, {}> {


    onClickQuestion = (question: IAuditQuestion) => async () => {
        const {rootStore: {auditStore: {dsAuditQuestion}},} = this.props;
        //console.log(question.questionno)
        if (dsAuditQuestion.actual.questionno !== question.questionno) {
            await dsAuditQuestion.post();
            dsAuditQuestion.locate(['questionno'], {questionno: question.questionno});
            dsAuditQuestion.edit();
        }
    }


    render() {
        const {
            rootStore: {
                auditStore: {
                    ds,
                    dsAuditQuestion,
                    actArea,
                    actElement,
                    nextElement,
                    prevElement,
                    images,
                    uploadFile,
                    deleteFile,
                    canDeleteFile
                }
            }
        } = this.props;
        return (
            <AuditCollectCollectView
                audit={ds.actual}
                questions={dsAuditQuestion.data}
                actArea={actArea}
                actElement={actElement}
                nextElement={nextElement}
                prevElement={prevElement}
                post={async () => {
                    await dsAuditQuestion.post();
                }}
                actQuestionno={dsAuditQuestion.actual?.questionno}
                onClickQuestion={this.onClickQuestion}
                images={images}
                uploadFile={uploadFile}
                deleteFile={deleteFile}
                canDeleteFile={canDeleteFile}
            />
        )
    }
}