import * as React from 'react';
import {inject, observer} from "mobx-react";
import {SidebarMenu} from "./SidebarMenu";
import {IBaseView} from "../../../_base/base-view";
import {SidebarFooter} from "./sidebar-footer";


@inject('rootStore')
@observer
export class Sidebar extends React.Component<IBaseView, any> {

    render() {
        return (
            <div className="sidebar">
                <SidebarMenu/>
                <SidebarFooter/>
            </div>
        )
    }
}



