import * as React from 'react';
import {Label} from "reactstrap";
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


interface IFieldLabel {
    label: string;
    bold: boolean;
    row: boolean;
    onClick?: () => void;
}

@observer
export class FieldLabel extends React.Component<IFieldLabel, {}> {

    render() {
        const {label, bold, row, onClick} = this.props;

        let rowstyle: any = onClick ?
            {textAlign: 'right', cursor: 'pointer'} :
            {textAlign: 'right'};

        let style: any = onClick ?
            {cursor: 'pointer'} :
            {};

        let external: JSX.Element = onClick ?
            <FontAwesomeIcon icon={["fad", "external-link"]}/> :
            null

        return row ?
            (<Label sm={3} style={rowstyle} onClick={onClick}>
                {bold ? (<b>{label} {external}</b>) : label} {external}
            </Label>)
            :
            (<Label style={style} onClick={onClick}>
                {bold ? (<b>{label} {external}</b>) : label} {external}
            </Label>)
    }
}