import {BaseStore, IBaseStore} from "./base-store";
import {IInspectionPlan, inspectionPlanColumns} from "../models/InspectionPlan";
import {IRootStore} from "../routes/root-store";
import {IDataset} from "../components/dataset/IDataset";
import {IInspectionPlanQuestion, inspectionPlanQuestionColumns} from "../models/InspectionPlanQuestion";
import {action, observable} from "mobx";
import {Dataset} from "../components/dataset/dataset";
import {RouterState} from "mobx-state-router";
import {decodeParams} from "../components/lib/encode-decode-params";
import * as R from "ramda";


export interface IInspectionPlanStore extends IBaseStore<IInspectionPlan> {
    dsQuestion: IDataset<IInspectionPlanQuestion>;
}

export class InspectionPlanStore extends BaseStore<IInspectionPlan> {

    @observable
    dsQuestion: IDataset<IInspectionPlanQuestion>;

    constructor(rootStore: IRootStore) {
        super(rootStore, '/gridApi/inspectionplan/', inspectionPlanColumns);

        this.dsQuestion = new Dataset<IInspectionPlanQuestion>('/gridApi/inspectionplanquestion/', inspectionPlanQuestionColumns);
        this.dsQuestion.setMasterSource(this.ds, [{field: 'planno', masterField: 'planno'}])
    }

    @action.bound
    async openDetails() {
        await this.dsQuestion.open();
    }

    @action.bound
    closeDetails() {
        this.dsQuestion.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {

        if (toState.routeName === 'inspectionplantable') {
            await this.open();
            if (fromState.routeName === 'inspectionplan') {
                this.ds.locate(this.ds.pkFields, fromState.params)
            }
        } else if (toState.routeName === 'inspectionplan') {

            await this.open(decodeParams(toState.params));

            this.ds.edit();
            await this.openDetails();

            if (fromState.routeName === 'inspectionplanquestion') {
                this.dsQuestion.locate(this.dsQuestion.pkFields, fromState.params)
            }
        } else if (toState.routeName === 'inspectionplanquestion') {
            await this.open(R.pick(['planno'], toState.params) as any);
            await this.openDetails();
            //this.ds.locate(this.ds.pkFields, toState.params);
            this.dsQuestion.locate(this.dsQuestion.pkFields, toState.params);
            /*this.dsQuestion.edit();*/
        }
    }


    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'inspectionplanquestion') {
            /*await this.dsQuestion.post();*/
            this.closeDetails();
            this.close();
            await Promise.resolve();
        } else if (fromState.routeName === 'inspectionplan') {
            this.closeDetails();
            await this.ds.post();
            this.close();
        } else if (fromState.routeName === 'inspectionplantable') {
            this.close();
        }
    }

}