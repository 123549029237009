import {IField} from "../components/dataset/IField";
import {enumToArray} from "../components/lib/enumToArray";
import {ILangKeys} from "../language/ILang";


export enum RatingMethod {
    VDA61 = 100,
    BINARY = 200,
    VDA63 = 300,
    VESTAS_SBA = 400
}


export const ratingMethodText = (ratingMethod: RatingMethod): ILangKeys => {
    switch (ratingMethod) {
        case RatingMethod.VDA61:
            return 'AUDIT_TEMPLATE_VDA61';
        case RatingMethod.BINARY:
            return 'AUDIT_TEMPLATE_BINARY';
        case RatingMethod.VDA63:
            return 'AUDIT_TEMPLATE_VDA63';
        case RatingMethod.VESTAS_SBA:
            return 'AUDIT_TEMPLATE_VESTAS_SBA';
        default:
            return 'Unknown';
    }
};

export interface IAuditTemplate {
    audittemplate: string;
    ratingmethod: RatingMethod;
}

export const auditTemplateColumns: IField<IAuditTemplate>[] = [
    {
        fieldName: 'audittemplate',
        dataType: 'string',
        label: 'AUDIT_TEMPLATE_ID',
        primaryKey: true
    },
    {
        fieldName: 'ratingmethod',
        dataType: 'number',
        label: 'AUDIT_TEMPLATE_RATINGMETHOD',
        insert: 'show',
        defaultValue: RatingMethod.VDA61,
        rules: ['number', 'mandatory'],
        input: 'select',
        textFunc: ratingMethodText,
        options: enumToArray(RatingMethod),
        readOnly: true
    }
];