import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {BaseTableFilterMenu} from "../../../_base/table-filter/base-table-filter-menu";
import {TableGrid} from "../../../_base/styled/table-grid";
import {TableLayout} from "../../../_base/styled/layout/table-layout";
import {UsergroupRight} from "../../../../models/rights/enum-usergrouprights";


@inject('rootStore')
@observer
export class AuditTableView extends React.Component<IBaseView, {}> {


    render() {
        const {rootStore: {auditStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="audit1"
                    icon="eye"
                    title='AUDITS'
                    ds={ds}
                    singleRouteName='audit'
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                            <BaseTableFilterMenu storeName="auditStore"
                                                 filtername="audit"/> :
                            null
                    }
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        )
    }
}