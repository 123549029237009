import {observer} from "mobx-react";
import {StyledHeadline} from "./styled-headline";
import {StyledTable} from "./styled-table";
import * as React from "react";
import {IPpapOrderDocument} from "../../../../../models/PpapDocument";
import {DocumentEditor} from "./document-editor";

interface IResponsible {
    document: IPpapOrderDocument;
    readonly: boolean;
}


export const Responsible = observer(({document, readonly}: IResponsible) => {
    return (
        <>
            <StyledHeadline>Document Responsible</StyledHeadline>
            <StyledTable width={'80%'}>
                <thead>
                <tr>
                    <td style={{width: '33%'}}>Name</td>
                    <td style={{width: '33%'}}>Function</td>
                    <td style={{width: '34%'}}>Company</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <DocumentEditor
                            document={document}
                            fieldName="responsible"
                            readonly={readonly}
                            mandatory
                        />
                    </td>
                    <td>
                        <DocumentEditor
                            document={document}
                            fieldName="responsible_function"
                            readonly={readonly}
                            mandatory
                        />
                    </td>
                    <td>
                        <DocumentEditor
                            document={document}
                            fieldName="responsible_company"
                            readonly={readonly}
                            mandatory
                        />
                    </td>
                </tr>
                </tbody>
            </StyledTable>
        </>
    )
});