import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import ReactEcharts from "echarts-for-react";
import {cl_green, cl_red, cl_yellow} from "../../_theme/colors";

@inject('rootStore')
@observer
export class InspectionStatisticScore extends React.Component<IBaseView, {}> {

    getColor = (score: number) => {
        if (score >= 80) {
            return cl_green
        } else if (score >= 60) {
            return cl_yellow
        } else {
            return cl_red
        }
    };


    getOption = () => {
        const {rootStore: {inspectionStore: {inspectionScore}}} = this.props;

        return {

            color: [this.getColor(inspectionScore), '#aaaaaa', '#427cac', '#2b7d2b', cl_red],
            grid: {
                top: '10%',
                left: '3%',
                right: '3%',
                bottom: '10%',
            },

            series: [
                {
                    name: 'Data',
                    type: 'pie',
                    hoverAnimation: false,
                    legendHoverLink: false,
                    radius: ['70%', '100%'],
                    markArea: {
                        animation: false
                    },
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                fontSize: '24',
                                fontWeight: 'bold',
                                color: 'black'
                            },
                            position: 'center'
                        },
                    },

                    data: [
                        {value: inspectionScore, name: inspectionScore ? inspectionScore.toFixed(2) + '%' : ''},
                        {value: 100 - inspectionScore, name: ''},
                    ]
                }
            ]
        };
    };


    render() {
        return (
            <div style={{flex: ' 0 0 50%'}}>
                <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={this.getOption()}
                    style={{height: '100%', width: '100%'}}
                />
            </div>
        )
    }
}