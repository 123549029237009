import React from "react";
import {IDashboardProjectCard} from "../dashboard-project-card";
import {Col, Row} from "reactstrap";
import {AuditStatisticScore} from "../../../audit/order/order/top/audit-statistic-score";
import {DashboardTaskStatus} from "../task/dashboard-task-status";
import {action} from "mobx";
import {inject, observer} from "mobx-react";




@inject('rootStore')
@observer
export class DashboardAudit extends React.Component<IDashboardProjectCard, {}> {

    @action.bound
    gotoAudit(auditno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('audit', {auditno: auditno});
        }
    }

    @action.bound
    gotoTasks(projectno: string, activityno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('projectactivityactiontable', {projectno: projectno, activityno: activityno});
        }
    }

    render() {
        const {projectStatus} = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>Audit</div>
                    </Col>
                </Row>
                <Row>
                    {projectStatus.audits
                        .map((audit, i) => {
                            //console.log(toJS(audit))
                                return (
                                    <React.Fragment key={i}>
                                        {/*Audit*/}
                                        <Col md={4} style={{paddingTop: '1rem'}}>
                                            <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                <AuditStatisticScore
                                                    auditScore={audit.audit.score}
                                                    ratingMethod={audit.audit.ratingMethod}
                                                />
                                            </div>
                                            <div className="link"
                                                 onClick={this.gotoAudit(audit.audit.auditno)}>
                                                {audit.audit.activity.description1}
                                            </div>
                                        </Col>
                                        {/*Development*/}
                                        <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                                            {audit.development ?
                                                <>
                                                    <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                        <AuditStatisticScore
                                                            auditScore={audit.development.score}
                                                            ratingMethod={audit.development.ratingMethod}
                                                        />
                                                    </div>
                                                    <div className="link"
                                                         onClick={this.gotoAudit(audit.development.auditno)}>
                                                        {audit.development.activity.description1}
                                                    </div>
                                                </> :
                                                null}

                                        </Col>
                                        {/*Tasks*/}
                                        {audit.development ?
                                            <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                                                <>
                                                    <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                        <DashboardTaskStatus
                                                            projectno={projectStatus.project.projectno}
                                                            activityno={audit.development.activity.activityno}
                                                            catValues={audit.development.actionByStatus}/>
                                                    </div>
                                                    <div className="link"
                                                         onClick={this.gotoTasks(projectStatus.project.projectno, audit.development.activity.activityno)}>{audit.development.actions.length}&nbsp;Tasks
                                                    </div>
                                                </>
                                            </Col> :
                                            <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                                                <>
                                                    <div style={{display: 'block', height: '100px', width: '100%'}}>
                                                        <DashboardTaskStatus
                                                            projectno={projectStatus.project.projectno}
                                                            activityno={audit.audit.activity.activityno}
                                                            catValues={audit.audit.actionByStatus}/>
                                                    </div>
                                                    <div className="link"
                                                         onClick={this.gotoTasks(projectStatus.project.projectno, audit.audit.activity.activityno)}>{audit.audit.actions.length}&nbsp;Tasks
                                                    </div>
                                                </>
                                            </Col>
                                        }
                                    </React.Fragment>
                                )
                            }
                        )}
                </Row>
            </>
        )
    }
}