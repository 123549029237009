import {IBaseView} from "../../../../_base/base-view";
import React from "react";
import {Col, Row} from "reactstrap";
import {cl_blue} from "../../../../_theme/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export class GembaReportPage2 extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {gembaStore: {ds, dsGembaFinding}}} = this.props;
        return (
            <div style={{paddingTop: '10mm'}}>
                <Row style={{paddingBottom: '3rem'}}>
                    <Col>
                        <h1 style={{fontSize: 48, color: cl_blue}}>Overview</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table>
                            <tbody>
                            <tr>
                                <td style={{verticalAlign: 'top'}}>
                                    <h2 style={{color: cl_blue, width: '15mm'}}>
                                        <FontAwesomeIcon icon={["fad", "bolt"]}/>
                                    </h2>
                                </td>
                                <td>
                                    <h4>Findings</h4>
                                    <h4>
                                        {dsGembaFinding.data
                                            .length
                                        }
                                    </h4>
                                    <h4>&nbsp;</h4>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row style={{paddingBottom: '3rem'}}>
                    <Col>
                        <table>
                            <tbody>
                            <tr>
                                <td style={{verticalAlign: 'top'}}>
                                    <h2 style={{color: cl_blue, width: '15mm'}}>
                                        <FontAwesomeIcon icon={["fad", "quote-right"]}/>
                                    </h2>
                                </td>
                                <td>
                                    <h4>Summary</h4>
                                    <h4>{ds.actual?.comment}</h4>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        )
    }
}