import * as React from 'react';
import {Col, FormGroup, InputGroup} from "reactstrap";
import {IFieldProps} from "../i-db-field-props";
import {inject, observer} from "mobx-react";

import {ILangKeys} from "../../../language/ILang";
import ReactDatePicker from "react-datepicker";

import {action,} from "mobx";
import moment from "moment";
import {FieldLabel} from "../field-label";
import styled from "styled-components";


const _ErrorFeedBack = ({message, className}: any) => <div className={className}>
    {message}
</div>


const ErrorFeedBack = styled(_ErrorFeedBack)`
    display: ${props => props.message ? "block" : "none"};
    width:100%;
    margin-top:0.25rem;
    font-size:80%;
    color:#e40000;
`;


@inject('rootStore')
@observer
export class FieldDatetime extends React.Component<IFieldProps<any>, any> {

    renderInput = () => {
        let {actual, column, error, readOnly} = this.props;

        const className = error ? "is-invalid form-control-sm form-control" : "form-control-sm form-control"
        return (
            <>
                <InputGroup>
                    <ReactDatePicker className={className}
                                     selected={actual ? (actual[column.fieldName] !== null && actual[column.fieldName] !== '') ? moment(actual[column.fieldName]).toDate() : null : null}
                                     onChange={action((date: Date) => {
                                         actual[column.fieldName] = moment(date).format('YYYY-MM-DD');
                                     })}
                                     dateFormat="yyyy-MM-dd"
                                     disabled={readOnly}
                                     popperPlacement='bottom'
                    />

                </InputGroup>
                <ErrorFeedBack message={error}/>
            </>
        )

    };


    render() {
        let {rootStore: {langStore: {lang}}, column, row} = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : column.fieldName as string;
        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey}/>
                {row ? (<Col sm={9}> {this.renderInput()}</Col>) : this.renderInput()}
            </FormGroup>
        )
    }
}