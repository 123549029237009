import * as React from 'react';
import {IBaseView} from "../_base/base-view";
import {TableForm} from "../_base/styled/table-form";
import {inject, observer} from "mobx-react";
import {Col, Row} from "reactstrap";
import {DbField} from "../../components/dbform/db-field";
import moment from "moment";
import {action, computed} from "mobx";
import {Status} from "../../models/Status";
import styled from "styled-components";
import {ActionTyp} from "../../models/Action";

const MiniLine = styled.div`
  font-size: 1rem;
`;


@inject('rootStore')
@observer
export class TaskDetailForm extends React.Component<IBaseView, {}> {


    @action.bound
    onStatusChanged() {
        const {rootStore: {actionStore: {ds}}} = this.props;
        if (ds.actual.status === Status.COMPLETED && !ds.actual.completeddat) {
            ds.actual.completeddat = moment().format();
        } else if (ds.actual.status === Status.PLANNED || ds.actual.status === Status.INPROGRESS) {
            ds.actual.completeddat = null;
        }
    }

    @action.bound
    async gotoLink() {
        const {rootStore: {routerStore, actionStore: {ds}}} = this.props;
        if (ds.actual?.typ === ActionTyp.PPAPTask) {
            await routerStore.goTo('ppaporder', {ppapno: ds.actual.key1});
        } else if (ds.actual?.typ === ActionTyp.PROJECTTask) {
            await routerStore.goTo('projectactivity', {projectno: ds.actual.key1, activityno: ds.actual.key2});
        } else if (ds.actual?.typ === ActionTyp.AUDITTask) {
            await routerStore.goTo('audit', {auditno: ds.actual.key1},);
        } else if (ds.actual?.typ === ActionTyp.INSPECTIONTask) {
            await routerStore.goTo('inspectionquestion', {inspectionno: ds.actual.key1, questionno: ds.actual.key2},);
        } else if (ds.actual?.typ === ActionTyp.GEMBATask) {
            await routerStore.goTo('gemba', {gembano: ds.actual.key1,}, {findingno: ds.actual.key2});
        }
    }

    @action.bound
    gotoProject() {
        const {rootStore: {routerStore, actionStore: {ds}}} = this.props;
        routerStore.goTo('project', {projectno: ds.actual.projectno});
    }

    @computed
    get keyTitle() {
        const {rootStore: {actionStore: {ds}}} = this.props;
        let rv1 = ds.actual?.no ?? ""
        let rv2 = ds.actual?.keyword ?? ""
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : ""
    }


    render() {
        const {
            rootStore:
                {
                    actionStore: {ds},
                    authStore: {username},
                    langStore: {lang}
                },

        } = this.props;

        return (
            <TableForm
                icon="tasks"
                title="ACTION"
                ds={ds}
                keyTitle={this.keyTitle}
                open={true}
                hideMenu={(username !== ds?.actual?.taskowner && username !== ds?.actual?.responsible) || (username === ds?.actual?.responsible && ds.actual?.status === Status.APPROVED)}
            >

                <Row>
                    <Col md={4}>
                        <DbField ds={ds} field="no"/>
                    </Col>
                    <Col md={8}>
                        <DbField ds={ds} field="keyword" readOnly={username !== ds?.actual?.taskowner}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DbField ds={ds} field="action"
                                 readOnly={username !== ds?.actual?.taskowner}/>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DbField
                            ds={ds}
                            field="conducted"
                            readOnly={username !== ds?.actual?.responsible}
                        />
                    </Col>
                    <Col>
                        <DbField
                            ds={ds}
                            field="comment"
                            readOnly={username !== ds?.actual?.taskowner}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DbField ds={ds} field="responsible"
                                 readOnly={username !== ds?.actual?.taskowner}/>
                        <DbField ds={ds} field="taskowner"
                                 readOnly={true}/>

                    </Col>
                    <Col>
                        <DbField ds={ds} field="duedate"
                                 readOnly={username !== ds?.actual?.taskowner}/>
                        <DbField ds={ds} field="projectno" onClickLabel={this.gotoProject}/>


                    </Col>
                    <Col>
                        <DbField
                            ds={ds}
                            field="status"
                            onChanged={this.onStatusChanged}
                            readOnly={username !== ds?.actual?.taskowner && username !== ds?.actual?.responsible}
                        />
                        {ds?.actual?.typ === ActionTyp.PROJECTTask ?
                            <DbField ds={ds} field="key2" onClickLabel={this.gotoLink}/> :
                            <DbField ds={ds} field="key1" onClickLabel={this.gotoLink}/>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MiniLine>
                            {lang['CREATED_BY']}&nbsp;
                            <b>{ds.actual?.createdby}</b>&nbsp;{lang['CREATED_DAT']}&nbsp;
                            <b>{moment(ds.actual?.createdat).format('DD-MM-YYYY')}</b>
                        </MiniLine>
                    </Col>
                </Row>
            </TableForm>
        )
    }
}