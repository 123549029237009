import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IFieldProps} from "../i-db-field-props";
import {FormGroup, Label} from "reactstrap";
import {action} from "mobx";


const RadioBtn =
    inject('rootStore')(
        observer(
            ({rootStore, column, i, actual, readOnly}: any) => {
                const {langStore: {lang}} = rootStore;
                if (actual)
                    return (
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name={column.fieldName}
                                   id={column.options[i]}
                                   value={column.options[i]}
                                   checked={actual[column.fieldName] === column.options[i]}
                                   onChange={action(() => {
                                       actual[column.fieldName] = column.options[i]
                                   })}
                                   disabled={readOnly && actual[column.fieldName] !== column.options[i]}
                            />
                            <label className="form-check-label" htmlFor={column.options[i]}>
                                {lang[column.textFunc(column.options[i])]}
                            </label>
                        </div>
                    )
                else return (<div/>)
            }
        )
    );


export const FieldRadio =
    inject('rootStore')(
        observer(
            (props: IFieldProps<any>) => {
                let {rootStore: {langStore: {lang}}, column, actual, readOnly} = props;
                return (
                    <FormGroup>
                        <Label>
                            {column.primaryKey ? (<b>{lang[column.label]}</b>) : lang[column.label]}
                        </Label>
                        {column.options
                            .map((option, i) => <RadioBtn key={i} column={column} i={i} actual={actual}
                                                          readOnly={readOnly}/>
                            )
                        }
                    </FormGroup>
                )
            }
        )
    );