import {IField} from "../components/dataset/IField";
import {ILangKeys} from "../language/ILang";
import {enumToArray} from "../components/lib/enumToArray";
import {iconRenderer} from "./_helper/icon-renderer";
import {cl_gray600, cl_green, cl_red, cl_yellow} from "../views/_theme/colors";
import {catalogDetailColumns} from "./CatalogDetail";
import {UsergroupRight} from "./rights/enum-usergrouprights";


export enum AuditScore {
    NOT_APPLICABLE = -2,
    UNRATED = -1,
    NO_COMPLIANCE = 0,
    UNSATISFACTORY_COMPLIANCE = 4,
    PARTIAL_COMPLIANCE = 6,
    PREDOMINANT_COMPLIANCE = 8,
    FULL_COMPLIANCE = 10
}

export const scoreText = (score: AuditScore): ILangKeys => {
    switch (score) {
        case AuditScore.NOT_APPLICABLE:
            return 'N_A';
        case AuditScore.UNRATED:
            return '';
        case AuditScore.NO_COMPLIANCE:
            return 'AUDIT_0';
        case AuditScore.UNSATISFACTORY_COMPLIANCE:
            return 'AUDIT_4';
        case AuditScore.PARTIAL_COMPLIANCE:
            return 'AUDIT_6';
        case AuditScore.PREDOMINANT_COMPLIANCE:
            return 'AUDIT_8';
        case AuditScore.FULL_COMPLIANCE:
            return 'AUDIT_10';
        default:
            return 'Unknown';
    }
};

export const scoreTextBinary = (score: AuditScore): ILangKeys => {
    switch (score) {
        case AuditScore.NOT_APPLICABLE:
            return 'N_A';
        case AuditScore.UNRATED:
            return '';
        case AuditScore.NO_COMPLIANCE:
            return 'NOK';
        case AuditScore.FULL_COMPLIANCE:
            return 'OK';
        default:
            return 'Unknown';
    }
};


export const scoreLongText = (score: AuditScore): ILangKeys => {
    switch (score) {
        case AuditScore.NOT_APPLICABLE:
            return 'N_A';
        case AuditScore.UNRATED:
            return 'AUDIT_UNRATED';
        case AuditScore.NO_COMPLIANCE:
            return 'AUDIT_NO_COMPLIANCE';
        case AuditScore.UNSATISFACTORY_COMPLIANCE:
            return 'AUDIT_UNSATISFACTORY_COMPLIANCE';
        case AuditScore.PARTIAL_COMPLIANCE:
            return 'AUDIT_PARTIAL_COMPLIANCE';
        case AuditScore.PREDOMINANT_COMPLIANCE:
            return 'AUDIT_PREDOMINANT_COMPLIANCE';
        case AuditScore.FULL_COMPLIANCE:
            return 'AUDIT_FULL_COMPLIANCE';
        default:
            return 'Unknown';
    }
};

const cellStyleScore = function (params: any) {

    switch (params.value) {
        case AuditScore.NOT_APPLICABLE:
            return {backgroundColor: cl_gray600, color: 'white', textAlign: 'center'};
        case AuditScore.UNRATED:
            return {backgroundColor: 'white', color: 'black', textAlign: 'center'};
        case AuditScore.NO_COMPLIANCE:
            return {backgroundColor: cl_red, color: 'white', textAlign: 'center'};
        case AuditScore.UNSATISFACTORY_COMPLIANCE:
            return {backgroundColor: cl_red, color: 'white', textAlign: 'center'};
        case AuditScore.PARTIAL_COMPLIANCE:
            return {backgroundColor: cl_yellow, color: 'white', textAlign: 'center'};
        case AuditScore.PREDOMINANT_COMPLIANCE:
            return {backgroundColor: cl_green, color: 'white', textAlign: 'center'};
        case AuditScore.FULL_COMPLIANCE:
            return {backgroundColor: cl_green, color: 'white', textAlign: 'center'};

        default:
            return {backgroundColor: 'white', color: 'black', textAlign: 'center'};
    }
};


export const scoreStyle = (score: AuditScore) => {

    switch (score) {
        case AuditScore.NOT_APPLICABLE:
            return {backgroundColor: cl_gray600, color: 'white'};
        case AuditScore.UNRATED:
            return {backgroundColor: 'white', color: 'black'};
        case AuditScore.NO_COMPLIANCE:
            return {backgroundColor: cl_red, color: 'white'};
        case AuditScore.UNSATISFACTORY_COMPLIANCE:
            return {backgroundColor: cl_red, color: 'white'};
        case AuditScore.PARTIAL_COMPLIANCE:
            return {backgroundColor: cl_yellow, color: 'white'};
        case AuditScore.PREDOMINANT_COMPLIANCE:
            return {backgroundColor: cl_green, color: 'white'};
        case AuditScore.FULL_COMPLIANCE:
            return {backgroundColor: cl_green, color: 'white'};

        default:
            return {backgroundColor: 'white', color: 'black'};
    }

}


export interface IAuditQuestion {
    auditno: string;
    questionno: string;
    question: string;
    mandatory: boolean;
    reference: string;
    requirements: string;
    answer: string;
    evidence: string;
    score: number;
    area: string;
    element: string;
    qm: number;
    praxis: number;
    notapplicable: boolean;
    extra01: string;
    extra02: string;
    level01: string;
    level02: string;
    level03: string;
    level04: string;
    level05: string;
    unselected: boolean;
    attentionflag: boolean;
    chapter: string;
    weighting: number;
}


/*
FairWind Kataloge
 */


export const auditQuestionColumns: IField<IAuditQuestion>[] = [
    {
        fieldName: 'auditno',
        dataType: 'string',
        label: 'AUDIT_AUDITNO',
        rules: ['pk'],
        primaryKey: true,
        insert: 'hide',
        hide: 'table'
    },
    {
        fieldName: 'questionno',
        label: 'AUDIT_QUESTIONNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 30,
        }
    },
    {
        fieldName: 'area',
        label: 'AUDIT_AREA',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'element',
        label: 'AUDIT_ELEMENT',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'question',
        dataType: 'string',
        label: 'AUDIT_QUESTION',
        insert: 'show',
        input: 'textarea',
        maxLength: 1024
    },
    {
        fieldName: 'mandatory',
        label: 'AUDIT_MANDATORY',
        dataType: 'boolean',
        input: 'checkbox',
        hide: 'table',
        grid: {
            width: 40,
            cellRenderer: (params: any) => params.value ? iconRenderer("exclamation", undefined, cl_red) : ''
        }
    },
    {
        fieldName: 'requirements',
        label: 'AUDIT_REQUIREMENTS',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        insert: 'show',
        maxLength: 1024
    },
    {
        fieldName: 'reference',
        label: 'AUDIT_REFERENCE',
        dataType: 'string',
        insert: 'show',
        hide: 'table',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'answer',
        label: 'AUDIT_ANSWER',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        maxLength: 1024
    },
    {
        fieldName: 'evidence',
        label: 'AUDIT_EVIDENCE',
        dataType: 'string',
        //input: 'textarea',
        hide: 'table',
        maxLength: 1024
    },
    {
        fieldName: 'score',
        dataType: 'number',
        label: 'AUDIT_SCORE',
        input: 'selectradio',
        options: enumToArray(AuditScore),
        textFunc: scoreText,
        defaultValue: -1,
        useAsFilter: true,
        grid: {
            width: 40,
            cellStyle: cellStyleScore
        }
    },
    {
        fieldName: 'qm',
        dataType: 'number',
        hide: 'always'
    },
    {
        fieldName: 'praxis',
        dataType: 'number',
        hide: 'always'
    },
    {
        fieldName: 'notapplicable',
        dataType: 'boolean',
        hide: 'always',
    },
    {
        fieldName: 'extra01',
        dataType: 'string',
        hide: 'always',
        input: 'selectdlg',
        label: 'AUDIT_QUESTION_EXTRA01',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10001',
            columns: catalogDetailColumns,
            field: 'description1',
            InserRight: UsergroupRight.UR_BASE_CATALOG_INSERT
        }
    },
    {
        fieldName: 'extra02',
        dataType: 'string',
        hide: 'always',
        input: 'selectdlg',
        label: 'AUDIT_QUESTION_EXTRA02',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10002',
            columns: catalogDetailColumns,
            field: 'description1',
            InserRight: UsergroupRight.UR_BASE_CATALOG_INSERT
        }
    },
    {
        fieldName: 'level01',
        label: 'AUDIT_QUESTION_LEVEL01',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level02',
        label: 'AUDIT_QUESTION_LEVEL02',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level03',
        label: 'AUDIT_QUESTION_LEVEL03',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level04',
        label: 'AUDIT_QUESTION_LEVEL04',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level05',
        label: 'AUDIT_QUESTION_LEVEL05',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'unselected',
        label: 'AUDIT_QUESTION_UNSELECTED',
        dataType: 'boolean',
        hide: 'always',
    },
    {
        fieldName: 'attentionflag',
        label: 'AUDIT_QUESTION_ATTENTIONFLAG',
        dataType: 'boolean',
        hide: 'always',
    },
    {
        fieldName: 'chapter',
        label: 'AUDIT_QUESTION_CHAPTER',
        dataType: 'string',
        hide: 'always',
    },
    {
        fieldName: 'weighting',
        label: 'AUDIT_QUESTION_WEIGHTING',
        dataType: 'number',
        hide: 'always',
    },

];

