import * as React from 'react';
import {inject, observer} from "mobx-react";
import ReactEcharts from "echarts-for-react";
import {cl_blue, cl_gray800, cl_green, cl_red, cl_yellow} from "../../../../_theme/colors";
import {RatingMethod} from "../../../../../models/AuditTemplate";


interface IAuditStatisticScore {
    auditScore: number;
    ratingMethod: RatingMethod;
}

@inject('rootStore')
@observer
export class AuditStatisticScore extends React.Component<IAuditStatisticScore, {}> {


    getColor = (score: number, ratingMethod: RatingMethod) => {
        switch (ratingMethod) {
            case RatingMethod.VESTAS_SBA:
                if (score >= 70) {
                    return cl_green
                } else if (score > 50) {
                    return cl_yellow
                } else {
                    return cl_red
                }

            default:
                if (score >= 80) {
                    return cl_green
                } else if (score >= 60) {
                    return cl_yellow
                } else {
                    return cl_red
                }
        }


    };


    getOption = () => {
        const {auditScore, ratingMethod} = this.props;
        const color = this.getColor(auditScore, ratingMethod);

        return {

            color: [color, '#aaaaaa', cl_blue, cl_green, cl_red],
            grid: {
                top: '10%',
                left: '3%',
                right: '3%',
                bottom: '10%',
            },

            series: [
                {
                    name: 'Data',
                    type: 'pie',
                    //hoverAnimation: false,
                    legendHoverLink: false,
                    radius: ['70%', '100%'],
                    cursor: 'default',
                    markArea: {
                        animation: false
                    },
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 600,
                        color: cl_gray800,
                        position: 'center',
                        formatter: () => auditScore ? auditScore.toFixed(0) + '%' : ''
                    },
                    emphasis: {
                        scale: false,
                        focus: false,
                        scaleSize: 1
                    },

                    data: [
                        {value: auditScore, name: ''},
                        {value: 100 - auditScore, name: ''},
                    ]
                }
            ]
        };
    };


    render() {
        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{height: '100%', width: '100%'}}
            />
        )
    }
}