import * as React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "reactstrap";
import {darken} from 'polished';


export const BackButton = styled(Button)`
 margin-top: -8px;
 margin-bottom: -7px;
 margin-right:0.25rem;
 height:2rem;
 width:2rem;
 padding:0;
 border-radius:1rem;
 background-color: ${props => props.theme.cardHeaderButtonBg};
 color:${props => props.theme.cardHeaderButtonColor};
 border:0;
 
 :hover{ 
            background-color:   ${props => darken(0.1, props.theme.cardHeaderButtonBg)};
      }
      
 :disabled{
            background-color:   ${props => props.theme.cardHeaderButtonBg};
      }
      
 :focus{
            background-color:   ${props => props.theme.cardHeaderButtonBg};
      }
      
 :active{
            background-color:   ${props => props.theme.cardHeaderButtonBg};
      }
`;


const _BaseCardHeaderLeftMenu = ({className, showBackButton, onClick}: any) => {

    return (
        <div className={className}>
            {showBackButton ?
                <>
                    <BackButton className={className} onClick={onClick}>
                        <FontAwesomeIcon icon="arrow-left"/>
                    </BackButton>
                </> :
                null}
        </div>
    )
};


export const BaseCardHeaderLeftMenu = styled(_BaseCardHeaderLeftMenu)`


`;