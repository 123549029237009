import {inject, observer} from "mobx-react";
import {Col, FormFeedback, FormGroup, Input} from "reactstrap";
import {action} from "mobx";
import * as React from "react";
import {IFieldProps} from "../i-db-field-props";
import {ILangKeys} from "../../../language/ILang";
import {FieldLabel} from "../field-label";

@inject('rootStore')
@observer
export class FieldInput extends React.Component<IFieldProps<any>, {}> {


    @action.bound
    onChange(e: any) {
        let {column, actual} = this.props;
        if (e.target.value === '' && column.dataType === 'number') {
            actual[e.target.name] = null;
        } else {
            actual[e.target.name] = e.target.value;
        }
    }

    renderInput = () => {
        const {column, actual, error, readOnly} = this.props;
        const value =
            actual ?
                actual[column.fieldName] !== null ?
                    actual[column.fieldName] :
                    '' :
                '';
        return (
            <>
                <Input id={column.fieldName as string}
                       name={column.fieldName as string}
                       type="text"
                       disabled={readOnly}
                       autoComplete="off"
                       value={value}
                       onChange={this.onChange}
                       bsSize="sm"
                       invalid={!!error}
                       maxLength={column.maxLength?column.maxLength:524288}
                       fontSize={16}
                />
                {error ? <FormFeedback>{error}</FormFeedback> : null}
            </>
        );
    }

    render() {
        const {rootStore: {langStore: {lang}}, label, column, row, onClickLabel} = this.props;
        const fieldLabel: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : column.fieldName as string;
        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label || label === '' ? label : fieldLabel} bold={column.primaryKey}
                            onClick={onClickLabel}/>
                {row ? (<Col sm={9}> {this.renderInput()}</Col>) : this.renderInput()}
            </FormGroup>
        )
    }
}




