import {IField} from "../components/dataset/IField";
import {iconRenderer} from "./_helper/icon-renderer";
import {cl_red} from "../views/_theme/colors";


export interface IInspectionPlanQuestion {
    planno: string;
    questionno: string;
    question: string;
    mandatory: boolean;
    requirements: string;
    answer: string;
    evidence: string;
    score: number;
    area: string;
    element: string;
    notapplicable: boolean;
}


export const inspectionPlanQuestionColumns: IField<IInspectionPlanQuestion>[] = [
    {
        fieldName: 'planno',
        dataType: 'string',
        label: 'INSPECTION_PLAN_PLANNO',
        primaryKey: true,
        insert: 'hide',
        hide: 'table'
    },
    {
        fieldName: 'questionno',
        label: 'INSPECTION_PLAN_QUESTIONNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 30,
        }
    },
    {
        fieldName: 'area',
        label: 'INSPECTION_PLAN_AREA',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'element',
        label: 'INSPECTION_PLAN_ELEMENT',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'question',
        dataType: 'string',
        label: 'INSPECTION_PLAN_QUESTION',
        insert: 'show',
        input: 'textarea',
        maxLength: 1024
    },
    {
        fieldName: 'requirements',
        label: 'AUDIT_REQUIREMENTS',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        insert: 'show',
        maxLength: 1024
    },
    {
        fieldName: 'mandatory',
        label: 'INSPECTION_PLAN_MANDATORY',
        dataType: 'boolean',
        input: 'checkbox',
        grid: {
            width: 40,
            cellRenderer: (params: any) => params.value ? iconRenderer("exclamation", undefined, cl_red) : ''
        }
    },
];