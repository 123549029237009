import {Status, StatusComplete, statusText} from "./Status";
import {IField} from "../components/dataset/IField";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {enumToArray} from "../components/lib/enumToArray";
import {StatusRenderer} from "./cell-renderers/StatusRenderer";
import {userColumns} from "./User";
import {DuedateRenderer} from "./_helper/duedate-renderer";
import {projectColumns} from "./Project";
import {dateCellRenderer} from "../components/dataset/IDataset";
import {StartdateRenderer} from "./_helper/startdate-renderer";
import {supplierColumns} from "./Supplier";


export interface IGemba {
    gembano: string;
    gemba: string;
    gembatemplate: string;
    status: Status;
    responsible: string;
    startdate: string;
    duedate: string;
    projectno: string;
    supplierno: string;
    supplier1: string;
    createdby: string;
    createdat: string;
    gantt: boolean;
    area_cat: string;
    element_cat: string;
    comment: string;

    //[key: string]: string | number;
}


export const gembaColumns: IField<IGemba>[] = [
    {
        fieldName: 'gembano',
        dataType: 'string',
        label: 'GEMBA_GEMBANO',
        primaryKey: true,
        //rules: ['pk'],
        insert: 'hide',
    },
    {
        fieldName: 'gemba',
        dataType: 'string',
        label: 'GEMBA_GEMBA',
        insert: 'show'
    },
    {
        fieldName: 'gembatemplate',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_TEMPLATE',
        rules: ['mandatory'],
        insert: 'show',
        hide: 'table'
    },
    {
        fieldName: 'supplierno',
        label: 'SUPPLIER_SUPPLIERNO',
        dataType: 'string',
        rules: ['mandatory'],
        insert: 'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT
        },
        useAsFilter: true,
    },
    {
        fieldName: 'supplier1',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIER1',
        readOnly: true,
    },
    {
        fieldName: 'responsible',
        dataType: 'string',
        label: 'PROJECTTASK_RESPONSIBLE',
        insert: 'show',
        rules: ['mandatory'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
        useAsFilter: true,
        defaultValue: ''
    },
    {
        fieldName: 'status',
        dataType: 'number',
        label: 'AUDIT_STATUS',
        rules: ['number'],
        input: 'catalog',
        textFunc: statusText,
        options: enumToArray(StatusComplete),
        useAsFilter: true,
        defaultValue: Status.PLANNED,
        grid: {
            cellRenderer: StatusRenderer,
            width: 100
        },
    },

    {
        fieldName: 'startdate',
        label: 'PROJECTTASK_STARTDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: StartdateRenderer,
            //cellStyle: dateCellStyle,
            width: 100
        },

        input: 'calendar'
    },
    {
        fieldName: 'duedate',
        label: 'PROJECTTASK_DUEDATE',
        dataType: 'string',
        insert: 'show',
        rules: ['mandatory'],
        grid: {
            cellRenderer: DuedateRenderer,
            //cellStyle: dateCellStyle,
            width: 100
        },
        input: 'calendar'
    },
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        //insert:'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/project/',
            columns: projectColumns,
            InserRight: UsergroupRight.UR_PROJECT_PROJECT_INSERT
        }
    },

    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,
        hide: 'table',
    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        hide: 'table',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
    },
    {
        fieldName: 'gantt',
        dataType: 'boolean',
        label: 'PROJECTTASK_GANTT',
        defaultValue: true,
        input: 'checkbox',
        hide: 'table',
    },
    {
        fieldName: 'area_cat',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_AREA_CAT',
        hide: 'table',
    },
    {
        fieldName: 'element_cat',
        dataType: 'string',
        label: 'GEMBA_TEMPLATE_ELEMENT_CAT',
        hide: 'table',
    },
    {
        fieldName: 'comment',
        dataType: 'string',
        label: 'AUDIT_COMMENT',
        input: 'textarea',
        hide: 'table'
    },

];