import styled from "styled-components";
import {IBaseView} from "../_base/base-view";
import {CardBody, Col, Row} from "reactstrap";
import * as React from "react";
import {DbForm} from "../../components/dbform/db-form";
import {IconButton} from "../_base/styled/icon-button";
import {TableCard} from "../_base/styled/table-card";
import {inject, observer} from "mobx-react";
import {action} from "mobx";

@inject('rootStore')
@observer
class _AuthPasswordChangeNotLoggedIn extends React.Component<IBaseView, {}> {

    @action.bound
    async onClick() {
        const {rootStore: {routerStore, authStore: {cdsPasswordForgot, onChangePasswordToken}}} = this.props;
        if (cdsPasswordForgot.validator.check(cdsPasswordForgot.actual)) {
            await onChangePasswordToken();
            routerStore.goTo('login')
        } else {
            await Promise.reject('')
        }

    }


    render() {
        const {
            rootStore: {langStore: {lang}, authStore: {cdsPasswordForgot}},
            className
        } = this.props;

        return (
            <div className={className}>
                <Row style={{alignItems: 'center', height: '100%', justifyContent: 'center'}}>

                    <Col md={3}>
                        <TableCard title='USER_CHANGE_PASSWORD' icon='user'>
                            <CardBody>
                                {/*doOK={this.onChangePassword}*/}
                                <DbForm ds={cdsPasswordForgot} colCount={1}/>
                                <p>{lang.USER_CHANGE_PASSWORD_NOTE}</p>
                                <IconButton id="change-btn"
                                            onClick={this.onClick}>{lang.USER_CHANGE_PASSWORD}</IconButton>{' '}

                            </CardBody>
                        </TableCard>

                    </Col>
                </Row>
            </div>
        )
    }
}


export const AuthPasswordChangeNotLoggedIn = styled(_AuthPasswordChangeNotLoggedIn)`
  background-color: ${props => props.theme.bg};
  background-image: url("/img/background.jpg");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
`


