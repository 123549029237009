import React from 'react';
import {Col, Row} from "reactstrap";
import {AuditCheckbox} from "./audit-checkbox";
import {inject, observer} from "mobx-react";
import {action} from "mobx";
import {auditQuestionColumns, AuditScore} from "../../../models/AuditQuestion";
import {IAuditRating} from "./audit-rating";
import {Field} from "../../../components/dbform/field/field";


@inject('rootStore')
@observer
export class AuditRatingBinary extends React.Component<IAuditRating, {}> {


    @action.bound
    onChangeApplicable() {
        const {rootStore: {authStore: {username}}, question, audit, readOnly} = this.props;
        if (username === audit?.leadauditor && !readOnly) {
            question.notapplicable = !question.notapplicable;
            if (question.notapplicable) {
                question.qm = -1;
                question.praxis = -1;
                question.score = AuditScore.NOT_APPLICABLE;
            } else {
                question.score = AuditScore.UNRATED;
            }
        }
    }


    @action.bound
    onChangeNO() {
        const {rootStore: {authStore: {username}}, question, audit, readOnly} = this.props;
        if (username === audit?.leadauditor && !readOnly) {
            question.score = question.score === AuditScore.NO_COMPLIANCE ? AuditScore.UNRATED : AuditScore.NO_COMPLIANCE;
            question.notapplicable = false;
        }
    }

    @action.bound
    onChangeFULL() {
        const {rootStore: {authStore: {username}}, question, audit, readOnly} = this.props;
        if (username === audit?.leadauditor && !readOnly) {
            question.score = question.score === AuditScore.FULL_COMPLIANCE ? AuditScore.UNRATED : AuditScore.FULL_COMPLIANCE;
            question.notapplicable = false;
        }
    }


    render() {
        const {rootStore: {langStore: {lang}}, question, readOnly} = this.props;
        return (
            <>
                <Row>
                    <Col>
                        {lang.AUDIT_NO}<br/>
                        <AuditCheckbox checked={question?.score === AuditScore.NO_COMPLIANCE}
                                       onChange={this.onChangeNO}/>
                    </Col>
                    <Col>
                        {lang.AUDIT_YES}<br/>
                        <AuditCheckbox checked={question?.score === AuditScore.FULL_COMPLIANCE}
                                       onChange={this.onChangeFULL}/>
                    </Col>
                    <Col>
                        {lang.AUDIT_RATING_NA}<br/>
                        <AuditCheckbox checked={question?.notapplicable}
                                       onChange={this.onChangeApplicable}/>
                    </Col>
                </Row>
                <Row style={{paddingTop: '1rem'}}>
                    <Col>
                        <Field actual={question}
                               column={auditQuestionColumns.find((column) => column.fieldName === "extra01")}
                               readOnly={readOnly}/>
                    </Col>
                    <Col>
                        <Field actual={question}
                               column={auditQuestionColumns.find((column) => column.fieldName === "extra02")}
                               readOnly={readOnly}/>
                    </Col>
                </Row>
            </>
        )
    }
}