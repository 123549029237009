import * as React from 'react';
import {IBaseView} from "../../../_base/base-view";
import {IAuditQuestion} from "../../../../models/AuditQuestion";
import {inject, observer} from "mobx-react";
import {action, runInAction} from "mobx";
import {historyAdapter} from "../../../_layout/app";
import {AuditCollectLayout} from "../../../_base/styled/audit-collect-layout";
import {IconButton} from "../../../_base/styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuditCollectCollectQuestion} from "../shared/audit-collect-collect-question";
import {IAudit} from "../../../../models/Audit";
import {IAuditImage} from "../../../../stores/audit-online-store";
import {UncontrolledTooltip} from "reactstrap";


interface IAuditCollectCollectView extends IBaseView {
    audit: IAudit;
    questions: IAuditQuestion[];
    actArea: string;
    actElement: string;
    nextElement: () => void;
    prevElement: () => void;
    post: () => Promise<void>;
    actQuestionno: string;
    onClickQuestion: (question: IAuditQuestion) => () => Promise<void>;
    images: IAuditImage[];
    uploadFile?: (acceptFile: any[]) => Promise<void>;
    accept?: string; // filter auf die Dateitypen die ausgewähltwerden können.
    deleteFile: (imageno: number) => Promise<void>;
    canDeleteFile: boolean;
}

@inject('rootStore')
@observer
export class AuditCollectCollectView extends React.Component<IAuditCollectCollectView, {}> {


    @action.bound
    goBack() {
        historyAdapter.goBack();
    }

    @action.bound
    async goPrevElement() {
        const {post, prevElement} = this.props;
        await post();
        runInAction(() => {
            prevElement();
        })
    }

    @action.bound
    async goNextElement() {
        const {post, nextElement} = this.props;
        await post();
        runInAction(() => {
            nextElement();
        })
    }


    render() {
        const {
            audit,
            questions,
            actArea,
            actElement,
            actQuestionno,
            onClickQuestion,
            images,
            uploadFile,
            accept,
            deleteFile,
            canDeleteFile,
            rootStore: {langStore: {lang}}
        } = this.props;
        return (
            <AuditCollectLayout>
                <div className="header">
                    <div className="left">
                        <IconButton id="goto_back" onClick={this.goBack}>
                            <FontAwesomeIcon icon={["fas", "arrow-left"]}/>
                        </IconButton>
                        <UncontrolledTooltip placement="bottom" target="goto_back">
                            {lang['BACK']}
                        </UncontrolledTooltip>
                    </div>
                    <div className="center">
                        {actArea} &nbsp;&nbsp;- &nbsp;&nbsp;{actElement}
                    </div>
                    <div className="right">
                        <IconButton id="prev_element" onClick={this.goPrevElement}>
                            <FontAwesomeIcon icon={["fas", "caret-left"]}/>
                        </IconButton>
                        <UncontrolledTooltip placement="bottom" target="prev_element">
                            {lang['PREVIOUS']}
                        </UncontrolledTooltip>
                        <IconButton id="next_element" onClick={this.goNextElement}>
                            <FontAwesomeIcon icon={["fas", "caret-right"]}/>
                        </IconButton>
                        <UncontrolledTooltip placement="bottom" target="next_element">
                            {lang['NEXT']}
                        </UncontrolledTooltip>

                    </div>
                </div>

                <div className="content">

                    {questions ?
                        questions
                            .filter(question => question.area === actArea && question.element === actElement)
                            .map((question, index) =>
                                <AuditCollectCollectQuestion
                                    key={index}
                                    audit={audit}
                                    question={question}
                                    cursor={question.questionno === actQuestionno}
                                    className={"question"}
                                    onClick={onClickQuestion(question)}
                                    images={images}
                                    uploadFile={uploadFile}
                                    accept={accept}
                                    deleteFile={deleteFile}
                                    canDeleteFile={canDeleteFile}
                                />
                            ) :
                        null}
                </div>
            </AuditCollectLayout>
        )
    }
}