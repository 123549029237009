import {IField} from "../components/dataset/IField";


export interface IPassword {
    oldpassword: string;
    newpassword1: string;
    newpassword2: string;
}


export const _passwordColumns: IField<IPassword>[] = [

    {
        fieldName:"oldpassword",
        dataType:'string',
        input: 'password',
        label:'USER_CURRENT_PASSWORD',
        rules:['mandatory']
    },
    {
        fieldName:"newpassword1",
        dataType:'string',
        input: 'password',
        label:'USER_NEW_PASSWORD',
        rules:['mandatory']
    },
    {
        fieldName:"newpassword2",
        dataType:'string',
        input: 'password',
        label:'USER_CONFIRMATION_PASSWORD',
        rules: ['mandatory', ['equalto', 'newpassword1']]
    }
];