import {IField} from "../components/dataset/IField";


export interface IInspectionQuestionImage {
    inspectionno: string;
    questionno: string;
    imageno: number;
    image: string;
}


export const inspectionQuestionImageColumns: IField<IInspectionQuestionImage>[] = [
    {
        fieldName: 'inspectionno',
        dataType: 'string',
        label: 'AUDIT_AUDITNO',
        rules: ['pk'],
        primaryKey: true,
        insert: 'hide',
        hide: 'table'
    },
    {
        fieldName: 'questionno',
        label: 'AUDIT_QUESTIONNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 60
        },
        hide: 'table'
    },
    {
        fieldName: 'imageno',
        label: '',
        dataType: 'number',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 60
        },
        hide: 'table'
    },
    {
        fieldName: 'image',
        dataType: 'string',
        label: 'IMAGE',
        insert: 'show',
    },

];