import * as React from 'react';
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {IBaseView} from "../../_base/base-view";
import {InspectionCollectHeaderTitle} from "./inspection-collect-header-title";
import {InspectionCollectOverview} from "./inspection-collect-overview";
import {InspectionCollectMain} from "./inspection-collect-main";
import {InspectionCollectFooter} from "./inspection-collect-footer";


@inject('rootStore')
@observer
class _InspectionCollectView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {inspectionStore: {titleOpen}}, className} = this.props;
        return (
            <div className={className}>
                <div className={"sheader"}>
                    <InspectionCollectHeaderTitle className={"InspectionCollectHeaderTitle"}/>
                    {titleOpen ?
                        <InspectionCollectOverview className={"InspectionCollectOverview"}/> :
                        null
                    }

                </div>
                <div className={"smain"}>
                    <div className="smaincontent">
                        {!titleOpen ?
                            <InspectionCollectMain/> :
                            null
                        }
                    </div>
                </div>
                {<div className={"sfooter"}>
                    {!titleOpen ?
                        <InspectionCollectFooter/> :
                        null}
                </div>}
            </div>
        )
    }
}


export const InspectionCollectView = styled(_InspectionCollectView)`
  display:flex;
  flex-direction: column;
  position:absolute;
  height:100%;
  width:100%;
  
   .sheader{
     flex:1 1 5%;
     
  }
  .smain{
     flex: 1 1 auto;
     color:black;
     display:block;
     position:relative;
     height:100%;
     
     .smaincontent{
       position:absolute;
       height:100%;
       width:100%;
       
     }
  }
  
  .sfooter{
     flex:1 1 5%;
     padding:0;
     
  }
  
`;