import {BaseStore, IBaseStore} from "./base-store";
import {Dataset} from "../components/dataset/dataset";
import {IProduct, productColumns} from "../models/Product";
import {action, observable} from "mobx";
import {IProductCustomer, productCustomerColumns} from "../models/ProductCustomer";
import {IProductSupplier, productSupplierColumns} from "../models/ProductSupplier";
import {RouterState} from "mobx-state-router";
import {dsState} from "../components/dataset/IDataset";
import {PpapFamilyTyp} from "../models/Config";


export interface IProductStore extends IBaseStore<IProduct> {
    dsProductCustomer: Dataset<IProductCustomer>;
    dsProductSupplier: Dataset<IProductSupplier>;
}


export class ProductStore extends BaseStore<IProduct> {


    @observable
    dsProductCustomer: Dataset<IProductCustomer>;

    @observable
    dsProductSupplier: Dataset<IProductSupplier>;

    constructor(rootStore: any) {
        super(rootStore, '/gridApi/product/', productColumns);
        //console.log(this.cdsFilter.columns)

        this.dsProductCustomer = new Dataset<IProductCustomer>('/gridApi/productcustomer/', productCustomerColumns);
        this.dsProductCustomer.setMasterSource(this.ds, [{field: 'productno', masterField: 'productno'}]);

        this.dsProductSupplier = new Dataset<IProductSupplier>('/gridApi/productsupplier/', productSupplierColumns);
        this.dsProductSupplier.setMasterSource(this.ds, [{field: 'productno', masterField: 'productno'}]);
    }

    @action.bound
    async openDetails() {
        /**
         *  ppap_family je nach Konfiguration ein/ausblenden
         */
        this.ds.columns
            .filter((column) => column.fieldName === 'ppap_family')
            .forEach((column) => column.hide = this.rootStore.authStore.config.ppap_family_typ === PpapFamilyTyp.PART_FIELD ? 'table' : 'always')
        await this.dsProductSupplier.open();
        await this.dsProductCustomer.open();
    }

    @action.bound
    closeDetails() {
        this.dsProductCustomer.close();
        this.dsProductSupplier.close();
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.initializeAsync();
        await this.loadFilter('product');

        if (toState.routeName === 'producttable') {
            await this.open(this.cdsFilter.actual);
            if (fromState.routeName === 'product') {
                this.ds.locate(this.ds.pkFields, fromState.params)
            }
        } else if (toState.routeName === 'product') {
            await this.open(toState.params as any);
            await this.openDetails();
        }
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        if (fromState.routeName === 'product') {
            if (this.ds.state === dsState.dsEdit) {
                await this.ds.post();
            }
            this.closeDetails();
            this.close();
        } else if (fromState.routeName === 'producttable') {
            this.close();
            Promise.resolve();
        }
    }

}