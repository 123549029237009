import {IField} from "../components/dataset/IField";
import {projectColumns} from "./Project";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {inspectionPlanColumns} from "./InspectionPlan";
import {userColumns} from "./User";
import {enumToArray} from "../components/lib/enumToArray";
import {Status, StatusComplete, statusText} from "./Status";
import {dateCellRenderer} from "../components/dataset/IDataset";
import {customerColumns} from "./Customer";
import {StatusRenderer} from "./cell-renderers/StatusRenderer";







export interface IInspection {
    inspectionno: string;
    inspection: string;
    planno: string;
    projectno: string;
    inspector: string;
    customerno: string;
    status: Status;
    duedate: string;
    createdby: string;
    createdat: string;
}


export const inspectionColumns: IField<IInspection>[] = [
    {
        fieldName: 'inspectionno',
        dataType: 'string',
        label: 'INSPECTION_INSPECTIONNO',
        primaryKey: true,
        insert: 'hide'
    },
    {
        fieldName: 'inspection',
        dataType: 'string',
        label: 'INSPECTION_INSPECTION',
        insert: 'show'
    },
    {
        fieldName: 'projectno',
        label: 'PROJECT_PROJECTNO',
        dataType: 'string',
        //insert:'show',
        readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/project/',
            columns: projectColumns,
            InserRight: UsergroupRight.UR_PROJECT_PROJECT_INSERT
        }
    },
    {
        fieldName: 'planno',
        dataType: 'string',
        label: 'INSPECTION_PLAN',
        insert: 'show',
        //hide: "table",
        rules: ['mandatory'],
        input: 'selectdlg',
        readOnly: true,
        selectdlg: {
            url: '/gridApi/inspectionplan/',
            columns: inspectionPlanColumns,
            field: 'planno',
            InserRight: UsergroupRight.UR_INSPECTION_PLAN_INSERT
        },
        useAsFilter: true,
    },

    {
        fieldName: 'status',
        dataType: 'number',
        label: 'AUDIT_STATUS',
        rules: ['number'],
        input: 'catalog',
        textFunc: statusText,
        options: enumToArray(StatusComplete),
        grid: {
            cellRenderer: StatusRenderer,
            width: 100
        },
        useAsFilter: true,
        defaultValue: Status.PLANNED
    },
    {
        fieldName: 'inspector',
        dataType: 'string',
        label: 'INSPECTION_INSPECTOR',
        insert: 'show',
        rules: ['mandatory'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/user/',
            columns: userColumns,
            field: 'username',
            InserRight: UsergroupRight.UR_ADMIN_USER_INSERT
        },
        useAsFilter: true,
        defaultValue: ''
    },
    {
        fieldName: 'customerno',
        label: 'CUSTOMER_CUSTOMERNO',
        dataType: 'string',
        rules: ['mandatory'],
        insert: 'show',
        //readOnly: true,
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/customer/',
            columns: customerColumns,
            InserRight: UsergroupRight.UR_BASE_CUSTOMER_INSERT
        },
        useAsFilter: true,
    },
    {
        fieldName: 'createdby',
        dataType: 'string',
        label: 'CREATED_BY',
        readOnly: true,

    },
    {
        fieldName: 'createdat',
        dataType: 'string',
        label: 'CREATED_DAT',
        input: 'calendar',
        grid: {
            cellRenderer: dateCellRenderer,
        },
        readOnly: true,
    },
    {
        fieldName: 'duedate',
        dataType: 'string',
        label: 'PROJECTTASK_DUEDATE',
        input: 'calendar',
        insert: 'show'
    },

];