import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton} from "../../../_base/styled/icon-button";
import {UncontrolledTooltip} from "reactstrap";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {action, observable} from "mobx";
import {Dialog} from "../../../../components/dialog/Dialog";


interface IMenuImageDelete extends IBaseView {
    id: string;
    disabled: boolean;
    onClickDelete: () => Promise<void>;
    onAfterDelete?: () => void;
}

@inject('rootStore')
@observer
export class MenuImageDelete extends React.Component<IMenuImageDelete, {}> {

    @observable
    level: number = 0;

    @observable
    isOpen: boolean = false;

    @observable
    dialogText: string = '';

    @action.bound
    toggleOpen() {
        const {rootStore: {langStore: {lang}}} = this.props;

        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.level = 1;
            this.dialogText = lang.DELETE_ENTRY;
        } else {
            this.level = 0;
            this.dialogText = ''
        }
    }

    @action.bound
    incLevel() {
        const {rootStore: {langStore: {lang}}} = this.props;
        this.level = 2;
        this.dialogText = lang.REALLY_DELETE_ENTRY;
    }

    onItemClick = () => {
        this.toggleOpen();
    };

    onCancel = () => {
        this.toggleOpen();
    };

    onOk = async () => {
        let {onClickDelete, onAfterDelete} = this.props;
        if (this.level === 1) {
            this.incLevel();
        } else {

            try {
                await onClickDelete();
                this.toggleOpen();
                if (onAfterDelete) {
                    onAfterDelete();
                }
            } catch (error) {
                console.log('Error while deleting');
            }
        }
    };

    renderText = () => {
        return (
            <div>
                {this.level === 2 ? <h5>{this.dialogText}</h5> : this.dialogText}
            </div>
        )
    };


    render() {
        const {rootStore: {langStore: {lang}}, id, disabled} = this.props;
        return (
            <>
                <IconButton
                    id={id + "btn-delete"}
                    onClick={this.onItemClick}
                    disabled={disabled}
                >
                    <FontAwesomeIcon icon="minus"/>
                </IconButton>
                <UncontrolledTooltip placement="bottom-end" target={id + "btn-delete"}>
                    {lang.DELETE}
                </UncontrolledTooltip>
                <Dialog
                    title={lang.CONFIRMATION}
                    isOpen={this.isOpen}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    okColor="danger"
                >
                    {this.renderText()}
                </Dialog>
            </>
        )
    }

}