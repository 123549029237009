import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../../_base/base-view";
import {computed} from "mobx";
import {cl_blue} from "../../../../_theme/colors";
import moment from "moment";

@inject('rootStore')
@observer
export class PpapReportCoverPage extends React.Component<IBaseView, {}> {

    @computed
    get logo() {
        const {rootStore: {ppapStore: {dsCustomer}}} = this.props;
        return dsCustomer.actual ? dsCustomer.actual.logo ? "/gridApi/customerlogo/download/" + dsCustomer.actual.customerno + "/" + dsCustomer.actual.logo : "" : "";
    }

    render() {
        const {rootStore: {ppapStore: {ds, dsCustomer, dsSupplier, dsProduct, dsProject},langStore:{lang}}} = this.props;
        return (
            <>

                {[...Array(3).keys()].map((x, i) => <h1 key={i}>&nbsp;</h1>)}
                {this.logo ?
                    <img
                        style={{width: '100%', height: '200px', objectFit: 'contain'}}
                        src={this.logo}
                        alt='logo'/> :
                    null
                }
                {[...Array(2).keys()].map((x, i) => <p key={i}>&nbsp;</p>)}

                <h1 style={{textAlign: 'center'}}>{lang.PPAP_PPAP4WIND_DOCUMENTATION}</h1>
                {[...Array(8).keys()].map((x, i) => <p key={i}>&nbsp;</p>)}
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td><h2>{lang.PROJECT_PROJECT}</h2></td>
                        <td><h2
                            style={{color: cl_blue}}>{dsProject.actual?.projectno + ' - ' + dsProject.actual?.project}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PPAP_ORDER}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.ppapno + ' - ' + ds.actual?.ppap}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PPAP_REVISION}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.ppaprevision}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.CUSTOMER}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{dsCustomer.actual?.customer1}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.SUPPLIER}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{dsSupplier.actual?.supplier1}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PRODUCT_PRODUCTNO}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.productno}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PRODUCT_DESCRIPTION1}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{dsProduct.actual?.description1}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PPAP_ECLREVISION}</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.eclrevision}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PPAP_ECLDATE}</h2></td>
                        <td><h2
                            style={{color: cl_blue}}>{ds.actual?.ecldate ? moment(ds.actual?.ecldate).format('YYYY-MM-DD') : ''}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td><h2>{lang.PPAP_SUBMISSION_DATE}</h2></td>
                        <td>
                            <h2 style={{color: cl_blue}}>
                                {ds.actual?.submissiondate ? moment(ds.actual?.submissiondate).format('YYYY-MM-DD') : ''}
                            </h2>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <img
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '10%',
                        objectFit: 'cover'
                    }}
                    src='/img/favicon.png'
                    alt='logo'/>
            </>
        )
    }

}