import * as React from 'react'
import {IBaseView} from "../../_base/base-view";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {InspectionScore} from "../../../models/InspectionQuestion";
import {IInspectionQuestionImage} from "../../../models/InspectionQuestionImage";

const Image=({image}:any)=>
    <img src={"/gridApi/image/inspectionquestionimage/" + image.inspectionno + '/' + image.questionno + '/' + image.image} alt={image.image} style={{width:'200px'}}/>

const Zeile = ({question, images}: any) =>{
    return(
    <tr>
        <td style={{width: '10%'}}>{question.questionno}</td>
        <td style={{width: '35%'}}>{question.question}</td>
        <td style={{width: '3%'}}>{question.score === InspectionScore.FULL_COMPLIANCE ? 'x' : ''}</td>
        <td style={{width: '3%'}}>{question.score === InspectionScore.NO_COMPLIANCE ? 'x' : ''}</td>
        <td style={{width: '3%'}}>{question.score === InspectionScore.NOT_APPLICABLE ? 'x' : ''}</td>
        <td style={{width: '10%'}}>{question.extra01}</td>
        <td style={{width: '10%'}}>{question.extra02}</td>
        <td style={{width: '26%'}}>{images.length>0? <Image image={images[0]}/> : ''}</td>
    </tr>
)
}


@inject('rootStore')
@observer
export class _InspectionReportResult extends React.Component<IBaseView, {}> {
    render() {
        const {rootStore: {inspectionStore: {dsInspectionQuestion, dsReportImages}}, className} = this.props;
        return (
            <div className={className}>
                <table>
                    <thead>
                    <tr>
                        <th style={{width: '8%'}}>#</th>
                        <th style={{width: '25%'}}>Question</th>
                        <th style={{width: '5%'}}>yes</th>
                        <th style={{width: '5%'}}>no</th>
                        <th style={{width: '5%'}}>n.a.</th>
                        <th style={{width: '5%'}}>findings,if no</th>
                        <th style={{width: '5%'}}>responsible,if no</th>
                        <th style={{width: '5%'}}>Picture</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dsInspectionQuestion.data
                        .map((question, i) => {
                            let images = dsReportImages.data
                                .filter((image: IInspectionQuestionImage) => question.questionno === image.questionno)

                            return (<Zeile key={i} question={question} images={images}/>)
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}


export const InspectionReportResult = styled(_InspectionReportResult)`
padding-top:1rem;
table,th,td{
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
}


`