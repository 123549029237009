import * as React from 'react';
import {CardFooter} from "reactstrap";
import styled from "styled-components";

interface IBaseCardFooter {
    className?: string;
    footerContent?: JSX.Element;
}


class _BaseCardFooter extends React.Component<IBaseCardFooter, {}> {
    render() {
        const {footerContent, className} = this.props;
        return (
            <CardFooter className={className}>
                {footerContent}
            </CardFooter>
        )
    }
}


export const BaseCardFooter = styled(_BaseCardFooter)`
 background-color: white;
 display:${props => props.footerContent ? 'flex' : 'none'};
`;