import * as React from 'react';
import styled from "styled-components";
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {Sidebar} from "./sidebar/Sidebar";
import {darken} from "polished";

interface ILeftSide extends IBaseView {
    open: boolean;
}


const _Left = inject('rootStore')(observer((props: ILeftSide) => {
    const {className} = props;
    return (
        <div className={className}>
            <Sidebar/>
        </div>
    )
}));


export const Left = styled(_Left)`
  flex: 0 0 auto;
  flex-direction: column;
  display: block;
  overflow: hidden;
  transition: width .25s ease-in-out;
  left: 0;
  width: ${props => props.open ? '260px' : '3rem'};
  border-right: ${props => props.theme.border};
  background-color: ${props => props.theme.bg};
  color: white;

  @media (max-width: 576px) {
    width: ${props => props.open ? '260px' : '0'};
    border-right: 0;
  };


  .sidebar {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.bg};
    color: ${props => props.theme.color};
    overflow: hidden;
    transition: width .25s ease-in-out;
    left: 0;
    width: ${props => props.open ? '260px' : '3rem'};
    height: 100%;
    font-size: ${props => props.theme.fontSize};
    font-weight: 500;


    .menu {
      padding-top: 1.5rem;
      flex: 0 0 auto;


      ul {
        list-style-position: inside;
        list-style-type: none;
        padding: 0;
        margin: 0.25rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: 6px;

        &.open {
          background-color: ${props => props.theme.bgBody};
          border: ${props => props.theme.border};
        }


        li {
          color: ${props => props.theme.color};
          padding: 0.25rem;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          //height: 1.5rem;

          &.selected {
              //background-color: ${props => props.theme.bgHover};
          }

          &:hover {
            background-color: ${props => props.theme.bgHover};
          }

          .icon {
            padding-left: 0;
            flex: 0 0 2rem;
            color: ${props => darken(0.1, props.theme.color)};
            font-size: 1.25rem;
            text-align: center
          }

          .label {
            padding-left: 1rem;
            padding-top: 6px;
            padding-bottom: 2px;
            margin: auto;
            flex: 1 1 auto;
            color: ${props => props.theme.color};
            font-size: 1rem;
            font-weight: 500;
          }

          .indicator {
            flex: 0 0 2rem;
            color: ${props => props.theme.color};
          }

          &.child {
            padding-left: 1rem;

            &:hover {
              background-color: ${props => props.theme.bgHover};
            }

            &.selected {
              background-color: ${props => props.theme.bgHover};
            }
          }
        }
      }

    }

    .footer {
      flex: 0 0 auto;
      flex-direction: row;
      display: flex;
      height: 3rem;
      border-top: ${props => props.theme.border};


      .icon {
        flex: 0 0 3rem;
        padding: 0.675rem;

        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .label {
        flex: 1 1 auto;
        //padding-left: 0.5rem;
        padding-top: 0.25rem;

        h5 {
          font-size: 1rem;
          margin-bottom: 0;
          line-height: 1.5;
        }

        p {
          font-size: 0.75rem;
        }
      }

    }
  }
`;