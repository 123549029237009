import * as React from 'react';
import {IBaseView} from "../../../_base/base-view";
import {DetailLayout} from "../../../_base/styled/layout/detail-layout";
import {inject, observer} from "mobx-react";
import {TableForm} from "../../../_base/styled/table-form";
import {IconButton} from "../../../_base/styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, ListGroup, ListGroupItem, Row, UncontrolledTooltip} from "reactstrap";
import {action} from "mobx";
import {auditColumns, IAudit} from "../../../../models/Audit";
import {Field} from "../../../../components/dbform/field/field";
import {TableCard} from "../../../_base/styled/table-card";
import {cl_green} from "../../../_theme/colors";
import {IArea, IAuditImage} from "../../../../stores/audit-online-store";
import {IRouteNames} from "../../../../routes/route-names";


interface IAuditCollectView extends IBaseView {
    audit: IAudit;
    //
    areas: IArea[];
    actArea: string;
    setArea: (area: string) => void;
    //
    elements: IArea[];
    actElement: string;
    setElement: (element: string) => void;
    collectRouteName: IRouteNames;
    images: IAuditImage[];
    uploadFile: (acceptFile: any[]) => Promise<void>;
}


@inject('rootStore')
@observer
export class AuditCollectView extends React.Component<IAuditCollectView, {}> {

    onClickArea = (label: string) => {
        const {setArea} = this.props;
        return () => setArea(label);
    };

    onClickElement = (label: string) => {
        const {setElement} = this.props;
        return () => setElement(label);
    };

    @action.bound
    async onClickCollect() {
        const {rootStore: {routerStore}, audit, collectRouteName} = this.props;
        await routerStore.goTo(collectRouteName, {auditno: audit.auditno})
    }

    render() {
        const {rootStore: {langStore: {lang}}, audit, areas, actArea, elements, actElement} = this.props;
        //console.log(actArea,actElement,elements)
        return (
            <DetailLayout
                topElement={
                    <TableForm
                        icon="eye"
                        title='AUDIT'
                        //ds={ds}
                        keyTitle={audit?.auditno + " - " + audit?.audit}
                        open={false}
                        extraMenu={
                            <>
                                <IconButton id="gotocollect" onClick={this.onClickCollect}>
                                    <FontAwesomeIcon icon={["fad", "user-md-chat"]}/>
                                </IconButton>
                                <UncontrolledTooltip placement="bottom" target="gotocollect">
                                    {lang['DATACOLLECT']}
                                </UncontrolledTooltip>

                            </>
                        }
                    >

                        <Row>
                            <Col>
                                <Field actual={audit}
                                       column={auditColumns.find((column) => column.fieldName === "auditno")}
                                       readOnly={true}/>
                                <Field actual={audit}
                                       column={auditColumns.find((column) => column.fieldName === "status")}
                                       readOnly={true}/>
                            </Col>
                            <Col>
                                <Field actual={audit}
                                       column={auditColumns.find((column) => column.fieldName === "audit")}
                                       readOnly={true}/>
                                <Field actual={audit}
                                       column={auditColumns.find((column) => column.fieldName === "supplierno")}
                                       readOnly={true}/>
                            </Col>
                            <Col>
                                <Field actual={audit}
                                       column={auditColumns.find((column) => column.fieldName === "leadauditor")}
                                       readOnly={true}/>
                                <Field actual={audit}
                                       column={auditColumns.find((column) => column.fieldName === "plant")}
                                       readOnly={true}/>
                            </Col>
                        </Row>

                    </TableForm>
                }

                mainLeftElement={
                    <TableCard
                        title='AUDIT_AREAS'
                        icon="layer-group"
                    >
                        <ListGroup style={{cursor: 'pointer'}}>
                            {
                                areas.map(
                                    (area, i) =>
                                        <ListGroupItem key={i} onClick={this.onClickArea(area.label)}
                                                       style={{borderTop: 0, borderRight: 0, borderLeft: 0}}>
                                            <div style={{display: 'inline-block', width: '2rem'}}>
                                                {
                                                    area.completed ?
                                                        <FontAwesomeIcon color={cl_green} icon="check"/> :
                                                        null
                                                }
                                            </div>
                                            {area.label === actArea ?
                                                <b>{area.label}</b> :
                                                area.label
                                            }
                                        </ListGroupItem>
                                )
                            }
                        </ListGroup>
                    </TableCard>
                }

                mainRightElement={
                    <TableCard
                        title='AUDIT_ELEMENTS'
                        icon="layer-group"
                    >
                        <ListGroup style={{cursor: 'pointer'}}>
                            {
                                elements.map(
                                    (element, i) =>
                                        <ListGroupItem key={i} onClick={this.onClickElement(element.label)}
                                                       style={{borderTop: 0, borderRight: 0, borderLeft: 0}}>
                                            <div style={{display: 'inline-block', width: '2rem'}}>
                                                {
                                                    element.completed ?
                                                        <FontAwesomeIcon color={cl_green} icon="check"/> :
                                                        null
                                                }
                                            </div>
                                            {element.label === actElement ?
                                                <b>{element.label}</b> :
                                                element.label
                                            }
                                        </ListGroupItem>
                                )
                            }
                        </ListGroup>
                    </TableCard>
                }
            />
        )
    }

}