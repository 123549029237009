import {observer} from "mobx-react";
import {action} from "mobx";
import styled from "styled-components";
import {cl_gray300, cl_red} from "../../../../_theme/colors";
import * as React from "react";
import {IPpapOrderDocument} from "../../../../../models/PpapDocument";
import {Input, InputGroup} from "reactstrap";


interface IDocumentEditor {
    className?: string;
    document: IPpapOrderDocument;
    fieldName: string;
    readonly: boolean;
    mandatory?: boolean;
}


const _DocumentEditor = observer(({className, document, fieldName, readonly, mandatory}: IDocumentEditor) => {

        if (fieldName === 'sample_type') {
            return (
                <InputGroup>
                    <Input
                        style={{fontSize: '12px', height: '19px', paddingLeft: 0, paddingTop: 0, paddingBottom: 0}}
                        id={fieldName}
                        name={fieldName}
                        type="select"
                        disabled={readonly}
                        autoComplete="off"
                        value={document ? (document as any)[fieldName] ?? '' : ''}
                        bsSize="sm"
                        onChange={action((e: any) => {
                            if (!readonly) {
                                (document as any)[fieldName] = e.target.value;
                            }
                        })}
                    >
                        <option key={0} value=' '></option>
                        <option key={1} value='Product Sample'>Product Sample</option>
                        <option key={2} value='Master Stample'>Master Stample</option>
                    </Input>
                </InputGroup>
            )
        } else {

            return (
                <>
                    <input
                        className={className}
                        style={{border: mandatory && document ? (document as any)[fieldName] ? 0 : '2px solid ' + cl_red : 0}}
                        name={fieldName}
                        autoComplete="off"
                        value={document ? (document as any)[fieldName] ?? '' : ''}
                        readOnly={readonly}
                        onChange={action(
                            (e: any) => {
                                if (!readonly) {
                                    (document as any)[fieldName] = e.target.value;
                                }
                            }
                        )}
                    />
                </>
            )
        }
    }
)


export const DocumentEditor = styled(_DocumentEditor)`
  width: 100%;
  border: 0;
  font-size: 12px;
  //border-bottom: 1px solid black;
  background-color: ${cl_gray300};

  :focus {
    outline: ${props => !!props.readonly ? 0 : '1px solid black'};
  }
`;