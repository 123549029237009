import * as React from "react";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {DbField} from "../../../../components/dbform/db-field";


export const GembaFinding = inject('rootStore')(observer(
    (props: IBaseView) => {
        const {rootStore: {mobileGembaStore: {dsGembaFinding}}} = props;
        return (
            <div className={"gemba-finding"}>
                <DbField ds={dsGembaFinding} field={'area'}/>

                <div className={"wrapper"}>
                    <DbField ds={dsGembaFinding} field={'keyword'}/>
                </div>
                <div className={"wrapper"}>
                    <DbField ds={dsGembaFinding} field={'finding'}/>
                </div>
            </div>
        )
    }
))