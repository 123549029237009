import {Dataset} from "../components/dataset/dataset";
import {action, observable, toJS} from "mobx";
import {IDataset} from "../components/dataset/IDataset";
import {IField} from "../components/dataset/IField";
import {IRootStore} from "../routes/root-store";
import {RouterState} from "mobx-state-router";
import axios from 'axios';
import {ClientDataset} from "../components/dataset/clientdataset";


export interface IBaseStore<T> {
    initializeAsync: () => Promise<any>;
    rootStore: IRootStore;
    dsCheck: IDataset<T>;
    ds: IDataset<T>;
    cdsFilter: IDataset<T>;
    open: (filter?: T) => Promise<void>;
    close: () => void;
    openDetails: () => Promise<void>;
    closeDetails: () => void;
    afterOpen: (fromState: RouterState, toState: RouterState) => void;
    beforeClose: (fromState: RouterState, toState: RouterState) => Promise<void>;

    beforeEnter: (fromState: RouterState, toState: RouterState) => Promise<boolean>;
    onEnter: (fromState: RouterState, toState: RouterState) => Promise<void>;
    onExit: (fromState: RouterState, toState: RouterState) => Promise<void>;
    //
    loadFilter: (filtername: string) => Promise<void>;
    saveFilter: (filtername: string) => Promise<void>;


}


export class BaseStore<IModel> implements IBaseStore<IModel> {

    rootStore: IRootStore;

    dsCheck: IDataset<IModel>;

    @observable
    ds: IDataset<IModel> = null;

    @observable
    cdsFilter: IDataset<IModel> = null;

    constructor(rootStore: IRootStore, dataUrl: string, columns: IField<IModel>[], filter?: any) {
        this.rootStore = rootStore;
        this.ds = new Dataset<IModel>(dataUrl, columns, filter ? filter : {});
        this.dsCheck = new Dataset<IModel>(dataUrl, columns);

        this.cdsFilter = new ClientDataset<IModel>(columns
            .filter((column) => column.useAsFilter)
            .map(column => {
                column.readOnly = false;
                return column
            })
        );


    }

    /**
     * async initializiation task of the Store
     * runs only once.
     */
    initializeAsync = async () => {
        if (!this.cdsFilter.data.length) {
            await this.cdsFilter.open();
            this.cdsFilter.insert();
            await this.cdsFilter.post();
        } else {
            await Promise.resolve();
        }
    }

    // neues open. wird in den Stores benutzt
    @action.bound
    async open(filter?: Partial<IModel>) {
        if (filter) {
            this.ds.filter = filter;
        } else {
            this.ds.filter = {};
        }
        await this.ds.open();
    }

    @action.bound
    close() {
        this.ds.close();
        this.ds.filter = {};
    }

    @action.bound
    async openDetails() {
        await Promise.resolve();
    }

    @action.bound
    closeDetails() {
    }


    beforeEnter = async (fromState: RouterState, toState: RouterState) => {
        return Promise.resolve(true);
    }


    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.open();
        this.afterOpen(fromState, toState)
    }

    @action.bound
    afterOpen(fromState: RouterState, toState: RouterState) {

    }

    // Führt zu Mecker beim commiten, weil fromState und toState nicht benutzt werden,
    // soll aber so bleiben als Platzhalter für Aberbungen
    @action.bound
    async beforeClose(fromState: RouterState, toState: RouterState) {
        await Promise.resolve();
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        await this.beforeClose(fromState, toState)
    }

    @action.bound
    async loadFilter(filtername: string): Promise<void> {
        //console.log('loaded')
        const {authStore: {username}} = this.rootStore;
        try {
            let response: any = await axios.get('/gridApi/userfilter/' + username + '/' + filtername);
            this.cdsFilter.close();
            await this.cdsFilter.open();
            this.cdsFilter.insert();
            Object.keys(this.cdsFilter.actual)
                .forEach((field: string) => {
                    (this.cdsFilter.actual as any)[field] = response.data[field];
                });
            await this.cdsFilter.post();
        } catch
            (error) {
            await Promise.resolve()
        }
    }

    @action.bound
    async saveFilter(filtername: string): Promise<void> {
        const {authStore: {username}} = this.rootStore;
        // Logout => kein speichern. sonst crash
        if (username) {
            // remove the fields with empty strings
            let filter: any = toJS(this.cdsFilter.actual);
            Object.keys(filter)
                .forEach(field => {
                    if (filter[field] === "") {
                        filter[field] = undefined;
                    }
                });
            await axios.post('/gridApi/userfilter/' + username + '/' + filtername, filter);
        }
    }


}