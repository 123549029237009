import * as React from 'react';
import {Col, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {DbField} from "../../../components/dbform/db-field";
import {DetailOneColLayout} from "../../_base/styled/layout/detail-one-col-layout";
import {TableForm} from "../../_base/styled/table-form";

@inject('rootStore')
@observer
export class PpapTemplateElementView extends React.Component<IBaseView, any> {
    render() {
        const {rootStore: {ppapStandardStore: {dsPpapMatrixDetail}, langStore: {lang}}} = this.props;
        return (
            <DetailOneColLayout
                topElement={
                    <TableForm icon="file" title='PPAP_ELEMENT' ds={dsPpapMatrixDetail}>
                        <Row>
                            <Col md={6}>
                                <DbField ds={dsPpapMatrixDetail} field="standardno"/>
                            </Col>
                            <Col md={2}>
                                <DbField ds={dsPpapMatrixDetail} field="elementno"/>
                            </Col>
                            <Col md={4}>
                                <DbField ds={dsPpapMatrixDetail} field="element"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={dsPpapMatrixDetail} field="phase"/>
                            </Col>
                            <Col>
                                <DbField ds={dsPpapMatrixDetail} field="reference"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>{lang['PPAP_RISKLEVEL_LOW']}</div>
                                <DbField ds={dsPpapMatrixDetail} field="doc1"/>
                                <DbField ds={dsPpapMatrixDetail} field="sr1"/>
                            </Col>
                            <Col>
                                <div>{lang['PPAP_RISKLEVEL_MEDIUM']}</div>
                                <DbField ds={dsPpapMatrixDetail} field="doc2"/>
                                <DbField ds={dsPpapMatrixDetail} field="sr2"/>
                            </Col>
                            <Col>
                                <div>{lang['PPAP_RISKLEVEL_HIGH']}</div>
                                <DbField ds={dsPpapMatrixDetail} field="doc3"/>
                                <DbField ds={dsPpapMatrixDetail} field="sr3"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={dsPpapMatrixDetail} field="template"/>
                            </Col>
                            <Col>
                                <DbField ds={dsPpapMatrixDetail} field="requirements"/>
                            </Col>
                        </Row>
                    </TableForm>

                }
            />
        )
    }
}