import {IModulNames} from "./modul-names";
import {IconName} from "@fortawesome/fontawesome-svg-core";
import {ILangKeys} from "../language/ILang";
import {ProjectActivityTyp} from "../models/ProjectActivity";


interface IActivityTypDef {
    activityTyp: ProjectActivityTyp;
    moduleName: IModulNames | '';
    icon: IconName;
    moduleText: ILangKeys;
}

const ActivityTypDef: IActivityTypDef = {
    activityTyp: ProjectActivityTyp.ACTIVITY,
    moduleName: '',
    icon: 'chart-network',
    moduleText: 'PROJECTTASK_ACTIVITY'
}

const AuditTypDef: IActivityTypDef = {
    activityTyp: ProjectActivityTyp.AUDIT,
    moduleName: 'AUDIT',
    icon: 'eye',
    moduleText: 'PROJECTTASKTYP_AUDIT'
}

const PPAPTypDef: IActivityTypDef = {
    activityTyp: ProjectActivityTyp.PPAP,
    moduleName: 'PPAP',
    icon: 'box-check',
    moduleText: 'PROJECTTASKTYP_PPAP'
}

const InspectionTypDef: IActivityTypDef = {
    activityTyp: ProjectActivityTyp.INSPECTION,
    moduleName: 'IM',
    icon: 'clipboard-check',
    moduleText: 'PROJECTTASKTYP_INSPECTION'
}

const GembaTypDef: IActivityTypDef = {
    activityTyp: ProjectActivityTyp.GEMBA_WALK,
    moduleName: 'GW',
    icon: 'walking',
    moduleText: 'PROJECTTASKTYP_GEMBAWALK'
}

export const ActivityTypeDefs: IActivityTypDef[] = [ActivityTypDef, AuditTypDef, PPAPTypDef, InspectionTypDef, GembaTypDef];
