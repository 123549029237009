import * as React from 'react';
import ReactEcharts from "echarts-for-react";
import {cl_gray400, cl_green, cl_red, cl_yellow} from "../../views/_theme/colors";
import {RatingMethod} from "../../models/AuditTemplate";


export interface ICatValue {
    category: string;
    value: number;
    color?: string;
}


interface IBarGraph {
    orientation: 'vertical' | 'horizontal';
    data: ICatValue[];
    title: string;
    fontSize?: number;
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
    labelRotate?: number;
    ratingmethod: RatingMethod;
}


export class BarGraph extends React.Component<IBarGraph, {}> {


    valueAxis = (): any => {
        return {
            type: 'value',
            min: 0,
            max: 100
        }
    };

    categoryAxis = (): any => {
        const {data, orientation, labelRotate} = this.props;
        const maxLength = 50;
        return {
            type: 'category',
            data: orientation === 'horizontal' ?
                data.map((x) => {
                    return x.category.length > maxLength ?
                        x.category.substr(1, maxLength) + '...' :
                        x.category
                }).reverse() :
                data.map((x) => {
                    return x.category.length > maxLength ?
                        x.category.substr(1, maxLength) + '...' :
                        x.category
                }),
            axisLabel: {
                show: true,
                interval: 0,
                rotate: labelRotate ?? 0
            },

        }
    };


    getSeries = () => {
        const {data, orientation, ratingmethod} = this.props;
        let series: any =
            [
                {
                    // red
                    type: 'bar',
                    stack: '1',
                    data: [],
                    label: {
                        show: true,
                        position: 'inside',
                        color: '#fff',
                        formatter: (params: any) => {
                            return params.value > 0 ? params.value.toFixed(2) : '';
                        }
                    },
                },
                {
                    // yellow
                    type: 'bar',
                    stack: '1',
                    data: [],
                    label: {
                        show: true,
                        position: 'inside',
                        color: '#fff',
                        formatter: (params: any) => {
                            return params.value > 0 ? params.value.toFixed(2) : '';
                        }
                    },
                }
                ,
                {
                    //green
                    type: 'bar',
                    stack: '1',
                    data: [],
                    label: {
                        show: true,
                        position: 'inside',
                        color: '#fff',
                        formatter: (params: any) => {
                            return params.value > 0 ? params.value.toFixed(2) : '';
                        }
                    },
                },
                {
                    //gray
                    type: 'bar',
                    stack: '1',
                    data: [],
                    label: {
                        show: true,
                        position: 'inside',
                        color: '#000',
                        formatter: (params: any) => {
                            return params.value > 0 ? 'n.a.' : '';
                        }
                    }
                }
            ];

        if (ratingmethod === RatingMethod.VESTAS_SBA) {
            data
                .forEach((x: ICatValue) => {
                    series[0].data.push(x.value <= 50 ? x.value : 0);
                    series[1].data.push(x.value > 50 && x.value < 70 ? x.value : 0);
                    series[2].data.push(x.value >= 70 ? x.value : 0);
                    series[3].data.push(x.value === -20 ? 100 : 0)
                });
        } else {
            data
                .forEach((x: ICatValue) => {
                    series[0].data.push(x.value < 60 ? x.value : 0);
                    series[1].data.push(x.value >= 60 && x.value < 80 ? x.value : 0);
                    series[2].data.push(x.value >= 80 ? x.value : 0);
                    series[3].data.push(x.value === -20 ? 100 : 0)
                });
        }

        if (orientation === 'horizontal') {
            series[0].data.reverse();
            series[1].data.reverse();
            series[2].data.reverse();
            series[3].data.reverse();
        }


        return series;
    };


    getOption = (): echarts.EChartOption => {
        const {orientation, title, fontSize, top, left, bottom, right} = this.props;

        return {
            color: [cl_red, cl_yellow, cl_green, cl_gray400],
            grid: {
                top: top ?? '20%',
                left: left ?? '5%',
                right: right ?? '5%',
                bottom: bottom ?? '5%',
                containLabel: true,
                width: 'auto',
                height: 'auto'
            },
            title: {
                text: title,
                top: '5%',
                left: 'center',
                textStyle: {
                    fontSize: fontSize ? fontSize : 18
                }
            },
            xAxis: orientation === 'horizontal' ? this.valueAxis() : this.categoryAxis(),
            yAxis: orientation === 'horizontal' ? this.categoryAxis() : this.valueAxis(),
            series: this.getSeries()
        };

    };

    render() {
        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{height: '100%', width: '100%'}}
            />
        )
    }
}