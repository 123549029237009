import React from 'react';
import {IField} from "../../../components/dataset/IField";
import {action,} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton} from "../styled/icon-button";

interface IMenuClear {
    actual: any;
    column: IField<any>;
    onClick: () => void;
}

export const MenuClear = ({actual, column, onClick}: IMenuClear) =>
    <IconButton
        onClick={
            action(() => {
                    actual[column.fieldName] = "";
                    onClick();
                }
            )
        }>
        <FontAwesomeIcon icon="eraser"/>
    </IconButton>;