import * as React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import {action} from "mobx";
import {observer} from "mobx-react";
import styled from "styled-components";
import {cl_gray400} from "../../_theme/colors";


const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${cl_gray400};
`;

const Image = styled.img`
  object-fit:contain;
  width: 100%;
  height: 100%;
`;


interface IBaseCarousel {
    images: any[];
    height?: number;
    activeIndex: number;
    setActiveIndex: (activeIndex: number) => void;
}


@observer
export class BaseCarousel extends React.Component<IBaseCarousel, {}> {

    animating: boolean = false;

    setAnimating = (value: boolean) =>
        this.animating = value;


    @action.bound
    next() {
        const {images, activeIndex, setActiveIndex} = this.props;
        if (this.animating) return;
        setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1);
    }

    @action.bound
    previous() {
        const {images, activeIndex,setActiveIndex} = this.props;
        if (this.animating) return;
        setActiveIndex (activeIndex === 0 ? images.length - 1 : activeIndex - 1);
    }

    @action.bound
    goToIndex(newIndex: number) {
        const {setActiveIndex} = this.props;
        if (this.animating) return;
        setActiveIndex (newIndex);
    }


    renderSlides = () => {
        const {images, height} = this.props;

        return images.map((item, i) => {
            return (
                <CarouselItem
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                    key={i}
                >
                    <div style={{
                        height: height ? height : 300,
                        position: 'relative',
                        display: 'block',

                    }}>
                        <Absolute>
                            <Image src={item.src} alt={item.altText}/>
                        </Absolute>
                    </div>
                    <CarouselCaption captionText="" captionHeader=""/>
                </CarouselItem>
            );
        });

    };

    render() {
        const {images, activeIndex} = this.props;
        if (images.length > 0) {
            return (
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    interval={false}
                >
                    <CarouselIndicators items={images} activeIndex={activeIndex}
                                        onClickHandler={this.goToIndex}/>
                    {this.renderSlides()}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
                </Carousel>
            )
        } else return (<div/>)
    }
}






