import * as React from 'react';
import {IBaseView} from "../../../_base/base-view";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../../components/dbform/db-field";
import {TableForm} from "../../../_base/styled/table-form";
import {action, computed, observable} from "mobx";
import {Status} from "../../../../models/Status";
import {dsState} from "../../../../components/dataset/IDataset";
import {inject, observer} from "mobx-react";
import {PpapOrderPrintMenu} from "./ppap-order-print-menu";

@inject('rootStore')
@observer
export class PpapOrderTopView extends React.Component<IBaseView, {}> {

    @action.bound
    goToProject() {
        const {rootStore: {routerStore, ppapStore: {ds}}} = this.props;
        if (ds.actual.projectno) {
            routerStore.goTo('project', {projectno: ds.actual.projectno});
        }
    }

    @action.bound
    goToCustomer() {
        const {rootStore: {routerStore, ppapStore: {ds,}}} = this.props;
        routerStore.goTo('customer', {
            customerno: ds.actual.customerno,
        })
    }

    @action.bound
    goToSupplier() {
        const {rootStore: {routerStore, ppapStore: {ds,}}} = this.props;
        routerStore.goTo('supplier', {
            supplierno: ds.actual.supplierno,
        })
    }

    @action.bound
    goToProduct() {
        const {rootStore: {routerStore, ppapStore: {ds}}} = this.props;

        routerStore.goTo('product', {
            productno: ds.actual.productno,
        })
    }

    /*@action.bound
    async gotoPartFamily() {
        const {rootStore: {routerStore, ppapStore: {ds}, productStore}} = this.props;
        await productStore.cdsFilter.open();
        productStore.cdsFilter.insert();
        productStore.cdsFilter.actual.ppap_family = ds.actual.ppap_family;
        await productStore.cdsFilter.post();
        await productStore.saveFilter('product');
        routerStore.goTo('producttable',)
    }*/

    @action.bound
    async goToReport() {
        const {rootStore: {routerStore, ppapStore: {ds}}} = this.props;
        await routerStore.goTo('ppapreport', {
            ppapno: ds.actual.ppapno
        })
    }

    @computed
    get hideMenu() {
        const {
            rootStore: {
                ppapStore: {ds, dsProject, saveOwner},
                authStore: {username}
            }
        } = this.props;
        //console.log(dsProject, ds.state, dsProject.actual?.owner)
        return !((username === ds.actual?.owner && ds.actual.status !== Status.APPROVED) || username === dsProject.actual?.owner || (ds.state === dsState.dsEdit && username === saveOwner))
    }

    @computed
    get keyTitle() {
        const {rootStore: {ppapStore: {ds}}} = this.props;
        let rv1 = ds.actual?.ppapno ?? ""
        let rv2 = ds.actual?.productno ?? ""
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : ""
    }

    @observable
    open: boolean = false;

    render() {
        const {rootStore: {ppapStore: {ds, dsProduct}}} = this.props;
        return (
            <TableForm
                icon="box-check"
                title='PPAP'
                ds={ds}
                keyTitle={this.keyTitle}
                open={this.open}
                hideMenu={this.hideMenu}
                ownerField={"owner"}
                extraMenu={<PpapOrderPrintMenu goToReport={this.goToReport}/>}
            >
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'ppapno'}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field={'ppap'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'ppaprevision'}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field={'submissiondate'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'purchaseno'}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field={'status'}/>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <DbField ds={ds} field={'productno'} onClickLabel={this.goToProduct}/>
                            </Col>
                            <Col>
                                <DbField ds={dsProduct} field={'ppap_family'}
                                    /*onClickLabel={dsProduct.actual?.ppap_family ? this.gotoPartFamily : undefined}*//>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'eclrevision'}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field={'ecldate'}/>
                            </Col>
                        </Row>


                    </Col>
                    <Col md={6}>
                        <DbField ds={ds} field={'projectno'} onClickLabel={this.goToProject}/>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'standardno'}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field={'level'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col/>
                            <Col>
                                <DbField ds={ds} field={'owner'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'customerno'} onClickLabel={this.goToCustomer}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field='responsiblecust'/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DbField ds={ds} field={'supplierno'} onClickLabel={this.goToSupplier}/>
                            </Col>
                            <Col>
                                <DbField ds={ds} field={'responsiblesup'}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>

                    <Col md={3}>
                        <DbField ds={ds} field={'startdate'}/>
                    </Col>
                    <Col md={3}>
                        <DbField ds={ds} field={'duedate'}/>
                    </Col>
                    <Col md={3}>
                        <DbField ds={ds} field={'createdby'}/>
                    </Col>
                    <Col md={3}>
                        <DbField ds={ds} field={'createdat'}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DbField ds={ds} field={'gantt'}/>
                    </Col>
                </Row>
            </TableForm>
        )
    }
}