import * as React from 'react';
import {Col, Row} from "reactstrap";
import {RatingMethod} from "../../../../../models/AuditTemplate";
import {AuditStatisticScore} from "./audit-statistic-score";
import {AuditStatisticArea} from "./audit-statistic-area";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../../_base/base-view";


@inject('rootStore')
@observer
export class AuditStatistic extends React.Component<IBaseView, {}> {


    render() {
        const {rootStore: {auditStore: {ds, auditScore, SBAScore}}} = this.props;
        return (
            <Row>
                <Col xs={12}>
                    <div style={{display: 'block', width: '100%', height: '160px'}}>
                        {ds.actual?.ratingmethod === RatingMethod.VESTAS_SBA ?
                            <AuditStatisticScore
                                auditScore={SBAScore}
                                ratingMethod={ds.actual?.ratingmethod}
                            /> :
                            <AuditStatisticScore
                                auditScore={auditScore}
                                ratingMethod={ds.actual?.ratingmethod}
                            />
                        }
                    </div>
                </Col>
                <Col xs={12}>
                    <div style={{display: 'block', width: '100%', height: '250px'}}>
                        <AuditStatisticArea orientation='horizontal'/>
                    </div>

                </Col>
            </Row>
        )
    }
}