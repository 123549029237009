import React from 'react';
import {IBaseView} from "../../../_base/base-view";
import {inject, observer} from "mobx-react";
import {TableLayout} from "../../../_base/styled/layout/table-layout";
import {TableGrid} from "../../../_base/styled/table-grid";


@inject('rootStore')
@observer
export class ProjectActionTableView extends React.Component<IBaseView, {}> {
    render() {
        const {rootStore: {projectActionStore: {ds}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    icon="tasks"
                    id="action"
                    title='ACTIONS'
                    ds={ds}
                    singleRouteName='action'
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        )
    }
}