import {IField} from "../components/dataset/IField";

export interface IPpapOrderDocument {
    ppapno: string;
    elementno: number;
    documentno: number;
    document: string;
    docname: string;
    revision: string;
    revision_date: string;
    responsible: string;
    responsible_function: string;
    responsible_company: string;
    sup_prod_loc_name: string;
    internal: string;
    external: string;
    net_weight: string;
    package_size: string;
    palletized: string;
    package_material: string;
    po_number_delivery: string;
    delivery_date: string;
    sample_type: string;
    customer_approver: string;
    customer_approver_function: string;
    customer_approver_company: string;
    name01: string;
    function01: string;
    company01: string;
    name02: string;
    function02: string;
    company02: string;
    name03: string;
    function03: string;
    company03: string;
    name04: string;
    function04: string;
    company04: string;
    name05: string;
    function05: string;
    company05: string;
}

export const ppapOrderDocumentColumns: IField<IPpapOrderDocument>[] = [
    {
        fieldName: 'ppapno',
        label: 'PPAP_ORDER',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        hide: 'table',
        insert: 'hide',
    },
    {
        fieldName: 'elementno',
        dataType: 'number',
        label: 'PPAP_ELEMENTNO',
        //hide: 'table',
        insert: 'hide',
        primaryKey: true,
        grid: {
            width: 40
        }
    },
    {
        fieldName: 'documentno',
        label: 'PPAPDOCUMENT_DOCUMENTNO',
        dataType: 'number',
        primaryKey: true,
        rules: ['pk'],
        insert: 'show',
        grid: {
            width: 45
        }
    },
    {
        fieldName: 'document',
        label: 'PPAPDOCUMENT_DOCUMENT',
        dataType: 'string',
        insert: 'show',
        grid: {
            flex: 1,
            cellRenderer: (params: any) => '<a href="/gridApi/ppaporderdocument/' + params.data.ppapno + '/' + params.data.elementno + '/' + params.value + '" download >' + params.value + '</a>',
        }
    },

];