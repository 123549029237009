import {inject, observer} from "mobx-react";
import {FormGroup, Input, Label} from "reactstrap";
import {action} from "mobx";
import * as React from "react";
import {IFieldProps} from "../i-db-field-props";
import {ILangKeys} from "../../../language/ILang";


// Patch the marginLeft. It seems not correct in Bootstrap (Error?)


export const FieldCheckbox =
    inject('rootStore')(
        observer(
            (props: IFieldProps<any>) => {
                let {rootStore: {langStore: {lang}}, column, actual, readOnly} = props;
                const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : column.fieldName as string;
                return (
                    <FormGroup>
                        <Input
                            type="checkbox"
                            checked={actual ? actual[column.fieldName] : false}
                            readOnly={readOnly}
                            name={column.fieldName as string}
                            onChange={action(
                                (e: any) => {
                                    if (!readOnly) {
                                        actual[e.target.name] = e.target.checked;
                                    }

                                }
                            )}
                            style={{marginLeft: "0rem"}}/>
                        <Label style={{marginLeft: "1.25rem"}}>{label}</Label>
                    </FormGroup>
                );
            }));