import React from 'react';
import {IBaseView} from "../_base/base-view";
import styled from "styled-components";
import {Col, Container, Row} from "reactstrap";
import {inject, observer} from "mobx-react";
import {BaseTableFilterMenu} from "../_base/table-filter/base-table-filter-menu";
import {DashboardProjectCard} from "./project/dashboard-project-card";
import {UsergroupRight} from "../../models/rights/enum-usergrouprights";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

@inject('rootStore')
@observer
class _DashboardView extends React.Component<IBaseView, {}> {


    render() {
        const {className} = this.props;
        const {rootStore: {dashboardStore: {projectStati}, authStore: {hasUserRight}}} = this.props;
        return (
            <div className={className}>
                <div className="dashboardheader">
                    <div className="cardheader">
                        <div className={"left"}>
                            <FontAwesomeIcon icon={["fad", 'home']}/> &nbsp;&nbsp;&nbsp;
                            Dashboard
                        </div>
                        <div className={"center"}>

                        </div>
                        <div className="right">
                            {
                                hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                                    <BaseTableFilterMenu storeName="dashboardStore"
                                                         filtername="dashboard"/> :
                                    null
                            }
                        </div>
                    </div>
                </div>

                <div className={'printcontent'}>
                    <Container fluid style={{paddingTop: '0rem'}}>
                        <Row style={{alignItems: 'stretch'}}>
                            {projectStati.map(((projectStatus) =>
                                    <Col key={projectStatus.project.projectno} xl={4} lg={6} md={12} sm={12} xs={12}
                                         style={{marginBottom: '1rem', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                                        <DashboardProjectCard projectStatus={projectStatus}/>
                                    </Col>

                            ))
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}


export const DashboardView = styled(_DashboardView)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  //
  display: flex;
  flex-direction: column;
  //

  color: black;
  background-color: ${props => props.theme.bgBody};
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${props => props.theme.gutter};
  box-shadow: ${props => props.theme.boxShadowInset};

  .dashboardheader {
    display: block;
    flex: 0 0 auto;
    margin-bottom: 1rem;

    .cardheader {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${props => props.theme.color};
      border: ${props => props.theme.border};
      border-radius: ${props => props.theme.borderRadius};
      box-shadow: ${props => props.theme.boxShadow};
      //
      padding: ${props => props.theme.box.header.padding};
      font-size: ${props => props.theme.box.header.fontSize};
      font-weight: ${props => props.theme.box.header.fontWeight};
      background-color: ${props => props.theme.box.header.backgroundColor};

      .keyTitle {
        font-weight: 500;
      }

      .right {
        font-weight: 100;
        color: black;
        font-size: 14px;
      }
    }
  }

  .printcontent {
    padding-top: 0;
    margin-top: 0;
    margin-left: -8px;
    margin-right: -8px;
    flex: 1 1 auto;
    display: block;
    position: relative;
    overflow-y: auto;
    scrollbar-width: none;


    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    .card {
      background-color: white;
      border-radius: ${props => props.theme.borderRadius};
      border: ${props => props.theme.border};
      box-shadow: ${props => props.theme.boxShadow};

      font-size: 1.3125rem;
      font-weight: 100;
      overflow: hidden;
      text-align: center;
      width: 100%;

      .card-header {
        padding: 1rem;
        font-size: 1rem;
        background-color: white;
        text-align: left;
        border-bottom: ${props => props.theme.border};

        .status-left {
          display: inline;
          color: ${props => props.theme.main.color};
          cursor: pointer;
          align-self: center;
          font-weight: 700;
          text-align: left;


          > .logo {
            display: inline-block;
            width: 2rem;
            height: 2rem;
          }

          :hover {
            color: ${props => props.theme.main.color};
            //text-decoration: underline;
          }
        }

        .status-right {
          display: inline;
          color: ${props => props.theme.main.color};
          align-self: center;
          font-weight: 400;
          text-align: right;
        }
      }

      .cardBody {
        margin-top: 1rem;
        padding: 1.25rem;
      }
    }

    .link {
      display: inline;
      color: ${props => props.theme.main.color};
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;

      :hover {
        color: ${props => props.theme.main.color};
        //text-decoration: underline;
      }
    }

    .simpletext {
      display: inline;
      color: ${props => props.theme.main.color};
      cursor: default;
      font-size: 1rem;
      font-weight: 500;
    }

    .project {
      text-align: left;
    }
  }
`;


