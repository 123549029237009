import * as React from 'react';
import {IFieldProps} from "../i-db-field-props";
import {FieldInput} from "./field-input";
import {FieldTextarea} from "./field-textarea";
import {FieldDatetime} from "./field-datetime";
import {FieldLookup} from "./field-lookup";
import {FieldLookupdlg} from "./field-lookupdlg";
import {FieldCatalog} from "./field-catalog";
import {FieldUploaddlg} from "./field-uploaddlg";
import {FieldPassword} from "./field-password";
import {FieldSelect} from "./field-select";
import {FieldSelectRadio} from "./field-select-radio";
import {FieldSelectdlg} from "./field-selectdlg";
import {FieldCheckbox} from "./field-checkbox";
import {FieldRadio} from "./field-radio";


export class Field<T> extends React.Component<IFieldProps<T>, {}> {

    render() {
        const {column, optionsFunc} = this.props;


        // Todo error einsteuern

        switch (column.input) {
            case 'input':
                return <FieldInput {...this.props} />;
            case  'fileuploaddlg':
                return <FieldUploaddlg {...this.props} />;
            case 'textarea':
                return (<FieldTextarea {...this.props} />);
            case 'calendar':
                return <FieldDatetime {...this.props} />;
            case 'lookup':
                return <FieldLookup {...this.props} />;
            case 'lookupdlg':
                return <FieldLookupdlg {...this.props} />;
            case 'catalog':
                return <FieldCatalog {...this.props} optionsFunc={optionsFunc}/>;
            case 'password':
                return <FieldPassword {...this.props} />;
            case 'select':
                return <FieldSelect {...this.props} />;
            case 'selectradio':
                return <FieldSelectRadio {...this.props} />;
            case 'selectdlg':
                return <FieldSelectdlg {...this.props} />;
            case 'checkbox':
                return <FieldCheckbox {...this.props} />;
            case 'radio':
                return <FieldRadio {...this.props} />;


            default:
                return <FieldInput {...this.props} />;
        }
    }
}