import {Dataset} from "./dataset";
import {IField} from "./IField";

// Same funktionality, except http communication
// an only in memory Dataset

export class ClientDataset<T> extends Dataset<T> {
    constructor(columns: IField<T>[]) {
        super('', columns, {}, false);
    }
}