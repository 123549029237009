import {IField} from "../components/dataset/IField";


// Login doesn't exist in DB !!!!!!


export interface ILogin {
    username: string;
    password: string;
    email: string;
}

export const _loginColumns: IField<ILogin>[] = [
    {
        fieldName: 'username',
        dataType: 'string',
        label: 'USER_USERNAME',
    },
    {
        fieldName: 'password',
        dataType: 'string',
        input: 'password',
        label: 'USER_PASSWORD',
    },
    {
        fieldName: 'email',
        dataType: 'string',
        label: 'USER_EMAIL',
        rules: ['email']
    },
];