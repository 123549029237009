import * as React from 'react';
import {inject, observer} from "mobx-react";
import {Dialog} from "../../../components/dialog/Dialog";
import {action, observable, runInAction} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IRootStore} from "../../../routes/root-store";
import {IDataset} from "../../../components/dataset/IDataset";
import {IconButton} from "../styled/icon-button";
import {UncontrolledTooltip} from "reactstrap";
import {cl_red} from "../../_theme/colors";

interface IMenuDelete {
    rootStore?: IRootStore;
    id: string;
    ds: IDataset<any>;
    disabled?: boolean;
    onAfterDelete?: () => void;
}

/*
 2020.01.13: jetzt 2-stufige Bestätigung bevor gelöscht wird

 */
@inject('rootStore')
@observer
export class MenuDelete extends React.Component<IMenuDelete, {}> {

    @observable
    level: number = 0;

    @observable
    isOpen: boolean = false;

    @observable
    dialogText: string = '';

    @observable
    errorText: string = '';

    @action.bound
    toggleOpen() {
        const {rootStore: {langStore: {lang}}} = this.props;
        this.errorText = '';
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.level = 1;
            this.dialogText = lang.DELETE_ENTRY;
        } else {
            this.level = 0;
            this.dialogText = ''
        }
    }

    @action.bound
    incLevel() {
        const {rootStore: {langStore: {lang}}} = this.props;
        this.level = 2;
        this.dialogText = lang.REALLY_DELETE_ENTRY;
    }


    onItemClick = () => {
        this.toggleOpen();
    };


    onCancel = () => {
        this.toggleOpen();
    };

    onOk = async () => {
        let {rootStore: {langStore: {lang}}, ds, onAfterDelete} = this.props;
        if (this.level === 1) {
            this.incLevel();
        } else {

            try {
                await ds.delete();
                this.toggleOpen();
                if (onAfterDelete) {
                    onAfterDelete();
                }
            } catch (error) {
                runInAction(() => this.errorText = lang.NOT_POSSIBLE_TO_DELETE)
            }
        }
    };


    renderText = () => {
        return (
            <div>
                {this.level === 2 ? <h5>{this.dialogText}</h5> : this.dialogText}
                <br/>
                {this.errorText ?
                    <h5 style={{color: cl_red}}>
                        {this.errorText}
                    </h5> :
                    ''
                }
            </div>
        )
    };

    render() {
        const {rootStore: {langStore: {lang}}, ds, id, disabled} = this.props;
        return (
            <>
                <IconButton
                    id={id + "btn-delete"}
                    onClick={disabled ? undefined : this.onItemClick}
                    disabled={disabled || !ds || ds.cursor === undefined}
                >
                    <FontAwesomeIcon icon="minus"/>
                </IconButton>
                <UncontrolledTooltip placement="bottom-end" target={id + "btn-delete"}>
                    {lang.DELETE}
                </UncontrolledTooltip>
                <Dialog
                    title={lang.CONFIRMATION}
                    isOpen={this.isOpen}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    okColor="danger"
                >
                    {this.renderText()}
                </Dialog>
            </>
        )
    }


}