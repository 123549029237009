import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import moment from "moment";
import {ppapRisklevelText} from "../../../models/Ppap";
import {PageTitle} from "./helper/page-title";

@inject('rootStore')
@observer
export class PpapReportDetails extends React.Component<IBaseView, {}> {

    render() {
        const {
            rootStore: {
                ppapStore: {ds, dsProduct, dsCustomer, dsSupplier, dsCustResponsible, dsSupResponsible},
                langStore: {lang}
            }
        } = this.props;
        return (
            <>
                <PageTitle>PPAP Details</PageTitle>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td>{lang.PPAP_GENERAL_INFO}</td>
                        <td>{lang.PPAP_SUBMISSION_DATE}</td>
                        <td>{ds.actual?.submissiondate ? moment(ds.actual?.submissiondate).format('YYYY-MM-DD') : ''}</td>
                    </tr>
                    <tr>
                        <td/>
                    </tr>
                    <tr>
                        <td>{lang.PPAP_PRODUCT_INFO}</td>
                        <td>{lang.PRODUCT_PRODUCTNO}</td>
                        <td>{dsProduct.actual?.productno}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PRODUCT_DESCRIPTION1}</td>
                        <td>{dsProduct.actual?.description1}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PPAP_PURCHASENO}</td>
                        <td>{ds.actual?.purchaseno}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PRODUCT_SPEZIFICATION}</td>
                        <td>{dsProduct.actual?.spezification}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PRODUCT_DRAWINGNO}</td>
                        <td>{dsProduct.actual?.drawingno}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PPAP_ECLREVISION}</td>
                        <td>{ds.actual?.eclrevision}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PPAP_ECLDATE}</td>
                        <td>{ds.actual?.ecldate}</td>
                    </tr>
                    <tr>
                        <td/>
                    </tr>

                    <tr>
                        <td>{lang.PPAP_PPAP_INFO}</td>
                        <td>{lang.PPAP_REVISION}</td>
                        <td>{ds.actual?.ppaprevision}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.PPAP_RISKLEVEL}</td>
                        <td>{lang[ppapRisklevelText(ds.actual?.level)]}</td>
                    </tr>

                    <tr>
                        <td>{lang.PPAP_CUSTOMER_INFO}</td>
                        <td>{lang.PPAP_RESPONSIBLE_CUSTOMER}</td>
                        <td>{ds.actual?.responsiblecust}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMERCONTACT_EMAIL}</td>
                        <td>{dsCustResponsible.actual?.email}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMERCONTACT_PHONE1}</td>
                        <td>{dsCustResponsible.actual?.telefon1}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_CUSTOMER1}</td>
                        <td>{dsCustomer.actual?.customer1}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_STREET}</td>
                        <td>{dsCustomer.actual?.street}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_CITY}</td>
                        <td>{dsCustomer.actual?.city}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_COUNTRY}</td>
                        <td>{dsCustomer.actual?.country}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_POSTCODE}</td>
                        <td>{dsCustomer.actual?.postcode}</td>
                    </tr>
                    <tr>
                        <td/>
                    </tr>
                    <tr>
                        <td>{lang.PPAP_SUPPLIER_INFO}</td>
                        <td>{lang.PPAP_RESPONSIBLE_SUPPLIER}</td>
                        <td>{ds.actual?.responsiblesup}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.SUPPLIERCONTACT_EMAIL}</td>
                        <td>{dsSupResponsible.actual?.email}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.SUPPLIERCONTACT_PHONE1}</td>
                        <td>{dsSupResponsible.actual?.telefon1}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.SUPPLIER_SUPPLIER1}</td>
                        <td>{dsSupplier.actual?.supplier1}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.SUPPLIER_CODE}</td>
                        <td>{dsSupplier.actual?.supplier_code}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_STREET}</td>
                        <td>{dsSupplier.actual?.street}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_CITY}</td>
                        <td>{dsSupplier.actual?.city}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_COUNTRY}</td>
                        <td>{dsSupplier.actual?.country}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td>{lang.CUSTOMER_POSTCODE}</td>
                        <td>{dsSupplier.actual?.postcode}</td>
                    </tr>
                    </tbody>
                </table>
            </>
        )
    }
}