import axios, {AxiosRequestConfig} from 'axios';
import {authorizer} from "./authorizer";

// interface for all http actions
// all methods shall return a promise
export interface IHttpAdapter {

    get(url: string, config?: any): any;

    post(url: string, data?: any, config?: any): any;

    put(url: string, data?: any, config?: any): any;

    patch(url: string, data?: any, config?: any): any;

    delete(url: string, config?: any): any;


}

// https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
/*function fixedEncodeURIComponent (str:string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}*/


export class HttpAxiosAdapter implements IHttpAdapter {

    noCacheHeaders = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0',
        Authorization: `Bearer ${sessionStorage.getItem('id_token')}`
    };



    get(url: string, config?: AxiosRequestConfig): any {
        return axios.get(url, authorizer(config));
    }

    post(url: string, data?: any, config?: AxiosRequestConfig) {
        const postUrl = url[url.length - 1] !== '/' ? url + '/' : url; //Flask specific: ensure a trailing '/'
        return axios.post(postUrl, data, authorizer(config));
    }

    put(url: string, data: any, config: AxiosRequestConfig) {
        return axios.put(url, data, authorizer(config));
    }

    patch(url: string, data: any, config: AxiosRequestConfig) {
        return axios.patch(url, data, authorizer(config));
    }

    delete(url: string, config: AxiosRequestConfig) {
        return axios.delete(url, authorizer(config));
    }
}

export class NoHttpAdapter implements IHttpAdapter {

    get(url: string, config?: any): any {
        return Promise.resolve();
    }

    post(url: string, data?: any, config?: any) {
        return Promise.resolve();
    }

    put(url: string, data: any, config: any) {
        return Promise.resolve();
    }

    patch(url: string, data: any, config: any) {
        return Promise.resolve();
    }

    delete(url: string, config: any) {
        return Promise.resolve();
    }


}