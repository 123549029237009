import * as React from 'react';
import {IBaseView} from "../../../_base/base-view";
import {auditQuestionColumns, IAuditQuestion} from "../../../../models/AuditQuestion";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ScoreBadge2} from "../../rating/rating-score-badge";
import {auditScoreColors, auditScoreText, IAuditImage} from "../../../../stores/audit-online-store";
import {AuditRating} from "../../rating/audit-rating";
import {AuditCollectCollectInfo} from "./audit-collect-collect-info";
import {Field} from "../../../../components/dbform/field/field";
import styled from "styled-components";
import {cl_black, cl_gray600, cl_green, cl_red, cl_white, cl_yellow} from "../../../_theme/colors";
import {IAudit} from "../../../../models/Audit";
import {AuditCollectCollectImages} from "./audit-collect-collect-images";


interface IAuditCollectCollectQuestion extends IBaseView {
    audit: IAudit;
    question: IAuditQuestion;
    cursor: boolean;
    onClick: () => Promise<void>;
    images?: IAuditImage[];
    uploadFile?: (acceptFile: any[]) => Promise<void>;
    accept: string;
    deleteFile: (imageno: number) => Promise<void>;
    canDeleteFile: boolean;
}


@inject('rootStore')
@observer
class _AuditCollectCollectQuestion extends React.Component<IAuditCollectCollectQuestion, {}> {


    render() {
        const {className, audit, question, onClick, images, uploadFile, accept, deleteFile, canDeleteFile} = this.props;
        //console.log(images)
        return (
            <Card className={className} onClick={onClick}>
                <CardHeader className={"qheader"}>
                    <div className="left">
                        {question.attentionflag ?
                            <FontAwesomeIcon color="red" size="1x"
                                             icon={["fas", "exclamation-triangle"]}/> :
                            <FontAwesomeIcon size="1x"
                                             icon={["fas", "question-circle"]}/>
                        }
                        &nbsp;&nbsp;{question.question}&nbsp;
                    </div>
                    <div className="right">
                        <ScoreBadge2 text={auditScoreText(audit?.ratingmethod, question.score)}
                                     color={auditScoreColors(audit?.ratingmethod, question.score).bg}/>
                    </div>
                </CardHeader>
                <CardBody className={"qbody"}>
                    <Row>
                        <Col>
                            <AuditRating
                                audit={audit}
                                question={question}
                                readOnly={false}  /*Todo*/
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} md={12} lg={12} xl={6}>
                            <Row style={{justifyContent: 'space-between'}}>
                                <Col>
                                    <AuditCollectCollectInfo
                                        question={question}
                                        columns={auditQuestionColumns}
                                    />
                                </Col>
                                <Col>


                                </Col>
                            </Row>

                            <Field
                                actual={question}
                                column={auditQuestionColumns.find((column) => column.fieldName === "answer")}
                            />
                        </Col>
                        <Col sm={6} md={5} lg={5} xl={3} style={{paddingLeft: 0}}>
                            <AuditCollectCollectImages images={images ?? []} uploadFile={uploadFile} accept={accept}
                                                       deleteFile={deleteFile} canDeleteFile={canDeleteFile}/>
                        </Col>
                    </Row>
                </CardBody>

            </Card>
        )
    }
}


export const AuditCollectCollectQuestion = styled(_AuditCollectCollectQuestion)`
  margin-bottom: 1rem;

  border-top: 0;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.45);
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;

  .qheader {
    background-color: ${props => props.theme.bg};
    color: ${props => props.theme.color};
    display: flex;
    border-bottom: 0;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    .left {
      flex: 1 1 auto;
      font-size: 1rem;
      font-weight: 500;

    }

    .right {
      color: ${cl_black};
      flex: 0 0 3rem;
      font-size: 1rem;
      font-weight: 500;

      .scorebtn {
        background-color: green;
        border-radius: ${props => props.theme.borderRadius};
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        color: ${props => props.theme.bg};
      }

      .btn {
        padding: 0.375rem;
        margin-top: -6px;
        margin-bottom: -6px;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2rem;
        border: 0;
        background-color: ${props =>
                props.question.score === 10 ? cl_green :
                        props.question.score === 8 ? cl_yellow :
                                props.question.score === 6 ? cl_yellow :
                                        props.question.score === 4 ? cl_red :
                                                props.question.score === 0 ? cl_red :
                                                        props.question.score === -2 ? cl_gray600 :
                                                                props.theme.bg};
        color: ${props =>
                props.question.score === 10 ? cl_white :
                        props.question.score === 8 ? cl_white :
                                props.question.score === 6 ? cl_white :
                                        props.question.score === 4 ? cl_white :
                                                props.question.score === 0 ? cl_white :
                                                        props.question.score === -2 ? cl_white :
                                                                cl_white};
      }
    }
  }

  .qbody {
    display: ${props => props.cursor ? 'block' : 'none'};
    border-top: ${props => props.theme.border};
    padding-left: 1rem;

    .not-applicable {

    }

    .image {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;
      align-self: stretch;
      height: 100%;

      .iconcol {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        flex: 0 1 2rem;
        justify-content: space-evenly;
        align-items: center;
        background-color: white;

        .btn {
          margin-top: 0.25rem;
          //padding-top:0.5rem;
          border-radius: 2rem;
          padding: 0;
          width: 2rem;
          height: 2rem;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        }
      }
    }

  }


`;