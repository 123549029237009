import * as React from 'react';
import {inject, observer, Provider} from "mobx-react";
import {RootStore} from "../../routes/root-store";
import {HistoryAdapter} from "mobx-state-router";
import {createBrowserHistory} from "history";
import {ThemeProvider} from "styled-components";
import {theme} from "../_theme/theme"
import {Header} from "./header/header";
import {IBaseView} from "../_base/base-view";
import {Left} from "./left/left";
import {AppLayout} from "./app-layout";
import {Main} from "./middle/Main";
import {offlineTheme} from "../_theme/offlineTheme";
//import {theme_color} from "../_theme/theme_color";

// Create the rootStore
// cause of ag's cellRendererFramework it has to be exported.
// the cell Renderers don't accept the inject.
// you have to explicite provide the store
// all other Views shall still walk the injected way
export const rootStore = new RootStore();

const history = createBrowserHistory();

// Observe history changes
export const historyAdapter = new HistoryAdapter(rootStore.routerStore, history);
historyAdapter.observeRouterStateChanges();

const AppContent = inject('rootStore')(
    observer((props: IBaseView) => {
            const {rootStore: {appStore: {sidebarOpen}}} = props;
            return (
                <>
                    <Header/>
                    <div className="middle">
                        <Left open={sidebarOpen}/>
                        <Main/>
                    </div>
                </>
            )
        }
    ));

@observer
export class App extends React.Component    <any, {}> {


    render() {
        let myTheme = (
            rootStore.routerStore.routerState.routeName === 'auditcollectoffline' ||
            rootStore.routerStore.routerState.routeName === 'auditcollectcollectoffline') ? offlineTheme : theme
        return (
            <>
                <Provider rootStore={rootStore}>
                    <ThemeProvider theme={myTheme}>
                        <AppLayout>
                            <AppContent/>
                        </AppLayout>
                    </ThemeProvider>
                </Provider>
            </>
        )
    }
}


