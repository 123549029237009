import {IField} from "../components/dataset/IField";
import {enumToArray} from "../components/lib/enumToArray";
import {ILangKeys} from "../language/ILang";


export enum PPAPReference {
    VDA = 100,
    AIAG = 200,
    APQP4WIND = 300
}

export const ppapReferenceText = (standard: PPAPReference): ILangKeys => {
    switch (standard) {
        case PPAPReference.VDA:
            return 'PPAP_VDA';
        case PPAPReference.AIAG:
            return 'PPAP_AIAG';
        case PPAPReference.APQP4WIND:
            return 'PPAP_APQP4WIND';
        default:
            return 'Unknown';
    }
};


export interface IPpapStandard {
    standardno: string;
    standard: string;
}

export const ppapStandardColumns: IField<IPpapStandard> [] = [

    {
        fieldName: 'standardno',
        dataType: 'string',
        primaryKey: true,
        label: 'PPAP_STANDARD',
        rules: ['pk']
    },
    {
        fieldName: 'standard',
        dataType: 'number',
        label: 'PPAP_REFERENCE',
        insert: 'show',
        readOnly:true,
        rules: ['mandatory','number'],
        input: 'select',
        options: enumToArray(PPAPReference),
        textFunc: ppapReferenceText,
        defaultValue: PPAPReference.VDA
    },

];