import * as React from 'react';
import {Alert, Col, Row, UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";
import {action, observable, runInAction} from "mobx";
import {Dialog} from "../../../../components/dialog/Dialog";
import {IBaseView} from "../../../_base/base-view";
import {ProjectActivityTyp, projectActivityTypText} from "../../../../models/ProjectActivity";
import {DbForm, FormMode} from "../../../../components/dbform/db-form";
import {IDataset} from "../../../../components/dataset/IDataset";
import {includes} from "../../../../components/lib/Includes";
import {IconButton} from "../../../_base/styled/icon-button";
import {ILangKeys} from "../../../../language/ILang";
import {IconName} from "@fortawesome/fontawesome-svg-core";
import {ActivityTypeDefs} from "../../../../stores/project-activity-lib";

interface IProjectActivitymenuInsert extends IBaseView {
    ds: IDataset<any>;

}


@inject('rootStore')
@observer
export class ProjectActivitymenuInsert extends React.Component<IProjectActivitymenuInsert, any> {

    @observable
    activityTyp: ProjectActivityTyp = undefined;


    @observable
    isOpenSelect: boolean = false;

    @observable
    isOpenInsert: boolean = false;

    @action.bound
    onCancel() {
        this.activityTyp = undefined;
        this.isOpenSelect = false;
        this.isOpenInsert = false;
    }

    private hideExtraFields = () => {
        const {rootStore: {projectStore: {dsProjectActivity}}} = this.props;
        dsProjectActivity.columns
            .filter(column => includes(['_auditno', '_audittemplate', '_ppaporderno', '_ppapstandardno', '_ppaplevel', '_ppapproductno', '_inspectionno', '_planno', "_responsiblesup","_gembatemplate"], column.fieldName))
            .forEach((column) => {
                column.insert = 'hide';
                column.rules = undefined;
            });
    };


    @action.bound
    onOkActivityType(type: ProjectActivityTyp) {
        const {rootStore: {projectStore: {dsProjectActivity}}} = this.props;
        this.activityTyp = type;
        this.isOpenSelect = false;
        switch (this.activityTyp) {
            case ProjectActivityTyp.ACTIVITY:
                this.hideExtraFields();
                dsProjectActivity.insert();
                dsProjectActivity.actual.activitytyp = this.activityTyp;
                this.isOpenInsert = true;
                break;
            case ProjectActivityTyp.GEMBA_WALK:
                this.hideExtraFields();
                /*dsProjectActivity.columns
                    .filter(column => includes(['_gembatemplate'], column.fieldName))
                    .forEach((column) => {
                        column.insert = 'show';
                        column.rules = ['mandatory'];
                    });*/
                dsProjectActivity.insert();
                dsProjectActivity.actual.activitytyp = this.activityTyp;
                this.isOpenInsert = true;
                break;
            case ProjectActivityTyp.AUDIT:
                this.hideExtraFields();
                dsProjectActivity.columns
                    .filter(column => includes(['_audittemplate'], column.fieldName))
                    .forEach((column) => {
                        column.insert = 'show';
                        column.rules = ['mandatory'];
                    });
                dsProjectActivity.insert();
                dsProjectActivity.actual.activitytyp = this.activityTyp;
                this.isOpenInsert = true;
                break;
            case ProjectActivityTyp.PPAP:
                this.hideExtraFields();
                dsProjectActivity.columns
                    .filter(column => includes(['_ppaporderno', '_ppapstandardno', '_ppaplevel', '_ppapproductno', "_responsiblesup"], column.fieldName))
                    .forEach((column) => {
                        column.insert = 'show';
                        column.rules = ['mandatory'];
                    });
                dsProjectActivity.insert();
                dsProjectActivity.actual.activitytyp = this.activityTyp;
                this.isOpenInsert = true;
                break;
            case ProjectActivityTyp.INSPECTION:
                this.hideExtraFields();
                dsProjectActivity.columns
                    .filter(column => includes(['_inspectionno', '_planno'], column.fieldName))
                    .forEach((column) => {
                        column.insert = 'show';
                        column.rules = ['mandatory'];
                    });
                dsProjectActivity.insert();
                dsProjectActivity.actual.activitytyp = this.activityTyp;
                this.isOpenInsert = true;
                break;

        }
    }

    @action.bound
    onClick() {
        this.isOpenSelect = true;
    }


    @action.bound
    async onOk() {
        const {rootStore: {projectStore: {dsProjectActivity}}} = this.props;
        if (dsProjectActivity.validator.check(dsProjectActivity.actual)) {
            await dsProjectActivity.post();
            runInAction(() => {
                this.isOpenInsert = false;
            })
        } else {
            console.log(dsProjectActivity.validator.errors)
        }
    }

    @action.bound
    onCancelActivity() {
        const {rootStore: {projectStore: {dsProjectActivity}}} = this.props;
        dsProjectActivity.cancel();
        this.isOpenInsert = false;
    }

    renderButton = (icon: IconName, text: ILangKeys, type: ProjectActivityTyp, i: string | number) => {
        const {rootStore: {langStore: {lang}}} = this.props;
        return (
            <Col sm={6} key={i}>
                <IconButton
                    color="primary"
                    style={{width: '100%', marginBottom: '1rem'}}
                    onClick={() => {
                        this.onOkActivityType(type)
                    }}
                >
                    <div style={{display: 'flex'}}>
                        <div style={{flex: ' 0 0 2rem'}}>
                            <FontAwesomeIcon icon={["fad", icon]}/>
                        </div>
                        <div style={{flex: ' 1 1 auto'}}>
                            {lang[text]}
                        </div>

                    </div>
                </IconButton>
            </Col>
        )
    }


    render() {
        const {rootStore: {langStore: {lang}, authStore: {hasModuleRight}}, ds} = this.props;
        return (
            <>
                <IconButton
                    id={"btn-insert"}
                    color="primary"
                    onClick={this.onClick}>
                    <FontAwesomeIcon icon="plus"/>
                </IconButton>
                <UncontrolledTooltip placement="bottom-end" target={"btn-insert"}>
                    {lang.ADD}
                </UncontrolledTooltip>
                <Dialog
                    title={lang.PROJECTTASK_TYP}
                    isOpen={this.isOpenSelect}
                    onCancel={this.onCancel}
                    onOk={this.onOkActivityType}
                    hideOk={true}
                >
                    <Row>
                        {ActivityTypeDefs.map((activityp, i) => {
                                return activityp.moduleName === '' || hasModuleRight(activityp.moduleName) ?
                                    this.renderButton(activityp.icon, activityp.moduleText, activityp.activityTyp, i) :
                                    null
                            }
                        )}
                    </Row>
                </Dialog>
                <Dialog
                    title={lang.ADD + ' : ' + lang[projectActivityTypText(this.activityTyp)]}
                    isOpen={this.isOpenInsert}
                    onCancel={this.onCancelActivity}
                    onOk={this.onOk}
                >
                    <DbForm ds={ds} doOK={this.onOk} formMode={FormMode.insert} colCount={1}/>
                    {ds.errorMessage ?
                        <Alert color="danger">
                            {ds.errorMessage}
                        </Alert>
                        :
                        null}

                </Dialog>
            </>
        )
    }
}