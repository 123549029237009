import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {TableForm} from "../../_base/styled/table-form";
import {TableLayout} from "../../_base/styled/layout/table-layout";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";

@inject('rootStore')
@observer
export class ProductDetailView extends React.Component<IBaseView, {}> {

    /*gotoCustomer = () => {
        let {rootStore: {routerStore, productStore: {dsProductCustomer}}} = this.props;
        routerStore.goTo('customer', {'customerno': dsProductCustomer.actual.customerno});
    };

    gotoSupplier = () => {
        let {rootStore: {routerStore, productStore: {dsProductSupplier}}} = this.props;
        routerStore.goTo('supplier', {'supplierno': dsProductSupplier.actual.supplierno});
    };*/

    render() {
        const {rootStore: {productStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <div style={{maxHeight: '50%'}}>
                    <TableForm
                        ds={ds}
                        icon="database"
                        title="PRODUCT"
                        hideMenu={!hasUserRight(UsergroupRight.UR_BASE_PRODUCT_EDIT)}
                    />
                </div>
            </TableLayout>
        )
    }
}

