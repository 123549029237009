import React from "react";
import {inject, observer} from "mobx-react";
import {Col, Row} from "reactstrap";
import {IDashboardProjectCard} from "../dashboard-project-card";
import {DashboardGembaChart} from "./dashboard-gemba-chart";
import {action} from "mobx";
import {DashboardTaskStatus} from "../task/dashboard-task-status";


@inject('rootStore')
@observer
export class DashboardGemba extends React.Component<IDashboardProjectCard, {}> {

    @action.bound
    gotoGemba(gembano: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('gemba', {gembano: gembano});
        }
    }

    @action.bound
    gotoTasks(projectno: string, activityno: string) {
        const {rootStore: {routerStore}} = this.props;
        return async () => {
            await routerStore.goTo('projectactivityactiontable', {projectno: projectno, activityno: activityno});
        }
    }

    /**
     * Maxwert der Y-Skala berechnen.
     */
    maxY = () => {
        const {projectStatus} = this.props;
        return projectStatus.gemba.reduce((prev: number, current) => {
            prev = current.positive > prev ? current.positive : prev;
            prev = current.negative > prev ? current.negative : prev;
            return prev
        }, 0)
    }

    render() {
        const {projectStatus} = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>
                            <hr style={{margin: '0.25rem 0 '}}/>
                            Gemba
                        </div>
                    </Col>
                </Row>
                {projectStatus.gemba.map((gemba, index: number) =>
                        <Row key={index}>
                            <Col md={8} style={{paddingTop: '1rem'}}>
                                <div style={{display: 'block', width: '100%', height: '100px'}}>
                                    <DashboardGembaChart gemba={gemba} maxY={this.maxY()}/>
                                </div>
                                <div className="link" onClick={this.gotoGemba(gemba.gembano)}>
                                    {gemba.gemba}
                                </div>
                            </Col>
                            <Col md={4} style={{paddingTop: '1rem', paddingLeft: 0}}>
                                <div style={{display: 'block', height: '100px', width: '100%'}}>
                                    <DashboardTaskStatus
                                        projectno={projectStatus.project.projectno}
                                        activityno={gemba.gembano}
                                        catValues={gemba?.actionByStatus}
                                    />
                                </div>
                                <div
                                    className="link"
                                    onClick={this.gotoTasks(projectStatus.project.projectno, gemba.activity.activityno)}
                                >
                                    {gemba?.actions.length}&nbsp;Tasks
                                </div>
                            </Col>
                        </Row>
                )}
            </>
        )
    }
}