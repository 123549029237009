import styled from "styled-components";


export const IconButton = styled.button.attrs(props => ({
    color: props.color || props.theme.color
}))`
  margin-left: 0.25rem;
  border: 0;
  background-color: ${props => props.theme.bgBody};
  color: ${props => props.theme.color};
  min-width: 2rem;
  height: 2rem;
  border-radius: ${props => props.theme.borderRadius};
  border: ${props => props.theme.border};

  :hover {
    background-color: ${props => props.theme.color};
    color: ${props => props.theme.bgBody};
  }

  :focus {
    outline: none;
    border: 0;
  }
`