import {IBaseView} from "../../../../_base/base-view";
import React from "react";
import Dropzone from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {lighten} from "polished";
import {cl_blue} from "../../../../_theme/colors";
import {inject, observer} from "mobx-react";

export const DropArea = styled.div`
  margin: 1rem;
  padding: 1rem;
  background-color: ${lighten(0.6, cl_blue)};
  border-radius: 0.65rem;
  border-style: dashed;
  border-width: 2px;
  border-color: ${cl_blue};
  text-align: center;
  font-weight: bold;
`


interface IMenuFileUpload extends IBaseView {
    id: string;
    disabled?: boolean;
    onDrop: (acceptFile: any[]) => Promise<any>;
    accept?: string;
}

@inject('rootStore')
@observer
export class DropUploadArea extends React.Component<IMenuFileUpload, {}> {

    onClick = () => {
    };


    render() {
        const {id, disabled, onDrop, accept} = this.props;
        return (
            <div style={{display: 'inline-block', width: '100%'}}>
                <Dropzone onDrop={onDrop} disabled={false} accept={accept}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} >
                            <input {...getInputProps()} />
                            <DropArea id={id} onClick={disabled ? undefined : this.onClick}>
                                <FontAwesomeIcon icon={['fad', 'file-upload']} size={'3x'} color={cl_blue}/>
                                <br/>
                                Drop file here or click to upload.
                                <br/>
                                <em>(Only *.pdf files will be accepted)</em>
                            </DropArea>
                        </div>
                    )}
                </Dropzone>
            </div>
        )
    }
}