import * as React from 'react';


// Nur noch in Inspection
export class PrintContainer extends React.Component<any, any> {
    render() {
        return (
            <div style={{padding: '0rem', display: 'block'}}>
                {this.props.children}
            </div>
        )
    }
}