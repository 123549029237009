import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {action} from "mobx";
import {TreeView} from "./tree-view";
import {DetailLayout} from "../../_base/styled/layout/detail-layout";
import {TableGrid} from "../../_base/styled/table-grid";
import {TableForm} from "../../_base/styled/table-form";
import {TableCard} from "../../_base/styled/table-card";


@inject('rootStore')
@observer
export class UsergroupDetailView extends React.Component<IBaseView, {}> {

    @action.bound
    toggleRight() {
        const {rootStore: {usergroupStore: {dsUsergroupRight}}} = this.props;
        dsUsergroupRight.edit();
        dsUsergroupRight.actual.enabled = !dsUsergroupRight.actual.enabled;
        dsUsergroupRight.post();
    }


    render() {
        const {rootStore: {usergroupStore: {ds, dsUser, treeNodes, setRight}}} = this.props;
        return (
            <DetailLayout
                topElement={
                    <TableForm
                        icon="users"
                        title='USERGROUP'
                        ds={ds}
                        hideMenu={true}
                    />}

                mainLeftElement={
                    <TableCard
                        title='USERGROUPRIGHTS'
                        icon="key"
                    >
                        <TreeView nodes={treeNodes} setRight={setRight}
                                  readOnly={ds.actual?.usergroupno === 'Admin'}/>
                    </TableCard>
                }

                mainRightElement={
                    <TableGrid
                        icon="user"
                        id="user"
                        title='USERS'
                        ds={dsUser}
                        height={200}
                        hideInsert={true}
                        hideDelete={true}
                    />
                }
            />
        )
    }

}