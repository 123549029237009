import * as React from "react";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../../_base/base-view";
import styled from "styled-components";
import {cl_blue, cl_green, cl_red} from "../../../../_theme/colors";
import {Col, Row} from "reactstrap";
import {IReportImage, ReportImages} from "../../../../_shared/report/report-Images";


const Block = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  color: black;
  border: solid 3px ${cl_blue};
  border-radius: 0.5rem;
  padding-top: 1rem;
  padding-left: 0;
  padding-right: 0;
`;

const Headline = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;


`;

const HeadLeft = styled.div`
  flex: 0 0 85%;
  background-color: ${cl_blue};
  color: white;
  border-radius: 0 0.5rem 0.5rem 0;
  padding-left: 0.5rem;
`;

const posNegBg = (st: string) => {
    if (st === 'positive') {
        return cl_green
    }
    if (st === 'negative') {
        return cl_red
    }
    return cl_blue
}


const _HeadRight = ({children, className}: any) =>
    <div className={className}>
        {children}
    </div>

const HeadRight = styled(_HeadRight)`
  display: block;
  flex: 1 1 auto;
  background-color: ${props => props.bg};
  color: white;
  border-radius: 0.5rem 0 0 0.5rem;
  margin-left: 1rem;
  padding-right: 0.5rem;
  text-align: center;

  h5 {
    margin-top: 0.5rem;
  }
`;


@inject('rootStore')
@observer
export class GembaReportPage3 extends React.Component<IBaseView, {}> {

    calcReportImages = (findingno?: string): IReportImage[] => {
        const {rootStore: {gembaStore: {dsReportImages}}} = this.props;
        if (findingno) {
            return dsReportImages
                .data
                .filter((image) => image.findingno === findingno)
                .map(image => {
                    return {
                        src: '/gridApi/image/gembafindingimage/' + image.gembano + '/' + image.findingno + '/' + image.image,
                        title: image.image
                    }
                })
        } else {
            return []
        }
    }


    render() {
        const {rootStore: {gembaStore: {dsGembaFinding}}} = this.props;
        return (<div>
            {dsGembaFinding.data.map((finding, index) =>
                <Row key={index} style={{paddingTop: '0.5rem', pageBreakBefore: 'auto', pageBreakInside: 'avoid'}}>
                    <Col>
                        <Block>
                            <h5 style={{paddingLeft: '0.5rem', fontWeight: 300}}>
                                {finding.area}
                                {finding.keyword ?
                                    <>
                                        &nbsp;
                                        <span style={{color: cl_blue, fontWeight: 600}}>&gt;</span>
                                        &nbsp;
                                        {finding.keyword}
                                    </> :
                                    null}
                            </h5>
                            <Headline>
                                <HeadLeft>
                                    <h5 style={{marginTop: 8, fontWeight: 500}}>{finding.finding}</h5>
                                </HeadLeft>
                                <HeadRight bg={posNegBg(finding.posneg)}>
                                    <h5>{finding.posneg ? finding.posneg : '\xa0'}</h5>
                                </HeadRight>
                            </Headline>
                            <ReportImages images={this.calcReportImages(finding.findingno)}/>
                        </Block>
                    </Col>
                </Row>
            )}
        </div>)
    }

}