import * as React from 'react';
import {Alert} from "reactstrap";
import {DbForm, FormMode} from "../../../components/dbform/db-form";
import {Dialog} from "../../../components/dialog/Dialog";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IRootStore} from "../../../routes/root-store";
import {IDataset} from "../../../components/dataset/IDataset";
import {IconButton} from "../styled/icon-button";
import {UncontrolledTooltip} from 'reactstrap';

interface IMessage {
    message: string;
}

const Message = ({message}: IMessage) =>
    message ?
        <Alert color="danger">
            {message}
        </Alert> : null;

interface IMenuInsert {
    rootStore?: IRootStore;
    id: string;
    text?: string;
    ds: IDataset<any>;
    disabled?: boolean;
    onBeforeInsert?: () => void;
    onAfterInsert?: () => void;
    onAfterOk?: () => void;
    hideDialog?: Boolean;
}


@inject('rootStore')
@observer
export class MenuInsert extends React.Component<IMenuInsert, {}> {


    @observable
    isopen: boolean = false;

    @action.bound
    toggler() {
        this.isopen = !this.isopen;
    }


    onCancel = () => {
        let {ds} = this.props;
        ds.cancel();
        this.toggler();
    };

    onOk = async () => {
        let {ds, onAfterOk} = this.props;
        if (ds.validator.check(ds.actual)) {
            await ds.post();
            this.toggler();
            if (onAfterOk) {
                onAfterOk();
            }
        }
    };


    onItemClick = () => {
        let {ds, onAfterInsert, onBeforeInsert, hideDialog} = this.props;
        if (onBeforeInsert) {
            onBeforeInsert();
        }
        ds.insert();
        if (onAfterInsert) {
            onAfterInsert();
        }
        if (hideDialog) {
            ds.post();
        } else {
            this.toggler();
        }
    };


    render() {
        const {rootStore: {langStore: {lang}}, ds, text, id, disabled} = this.props;
        return (
            <>
                <IconButton
                    id={id + "btn-insert"}
                    onClick={disabled ? undefined : this.onItemClick}
                    disabled={disabled}
                >
                    {text ? text : <FontAwesomeIcon icon={["fas", "plus"]}/>}
                </IconButton>
                {text ?
                    null :
                    <UncontrolledTooltip placement="bottom-end" target={id + "btn-insert"}>
                        {lang.ADD}
                    </UncontrolledTooltip>
                }
                <Dialog
                    title={lang.ADD}
                    isOpen={this.isopen}
                    onCancel={this.onCancel}
                    onOk={this.onOk}>
                    <DbForm ds={ds} doOK={this.onOk} formMode={FormMode.insert} colCount={1}/>
                    <Message message={ds.errorMessage}/>
                </Dialog>
            </>
        )
    }

}