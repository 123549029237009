import * as React from 'react';
import {ILangKeys} from "../../../language/ILang";
import {Card} from "reactstrap";
import {inject, observer} from "mobx-react";
import {BaseCardTabGrid} from "../tab/base-card-tab-grid";
import {IDataset} from "../../../components/dataset/IDataset";
import {IRouteNames} from "../../../routes/route-names";
import {BaseCardHeader} from "../card/base-card-header";
import styled from "styled-components";
import {IBaseView} from "../base-view";
import {GridBody} from "./grid-body";

interface IBaseCardGrid extends IBaseView{
    id: string;
    title: ILangKeys;
    ds: IDataset<any>;
    singleRouteName?: IRouteNames;
    onRowDoubleClicked?: any;
    menu?: any;
    filterFields?: string[];
    height?: number;
    disabled?: boolean;
    showBackButton?: boolean;
    hideInsert?: boolean;
    hideDelete?: boolean;
    rightHeaderMenu?: any;
}


@inject('rootStore')
@observer
class _BaseCardGrid extends React.Component<IBaseCardGrid, {}> {

    // per default switch to singeRoutePage
    onDefaultRowDoubleClicked = async () => {
        let {rootStore: {routerStore}, ds, singleRouteName} = this.props;
        if (singleRouteName) {
            await routerStore.goTo(singleRouteName, ds.pkValues);
        }
    };


    render() {
        const {id, ds, height, onRowDoubleClicked,  menu,disabled, hideInsert, hideDelete, title, rightHeaderMenu, showBackButton,className} = this.props;
        return (
                    <Card className={className}>
                        <BaseCardHeader title={title} rightHeaderMenu={rightHeaderMenu}
                                        showBackButton={showBackButton}/>
                        <GridBody >
                            <BaseCardTabGrid
                                id={id}
                                ds={ds}
                                height={height}
                                onRowDoubleClicked={onRowDoubleClicked ? onRowDoubleClicked : this.onDefaultRowDoubleClicked}
                                disabled={disabled}
                                hideInsert={hideInsert}
                                hideDelete={hideDelete}
                                menu={menu}
                            />
                        </GridBody>
                    </Card>
        )
    }
}

export const BaseCardGrid=styled(_BaseCardGrid)`
  color: black;
  position: relative;
  height: 100%;
  overflow: auto;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.45);
  border-radius:${props=>props.theme.cardRadius};
  border: 0;
`;


