import {IField} from "../components/dataset/IField";
import {iconRenderer} from "./_helper/icon-renderer";
import {cl_gray600, cl_green, cl_red} from "../views/_theme/colors";
import {enumToArray} from "../components/lib/enumToArray";
import {catalogDetailColumns} from "./CatalogDetail";
import {UsergroupRight} from "./rights/enum-usergrouprights";
import {ILangKeys} from "../language/ILang";

export enum InspectionScore {
    NOT_APPLICABLE = -2,
    UNRATED = -1,
    NO_COMPLIANCE = 0,
    FULL_COMPLIANCE = 10
}


export interface IInspectionQuestion {
    inspectionno: string;
    questionno: string;
    question: string;
    mandatory: boolean;
    requirements: string;
    answer: string;
    evidence: string;
    score: number;
    area: string;
    element: string;
    notapplicable: boolean;
    extra01: string;
    extra02: string;
}

const cellStyleScore = function (params: any) {
    switch (params.value) {
        case InspectionScore.NOT_APPLICABLE:
            return {backgroundColor: cl_gray600, color: 'white', textAlign: 'center'};
        case InspectionScore.UNRATED:
            return {backgroundColor: 'white', color: 'black', textAlign: 'center'};
        case InspectionScore.NO_COMPLIANCE:
            return {backgroundColor: cl_red, color: 'white', textAlign: 'center'};
        case InspectionScore.FULL_COMPLIANCE:
            return {backgroundColor: cl_green, color: 'white', textAlign: 'center'};
        default:
            return {backgroundColor: 'white', color: 'black', textAlign: 'center'};
    }
};


export const scoreText = (score: InspectionScore): ILangKeys => {
    switch (score) {
        case InspectionScore.NOT_APPLICABLE:
            return 'AUDIT_RATING_NA';
        case InspectionScore.UNRATED:
            return '';
        case InspectionScore.NO_COMPLIANCE:
            return 'NOK';
        case InspectionScore.FULL_COMPLIANCE:
            return 'OK';
        default:
            return 'Unknown';
    }
};

/*
FairWind Kataloge
 */


export const inspectionQuestionColumns: IField<IInspectionQuestion>[] = [
    {
        fieldName: 'inspectionno',
        dataType: 'string',
        label: 'INSPECTION_INSPECTIONNO',
        rules: ['pk'],
        primaryKey: true,
        insert: 'hide',
        hide: 'table'
    },
    {
        fieldName: 'questionno',
        label: 'AUDIT_QUESTIONNO',
        dataType: 'string',
        primaryKey: true,
        rules: ['pk'],
        grid: {
            width: 30,
        }
    },
    {
        fieldName: 'area',
        label: 'AUDIT_AREA',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'element',
        label: 'AUDIT_ELEMENT',
        dataType: 'string',
        maxLength: 80,
        insert: 'show',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'question',
        dataType: 'string',
        label: 'AUDIT_QUESTION',
        insert: 'show',
        input: 'textarea',
        maxLength: 1024
    },
    {
        fieldName: 'mandatory',
        label: 'AUDIT_MANDATORY',
        dataType: 'boolean',
        input: 'checkbox',
        hide: 'table',
        grid: {
            width: 40,
            cellRenderer: (params: any) => params.value ? iconRenderer("exclamation", undefined, cl_red) : ''
        }
    },
    {
        fieldName: 'requirements',
        label: 'AUDIT_REQUIREMENTS',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        insert: 'show',
        maxLength: 1024
    },

    {
        fieldName: 'answer',
        label: 'AUDIT_ANSWER',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        maxLength: 1024
    },
    {
        fieldName: 'evidence',
        label: 'AUDIT_EVIDENCE',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        maxLength: 1024
    },
    {
        fieldName: 'score',
        dataType: 'number',
        label: 'AUDIT_SCORE',
        input: 'selectradio',
        options: enumToArray(InspectionScore),
        textFunc: scoreText,
        defaultValue: -1,
        useAsFilter: true,
        grid: {
            width: 40,
            cellStyle: cellStyleScore
        }
    },

    {
        fieldName: 'notapplicable',
        dataType: 'boolean',
        hide: 'always',
    },
    {
        fieldName: 'extra01',
        dataType: 'string',
        hide: 'always',
        input: 'selectdlg',
        label: 'AUDIT_QUESTION_EXTRA01',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10001',
            columns: catalogDetailColumns,
            field: 'description1',
            InserRight: UsergroupRight.UR_BASE_CATALOG_INSERT
        }
    },
    {
        fieldName: 'extra02',
        dataType: 'string',
        hide: 'always',
        input: 'selectdlg',
        label: 'AUDIT_QUESTION_EXTRA02',
        selectdlg: {
            url: '/gridApi/catalogdetail/?catalogno=10002',
            columns: catalogDetailColumns,
            field: 'description1',
            InserRight: UsergroupRight.UR_BASE_CATALOG_INSERT
        }
    }

];