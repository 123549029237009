import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";
import {Button} from "reactstrap";
import {action} from "mobx";

@inject('rootStore')
@observer
export class HeaderLogin extends React.Component<IBaseView, {}> {

    @action.bound
    goToLogin = () => {
        const {rootStore: {routerStore}} = this.props;
        routerStore.goTo('login');
    };

    render() {
        const {rootStore: {langStore: {lang}}} = this.props;
        return (
            <Button
                className="header-user"
                onClick={this.goToLogin}>
                {lang.LOGIN}&nbsp;&nbsp;<FontAwesomeIcon icon="sign-in-alt"/>
            </Button>
        );
    }
}