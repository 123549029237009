import {IField} from "../components/dataset/IField";


export interface ICatalogDetail{
    catalogno:string;
    entryno:string;
    description1:string;
    rating:number;
}

export const catalogDetailColumns:IField<ICatalogDetail>[]=[
    {
        fieldName: 'catalogno',
        dataType: 'string',
        label: 'DESCAT_CATALOGNO',
        primaryKey: true,
        rules:['pk'],
        hide:'table',
        insert:'hide'
    },
    {
        fieldName: 'entryno',
        dataType: 'string',
        label: 'DESCATD_ENTRYNO',
        primaryKey: true,
        rules:['pk'],
    },
    {
        fieldName: 'description1',
        dataType: 'string',
        label: 'DESCAT_DESCRIPTON1',
        insert:'show'
    },
    {
        fieldName: 'rating',
        dataType: 'number',
        label: 'DESCAT_RATING',
        rules:['number'],
        hide:'always',
        defaultValue: 0


    },
];