import {action, computed, observable} from "mobx";
import {IRootStore} from "../routes/root-store";
import {IDataset} from "../components/dataset/IDataset";
import {IProject} from "../models/Project";


export interface IAppStore {
    sidebarOpen: boolean;
    navigate: (toRoute: string) => void;
    toggleSidebar: () => void;
    closeSidebar: () => void;
    openSidebar: () => void;
    headerTitle: string;
}


export class AppStore implements IAppStore {

    rootStore: IRootStore;

    @observable
    sidebarOpen: boolean = false;


    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;


    }


    @action.bound
    navigate(toRoute: string) {
        this.rootStore.routerStore.goTo(toRoute);

    }

    // schließ die Sidebar und aller UnterMenüs
    @action.bound
    closeSidebar() {
        // Ist das ein guter Construct?
        const {authStore: {sidebarRoutes}} = this.rootStore;
        sidebarRoutes.forEach((route) => {
            route.isOpen = false;
        });
        this.sidebarOpen = false;
    }

    @action.bound
    openSidebar() {
        this.sidebarOpen = true;
    }

    @action.bound
    toggleSidebar() {
        if (this.sidebarOpen) {
            this.closeSidebar();
        } else {
            this.openSidebar()
        }
    }


    projectHeaderTitle = (dsProject: IDataset<IProject>) => {
        let rv1 = dsProject.actual?.projectno ?? ""
        let rv2 = dsProject.actual?.project ?? ""
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : rv1
    }

    projectHeaderOfflineTitle = (projectno: string, project: string) => {
        return projectno + ' - ' + project;
    }

    /**
     *  Returns the translated HeaderTitle based on current RouterState
     */
    @computed
    get headerTitle(): string {
        const {routerStore: {routerState: {routeName}}, langStore: {lang}} = this.rootStore;
        switch (routeName) {
            case '__initial__':
                return ''
            case 'home':
                return lang['HOME'];
            case 'login':
            case 'auth_request_password_link':
            case 'auth_password_change':
                return 'qp|squid';
            //
            case 'configuration':
                return lang['CONFIGURATION'];
            case 'logbooktable':
                return lang['LOGBOOK'];
            //
            case 'usergrouptable':
            case 'usergroup':
            case 'usertable':
            case 'user':
                return lang['ADMINISTRATION'];
            //
            case 'producttable':
            case 'product':
            case 'suppliertable':
            case 'supplier':
            case 'customertable':
            case 'customer':
            case 'catalogtable':
            case 'catalog':
            case 'catalogentry':
                return lang['BASEDATA'];
            //
            case 'projecttable':
            case 'project':
            case 'projecttemplatetable':
            case 'projecttemplate':
            case 'projecttemplateactivity':
            case 'projectactivity':

                return lang['PROJECTMANAGEMENT'];
            //
            case 'activitytable':
                return lang['OFFLINE_ACTIVITIES']
            case 'ppapordertable':
            case 'ppapstandardtable':
            case 'ppapstandardelement':
            case 'ppapstandard':
                return lang['PPAP'];
            case 'ppaporder':
            case 'ppapreport':
                return lang['PROJECT'] + ' ' + this.projectHeaderTitle(this.rootStore.ppapStore.dsProject);
            //
            case 'audittable':
            case 'audittemplatetable':
            case 'audittemplate':
                return lang['AUDIT'];
            case 'audit':
            case 'auditreport':
            case 'auditcollectonline':
            case 'auditcollectcollectonline':
                return lang['PROJECT'] + ' ' + this.projectHeaderTitle(this.rootStore.auditStore.dsProject);
            //
            case 'auditcollectoffline':
            case 'auditcollectcollectoffline':
                return lang['PROJECT'] + ' ' + this.projectHeaderOfflineTitle(this.rootStore.auditOfflineStore.auditOffline?.audit?.projectno,
                    this.rootStore.auditOfflineStore.auditOffline?.project?.project) + ' (Offline)';
            //
            case 'inspectiontable':
            case 'inspectionplantable':
            case'inspectionplan':
            case 'inspectionplanquestion':
            case 'inspection':
                return lang['INSPECTION'];
            //
            case 'gembatable':
            case 'gembatemplatetable':
            case 'gembatemplate':
                return lang['GEMBA'];
            case 'gemba':
            case 'gembareport':
                return lang['PROJECT'] + ' ' + this.projectHeaderTitle(this.rootStore.gembaStore.dsProject);
            case 'gembacollectonline':
                return lang['PROJECT'] + ' ' + this.projectHeaderTitle(this.rootStore.gembaStore.dsProject);
            //
            case 'actiontable':
            case 'projectactivityactiontable':
                return lang['ACTIONS'];
            case 'action':
                return lang['ACTION'];
            case 'settings':
                return lang['SETTINGS']
            case 'nopermission':
                return ''

            case 'mobilegemba':
                return lang['GEMBA']
            case 'mobilelist':
                return ''

            default:
                console.warn('app-store: missing header caption for: ', routeName)
                return '???'
        }

    }

}