import {Status} from "../Status";
import moment from "moment";
import {cl_red, cl_yellow} from "../../views/_theme/colors";


export const dateCellStyle = function (params: any) {
    if (moment(params.value).diff(new Date(), 'days') <= 0 && params.data.status !== Status.COMPLETED && params.data.status !== Status.APPROVED) {
        return {backgroundColor: cl_red, color: 'white'}
    } else if ((moment(params.value, "YYYY-MM-DD").diff(new Date(), 'days') < 7 && moment(params.value).diff(new Date(), 'days') >= 1) && params.data.status !== Status.COMPLETED && params.data.status !== Status.APPROVED) {
        return {backgroundColor: cl_yellow, color: 'white'}
    }

};

