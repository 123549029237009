import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IPpapOrderElement} from "../../../../models/PpapOrderElement";
import {IBaseView} from "../../../_base/base-view";
import {PPAP_DOC} from "../../../../models/PpapStandardElement";
import {action} from "mobx";


export interface IPpapElementCheckbox extends IBaseView {
    element: IPpapOrderElement;
}


export const PpapElementCheckbox = inject('rootStore')(observer((props: IPpapElementCheckbox) => {
    const {rootStore: {ppapStore: {toggleElement}}, element} = props;
    return (
        <div>
            <input
                type="checkbox"
                id={element.elementno.toString()}
                name={element.element}
                checked={element.doc === PPAP_DOC.MANDATORY}
                onChange={
                    action(() => {
                            element.doc = element.doc === PPAP_DOC.MANDATORY ? PPAP_DOC.OPTIONAL : PPAP_DOC.MANDATORY;
                            toggleElement(element.ppapno, element.elementno, element.doc);
                        }
                    )}
            />
            <label htmlFor={element.elementno.toString()}>&nbsp;&nbsp;{element.element}</label>
        </div>
    )
}))