import {IField} from "../components/dataset/IField";
import {iconRenderer} from "./_helper/icon-renderer";
import {cl_red} from "../views/_theme/colors";


export interface IAuditTemplateQuestion{
    audittemplate: string;
    questionno: string;
    question: string;
    mandatory: boolean;
    reference: string;
    requirements: string;
    area: string;
    element: string;
    level01: string;
    level02: string;
    level03: string;
    level04: string;
    level05: string;
    unselected: boolean;
    attentionflag: boolean;
    chapter: string;
    weighting: number;

}


export const auditTemplateQuestionColumns:IField<IAuditTemplateQuestion>[]=[
    {
        fieldName:'audittemplate',
        label:'AUDIT_TEMPLATE_ID',
        dataType: 'string',
        primaryKey: true,
        insert: 'hide',
        hide: 'table',
        rules:['pk'],
    },
    {
        fieldName:'questionno',
        label:'AUDIT_QUESTIONNO',
        dataType: 'string',
        primaryKey: true,
        rules:['pk'],
    },
    {
        fieldName:'area',
        label:'AUDIT_AREA',
        dataType:'string',
        insert:'show'
    },
    {
        fieldName:'element',
        label:'AUDIT_ELEMENT',
        dataType:'string',
        insert:'show'
    },
    {
        fieldName:'question',
        label:'AUDIT_QUESTION',
        dataType: 'string',
        input: 'textarea',
        insert: 'show',
        maxLength: 1024
    },
    {
        fieldName: 'mandatory',
        label:'AUDIT_MANDATORY',
        dataType: 'boolean',
        input: 'checkbox',
        grid: {
            width: 40,
            cellRenderer: (params: any) => params.value ? iconRenderer("exclamation", undefined, cl_red) : ''
        }
    },
    {
        fieldName: 'requirements',
        label:'AUDIT_REQUIREMENTS',
        dataType: 'string',
        input: 'textarea',
        hide: 'table',
        insert: 'show',
        maxLength: 1024
    },
    {
        fieldName: 'reference',
        label: 'AUDIT_REFERENCE',
        dataType: 'string',
        insert: 'show'
    },
    {
        fieldName: 'level01',
        label: 'AUDIT_QUESTION_LEVEL01',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level02',
        label: 'AUDIT_QUESTION_LEVEL02',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level03',
        label: 'AUDIT_QUESTION_LEVEL03',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level04',
        label: 'AUDIT_QUESTION_LEVEL04',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'level05',
        label: 'AUDIT_QUESTION_LEVEL05',
        dataType: 'string',
        input: 'textarea',
        rows: 4,
        hide: 'table',
    },
    {
        fieldName: 'unselected',
        label: 'AUDIT_QUESTION_UNSELECTED',
        dataType: 'boolean',
        input: 'checkbox',
    },
    {
        fieldName: 'attentionflag',
        label: 'AUDIT_QUESTION_ATTENTIONFLAG',
        dataType: 'boolean',
        input: 'checkbox',
    },
    {
        fieldName: 'chapter',
        label: 'AUDIT_QUESTION_CHAPTER',
        dataType: 'string',
    },
    {
        fieldName: 'weighting',
        label: 'AUDIT_QUESTION_WEIGHTING',
        dataType: 'number',
    },


];