import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../../_base/base-view";
import {AuditSelectArea} from "./audit-select-area";
import {AuditSelectScoreFilter} from "./audit-select-score-filter";
import {Status} from "../../../../../models/Status";


@inject('rootStore')
@observer
export class AuditDetailMainLeftMenu extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {auditStore: {ds}, authStore: {username}}} = this.props;
        return (
            <div style={{display: 'inline-flex'}}>
                {(username === ds.actual?.leadauditor || username === ds.actual?.coauditor01) && ds.actual.status !== Status.APPROVED ?
                    <AuditSelectArea/> : null}
                <AuditSelectScoreFilter/>
            </div>
        )
    }

}



