import * as React from 'react';
import {IFieldProps} from "../i-db-field-props";
import {inject, observer} from "mobx-react";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {action, observable} from "mobx";
import {Dialog} from "../../dialog/Dialog";
import {FieldSelectRadio} from "./field-select-radio";

@inject('rootStore')
@observer
export class FieldCatalogBtn extends React.Component<IFieldProps<any>, any> {

    @observable
    isDialogOpen: boolean = false;


    @action.bound
    onOpenClick() {
        this.isDialogOpen = true;
    }

    @action.bound
    onCancelClick() {
        this.isDialogOpen = false;
    }

    @action.bound
    onOkClick() {
        let {onChanged} = this.props;
        this.isDialogOpen = false;
        if (onChanged) {
            onChanged();
        }
    }

    render() {
        let {readOnly, actual, column, optionsFunc} = this.props;
        return (
            <>
                <Button
                    onClick={this.onOpenClick}
                    disabled={readOnly}
                    size="sm">
                    <FontAwesomeIcon icon="caret-down"/>
                </Button>
                <Dialog
                    title='Dataselect'
                    isOpen={this.isDialogOpen}
                    onCancel={this.onCancelClick}
                    onOk={this.onOkClick}
                >
                    <div>
                        <FieldSelectRadio actual={actual} column={column} optionsFunc={optionsFunc}/>
                    </div>
                </Dialog>
            </>
        )
    }

}