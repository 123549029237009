import {IconButton} from "../styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {IBaseView} from "../base-view";
import {CSVDownloader, jsonToCSV} from "react-papaparse";
import {IDataset} from "../../../components/dataset/IDataset";
import {inject, observer} from "mobx-react";
import {UncontrolledTooltip} from "reactstrap";


interface ICsvButtonDownload extends IBaseView {
    filename?: string;
    ds: IDataset<any>;
}

@inject('rootStore')
@observer
export class CsvButtonDownload extends React.Component <ICsvButtonDownload, {}> {


    render() {
        const {filename, ds, rootStore: {langStore: {lang}}} = this.props;
        return (
            <CSVDownloader
                data={jsonToCSV(ds.data, {delimiter: ";"})}
                filename={filename ?? 'export.csv'}
                bom={true}
            >
                <>
                    <IconButton
                        id="id_csv_download"
                        type='button'
                    >
                        <FontAwesomeIcon icon={["fas", "file-export"]}/>
                    </IconButton>
                    <UncontrolledTooltip placement="bottom-end" target="id_csv_download">
                        {lang.EXPORT}
                    </UncontrolledTooltip>
                </>
            </CSVDownloader>
        )
    }
}