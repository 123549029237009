import * as R from "ramda";


// Aus irgend welchen Gründen werden die Params nicht korrekt decodiert.
// hier wird ein explicites decodeURIComponent für jeden Parameter ausgeführt.
// bis Bug in mobx-state-router gefixed ist.
// https://github.com/nareshbhatia/mobx-state-router/issues/72


export const encodeParams = (params: any) => {
    if (params) {
        let rv = R.clone(params);
        Object.keys(rv)
            .forEach(p => rv[p] = encodeURIComponent(rv[p]));
        //console.log(rv)
        return rv;
    }
    return {};
};


export const decodeParams = (params: any) => {
    if (params) {
        let rv = R.clone(params);
        Object.keys(rv)
            .forEach(p => rv[p] = decodeURIComponent(rv[p]));
        return rv;
    }
    return {};
};