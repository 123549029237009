import * as React from "react";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {DetailLayout} from "../../_base/styled/layout/detail-layout";
import {PpapOrderLeftView} from "./left/ppap-order-left-view";
import {PpapOrderTopView} from "./top/ppap-order-top-view";
import {PpapOrderRightView} from "./right/ppap-order-right-view";


@inject('rootStore')
@observer
export class PpapOrderDetailView extends React.Component<IBaseView, any> {
    render() {
        return (
            <DetailLayout
                topElement={<PpapOrderTopView/>}
                mainLeftElement={<PpapOrderLeftView/>}
                mainRightElement={<PpapOrderRightView/>}
            />
        )
    }

}

