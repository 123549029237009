import {IField} from "../components/dataset/IField";
import {supplierColumns} from "./Supplier";
import {UsergroupRight} from "./rights/enum-usergrouprights";

export interface IProductSupplier {
    productno: string;
    supplierno: string;
}


export const productSupplierColumns: IField<IProductSupplier>[] = [
    {
        fieldName: 'productno',
        dataType: 'string',
        label: 'PRODUCT_PRODUCTNO',
        primaryKey: true,
        rules: ['pk'],
        hide: 'table',
        insert: 'hide'

    },
    {
        fieldName: 'supplierno',
        dataType: 'string',
        label: 'SUPPLIER_SUPPLIERNO',
        primaryKey: true,
        rules: ['pk'],
        insert: 'show',
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/supplier/',
            columns: supplierColumns,
            InserRight: UsergroupRight.UR_BASE_SUPPLIER_INSERT
        }
    }
];
