import {IBaseView} from "../../../_base/base-view";
import React from "react";
import {inject, observer} from "mobx-react";
import ReactEcharts from "echarts-for-react";
import {action} from "mobx";
import {IGembaDashboard} from "../../../../stores/dashboard-store";
import {cl_blue, cl_green, cl_red} from "../../../_theme/colors";


interface IGembaChart extends IBaseView {
    gemba: IGembaDashboard;
    maxY: number;
}


@inject('rootStore')
@observer
export class DashboardGembaChart extends React.Component<IGembaChart, {}> {


    @action.bound
    onClick(params: any) {
        const {gemba} = this.props;
        const {rootStore: {routerStore}} = this.props;
        if (params.componentType === 'series') {
            routerStore.goTo('gemba', {gembano: gemba.gembano})
        }
    }

    getOption = () => {
        const {gemba, /*maxY*/} = this.props;
        return {
            grid: {
                containLabel: true,
                top: 16,
                bottom: 3,
                right: 10
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    fontSize: 10,
                    fontWeight: 400,
                    color: cl_blue,
                    hideOverlap: false,
                },
                data: ['postive', 'negative']
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                // max:maxY,
                axisLine: {
                    show: true
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: true
                }
            },
            series: [
                {
                    data: [

                        {
                            value: gemba.positive,
                            itemStyle: {
                                color: cl_green,
                            }
                        },
                        {
                            value: gemba.negative,
                            itemStyle: {
                                color: cl_red
                            }
                        },

                    ],
                    type: 'bar',
                    barWidth: '60%'
                }
            ]
        }
    }

    render() {
        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{height: '100%', width: '100%'}}
                opts={{renderer: 'svg'}}
                onEvents={{'click': this.onClick}}
            />
        )
    }
}