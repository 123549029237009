import {IField} from "../components/dataset/IField";


export interface IPpapDetail02 {
    ppapno: string;
    respname: string;
    respfunction: string;
    respcompany: string;
    name01: string;
    function01: string;
    company01: string;
    name02: string;
    function02: string;
    company02: string;
    name03: string;
    function03: string;
    company03: string;
    name04: string;
    function04: string;
    company04: string;
    name05: string;
    function05: string;
    company05: string;
    name06: string;
    function06: string;
    company06: string;
    name07: string;
    function07: string;
    company07: string;
    //
    isdefined_c: string;
    isperformance_c: string;
    istolerances_c: string;
    iscpk_c: string;
    iscapacity_c: string;
    ishandling_c: string;
    iscostcapital_c: string;
    iscosttooling_c: string;
    isalternative_c: string;
    isstatrequired_c: string;
    isstatpresent_c: string;
    isstable_c: string;
    iscapability_c: string;
    //
    isdefined_ic: string;
    isperformance_ic: string;
    istolerances_ic: string;
    iscpk_ic: string;
    iscapacity_ic: string;
    ishandling_ic: string;
    iscostcapital_ic: string;
    iscosttooling_ic: string;
    isalternative_ic: string;
    isstatrequired_ic: string;
    isstatpresent_ic: string;
    isstable_ic: string;
    iscapability_ic: string;
}

export const ppapDetail02Columns: IField<IPpapDetail02> [] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDERNO',
        primaryKey: true,
        rules: ['pk', 'mandatory'],
        insert: 'hide'
    },
];