import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../_base/base-view";
import {action} from "mobx";
import {TableForm} from "../../_base/styled/table-form";
import {DetailOneColLayout} from "../../_base/styled/layout/detail-one-col-layout";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../components/dbform/db-field";
import {Logo} from "../../_base/logo/logo";
import {CardWithTab, ITab} from "../../_base/styled/card-with-tab";
import {DbGrid} from "../../../components/dbgrid/db-grid";

@inject('rootStore')
@observer
export class CustomerDetailView extends React.Component<IBaseView, {}> {

    @action.bound
    async gotoProject() {
        const {rootStore: {routerStore, customerStore: {dsProject}}} = this.props;
        await routerStore.goTo('project', {projectno: dsProject.actual.projectno});
    }

    @action.bound
    async gotoUser() {
        const {rootStore: {routerStore, customerStore: {dsUser}}} = this.props;
        await routerStore.goTo('user', {username: dsUser.actual.username});
    }

    getTabs = (): ITab[] => {
        const {rootStore: {customerStore: {dsProject, dsUser},}} = this.props;
        return [
            {
                title: 'PROJECTS',
                icon: "project-diagram",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsProject} onRowDoubleClicked={this.gotoProject}/>
                    </div>)
            },
            {
                title: 'USERS',
                icon: "user",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsUser} onRowDoubleClicked={this.gotoUser}/>
                    </div>)
            }
        ]
    }


    render() {
        const {
            rootStore: {
                customerStore: {ds, uploadIcon, uploadLogo, logoUrl, iconUrl},
                langStore: {lang}
            }
        } = this.props;


        return (
            <DetailOneColLayout
                topElement={
                    <TableForm
                        icon="database"
                        title='CUSTOMER'
                        keyTitle={ds.actual?.customerno + " - " + ds.actual?.customer1}
                        ds={ds}
                    >
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={4}>
                                        <DbField ds={ds} field="customerno"/>
                                    </Col>
                                    <Col md={8}>
                                        <DbField ds={ds} field="customer1"/>
                                    </Col>
                                </Row>
                                <DbField ds={ds} field="customer2"/>
                                <DbField ds={ds} field="street"/>
                                <Row>
                                    <Col md={2}>
                                        <DbField ds={ds} field="postcode"/>
                                    </Col>
                                    <Col md={10}>
                                        <DbField ds={ds} field="city"/>
                                    </Col>
                                </Row>
                                <DbField ds={ds} field="country"/>
                                <DbField ds={ds} field="description1"/>
                                <DbField ds={ds} field="description2"/>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Logo label="Icon (Project)" src={iconUrl} ds={ds} fieldName="icon"
                                              uploadFile={uploadIcon} lang={lang}
                                              width={32} height={32}/>
                                        <br/>
                                        <Logo label="Logo (Report)" src={logoUrl} ds={ds} fieldName="logo"
                                              uploadFile={uploadLogo} lang={lang}
                                              width={300} height={150}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TableForm>
                }
                mainElement={<CardWithTab
                    tabs={this.getTabs()}
                />}
            />

        )
    }
}