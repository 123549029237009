import * as React from 'react';
import {IFieldProps} from "../i-db-field-props";
import {inject, observer} from "mobx-react";
import {action, observable, runInAction} from "mobx";
import {ILangKeys} from "../../../language/ILang";
import {Col, FormGroup, Input, InputGroup} from "reactstrap";
import {FieldLookupdlgButton} from "./field-lookupdlg-button";
import {dsState} from "../../dataset/IDataset";
import {FieldLabel} from "../field-label";


@inject('rootStore')
@observer
export class FieldLookupdlg extends React.Component<IFieldProps<any>, {}> {


    @observable
    value: string;

    // lookup is always readonly
    @action.bound
    onChange() {

    }

    @action.bound
    async onOK() {
        const {column: {lookup: {ds, displayField}}} = this.props;
        if (ds.state !== dsState.dsInactive) {
            ds.filter = ds.getMasterFilter();
            await ds.refresh(ds.cursor);
            runInAction(() => {
                this.value = ds.actual[displayField]
            })
        }
    }

    constructor(props: IFieldProps<any>) {
        super(props);
        const {column} = this.props;
        const {ds, displayField} = column.lookup;
        this.value =
            ds ?
                ds.actual ?
                    ds.actual[displayField] !== null ?
                        ds.actual[displayField] :
                        '' :
                    '' :
                '';
    }


    renderInput() {
        const {actual, column, readOnly} = this.props;
        const {displayField} = column.lookup;
        return (
            <InputGroup>
                <Input id={displayField as string}
                       name={displayField as string}
                       type="text"
                       disabled={readOnly}
                       autoComplete="off"
                       value={this.value}
                       onChange={this.onChange}
                       bsSize="sm"

                />
                {!readOnly ?
                    /*<InputGroupAddon addonType="append">*/
                    <FieldLookupdlgButton actual={actual} column={column} readOnly={readOnly}
                                          onOK={this.onOK}/>
                    /* </InputGroupAddon>*/ :
                    null}
                {/*{error ? <FormFeedback>{lang[error.text]}</FormFeedback> : null}*/}
            </InputGroup>
        )
    }


    render() {
        const {rootStore: {langStore: {lang}}, column, row} = this.props;
        const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : column.fieldName as string;
        return (
            <FormGroup row={row}>
                <FieldLabel row={row} label={label} bold={column.primaryKey}/>
                {row ? (<Col sm={9}> {this.renderInput()}</Col>) : this.renderInput()}
            </FormGroup>
        )
    }
}

/* invalid={!!error}*/