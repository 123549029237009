import * as React from 'react';
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";
import {inject, observer} from "mobx-react";
import {IFieldProps} from "../i-db-field-props";
import {action, runInAction} from "mobx";
import axios from "axios";

@inject('rootStore')
@observer
export class FieldUploaddlgButton extends React.Component<IFieldProps<any>, {}> {

    @action.bound
    onDrop(acceptFile: any[]) {
        const {actual} = this.props;
        const {column: {fileuploaddlg: {url}}} = this.props;
        acceptFile.forEach(async (file: any) => {
            const formData = new FormData();
            formData.append('standardno', actual.standardno);
            formData.append('elementno', actual.elementno);
            formData.append('file', file);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };
                //
                await axios.post(url, formData, config);
                // UweWindt/squid#630
                //await ds.refresh(ds.cursor);
                //ds.edit();
                runInAction(() => {
                    actual.template = file.name;
                })

            }
        );
    };


    render() {

        return (
            <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps}) => {
                    return (
                        <div {...getRootProps()} >
                            <input {...getInputProps()} />
                            <Button
                                disabled={false}
                                size="sm">
                                <FontAwesomeIcon icon="file-upload"/>
                            </Button>
                        </div>
                    )
                }}
            </Dropzone>

        )
    }
}