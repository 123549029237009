import * as React from 'react';
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {ProjectActivitymenuInsert} from "./project-activity-menu-insert";
import {IconButton} from "../../../_base/styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {UncontrolledTooltip} from "reactstrap";
import {UsergroupRight} from "../../../../models/rights/enum-usergrouprights";

export interface IProjectActivityGridMenu extends IBaseView {
    children?: any;
    id: string;
}


export const ProjectActivityGridMenu = inject('rootStore')(observer((props: IProjectActivityGridMenu) => {

    const {
        rootStore:
            {
                projectStore:
                    {ds, dsProjectActivity, createDevelopment, canCreateDevelopment, canDeleteActivity},
                authStore: {username, hasUserRight},
                langStore: {lang}
            },
        id
    } = props;
    const hideInsert = !(username === ds.actual?.owner && hasUserRight(UsergroupRight.UR_PROJECT_ACTIVITY_INSERT));
    const hideDelete = !(username === ds.actual?.owner && hasUserRight(UsergroupRight.UR_PROJECT_ACTIVITY_DELETE));
    //console.log(canDeleteActivity,(!hideDelete && canDeleteActivity))
    return (
        <div className="grid-menu">
            {!hideInsert ? <ProjectActivitymenuInsert ds={dsProjectActivity}/> : null}
            {(!hideDelete && canDeleteActivity) ? <MenuDelete id={id}
                                                              ds={dsProjectActivity}
                                                              disabled={dsProjectActivity.cursor === undefined}
                /> :
                null}

            {!hideInsert ? canCreateDevelopment ?
                    <>
                        <IconButton
                            id={id + "btn-clone"}
                            onClick={() => {
                                if (canCreateDevelopment) {
                                    createDevelopment()
                                } else {


                                }
                            }}
                        >
                            <FontAwesomeIcon icon={["fad", "chart-line"]}/>
                        </IconButton>
                        <UncontrolledTooltip placement="bottom-end" target={id + "btn-clone"}>
                            {lang.PROJECT_ACTIVITY_CREATE_DEVELOPMENT}
                        </UncontrolledTooltip>
                    </> :
                    null :
                null
            }
        </div>
    )
}));