import React from "react";
import {Col, Row} from "reactstrap";
import {DashboardGanttChart} from "./dashboard-gantt-chart";
import {IDashboardProjectCard} from "../dashboard-project-card";


export class DashboardGantt extends React.Component<IDashboardProjectCard, {}> {
    render() {
        const {projectStatus} = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div style={{textAlign: 'left', fontSize: '14px', fontWeight: 500}}>
                            <hr style={{margin: '0.25rem 0 '}}/>
                            Gantt Chart
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{paddingTop: 0, paddingLeft: 0}}>
                        <div style={{display: 'block', width: '100%'}}>
                            <DashboardGanttChart
                                from={projectStatus.project.startdate}
                                to={projectStatus.project.duedate}
                                data={projectStatus.gantt.data}/>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}