import * as React from 'react';
import {IBaseView} from "../../../_base/base-view";
import moment from "moment";
import {inject, observer} from "mobx-react";
import {cl_blue} from "../../../_theme/colors";
import {computed} from "mobx";

@inject('rootStore')
@observer
export class AuditReportPage1 extends React.Component<IBaseView, {}> {

    @computed
    get logo() {
        const {rootStore: {auditStore: {dsCustomer}}} = this.props;
        //return dsSupplier.actual ? dsSupplier.actual.logo ? "/gridApi/supplierlogo/download/" + dsSupplier.actual.supplierno + "/" + dsSupplier.actual.logo : "" : "";
        //console.log(dsCustomer, toJS(dsCustomer.actual))
        return dsCustomer.actual ? dsCustomer.actual.logo ? "/gridApi/customerlogo/download/" + dsCustomer.actual.customerno + "/" + dsCustomer.actual.logo : "" : "";
    }

    render() {
        const {rootStore: {auditStore: {ds}}} = this.props;
        return (
            <>

                {[...Array(3).keys()].map((x, i) => <h1 key={i}>&nbsp;</h1>)}
                {this.logo ?
                    <img
                        style={{width: '100%', height: '200px', objectFit: 'contain'}}
                        src={this.logo}
                        alt='logo'/> :
                    null
                }
                {[...Array(2).keys()].map((x, i) => <p key={i}>&nbsp;</p>)}

                <h1 style={{textAlign: 'center'}}>Audit-Report</h1>
                {[...Array(12).keys()].map((x, i) => <p key={i}>&nbsp;</p>)}
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td><h2>Audit Number</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.auditno}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>Supplier</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.supplierno}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>Date</h2></td>
                        <td>
                            <h2 style={{color: cl_blue}}>{moment(ds.actual?.startdate).format('DD-MM-YYYY')} - {moment(ds.actual?.duedate).format('DD-MM-YYYY')}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td><h2>Lead Auditor</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.leadauditor}</h2></td>
                    </tr>
                    <tr>
                        <td><h2>Plant</h2></td>
                        <td><h2 style={{color: cl_blue}}>{ds.actual?.plant}</h2></td>
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <img
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',


                        width: '10%',
                        objectFit: 'cover'
                    }}
                    src='/img/favicon.png'
                    alt='logo'/>
            </>
        )
    }

}