import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../../_base/base-view";
import {DetailLayout} from "../../../_base/styled/layout/detail-layout";
import {TableForm} from "../../../_base/styled/table-form";
import {TableGrid} from "../../../_base/styled/table-grid";
import {CardWithTab, ITab} from "../../../_base/styled/card-with-tab";
import {DbGrid} from "../../../../components/dbgrid/db-grid";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {MenuInsert} from "../../../_base/menu/menu-insert";
import {
    AuditDetailMainRightToggleDocument
} from "../../../audit/order/order/main-right/audit-detail-main-right-toggle-document";
import {action, computed} from "mobx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton} from "../../../_base/styled/icon-button";
import {Col, Row, UncontrolledTooltip} from "reactstrap";
import {Status} from "../../../../models/Status";
import {dsState} from "../../../../components/dataset/IDataset";
import {DbField} from "../../../../components/dbform/db-field";
import {GembaDetailObservation} from "./gemba-detail-observation";
import {GembaDetailObservationMenu} from "./gemba-detail-observation-menu";


const TasksMenu = inject('rootStore')(observer((props: IBaseView) => {
    const {rootStore: {gembaStore: {ds, dsAction}, authStore: {username}}} = props;
    return (username === ds.actual?.responsible ?
            <>
                <MenuInsert id={"ACTIONS_btn-insert"} ds={dsAction}/>
                <MenuDelete id={"ACTIONS_btn-delete"} ds={dsAction}/>
                <AuditDetailMainRightToggleDocument id="toggleTaskDoc" icon="filter"/>
            </>
            : null

    )
}))


@inject('rootStore')
@observer
export class GembaDetailView extends React.Component<IBaseView, {}> {

    @action.bound
    gotoTask() {
        const {rootStore: {routerStore, gembaStore: {dsAction,}}} = this.props;
        routerStore.goTo('action', {no: dsAction.actual.no})
    }

    @action.bound
    gotoReport() {
        const {rootStore: {routerStore, gembaStore: {ds}}} = this.props;
        routerStore.goTo('gembareport', {
            gembano: ds.actual.gembano
        })
    }

    @action.bound
    gotoProject() {
        const {rootStore: {routerStore, gembaStore: {ds}}} = this.props;
        routerStore.goTo('project', {
            projectno: ds.actual.projectno
        })
    }

    @action.bound
    goToCollect() {
        const {rootStore: {routerStore, gembaStore: {ds}}} = this.props;
        routerStore.goTo('gembacollectonline', {
            gembano: ds.actual.gembano,
        });
    }


    getTabs = (): ITab[] => {
        const {rootStore: {gembaStore: {dsAction}}} = this.props;
        return [
            {
                title: 'GEMBA_FINDING',
                icon: 'images',
                content: <GembaDetailObservation/>,
                menu: <GembaDetailObservationMenu/>
            },
            {
                title: 'ACTIONS',
                icon: "tasks",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid
                            ds={dsAction}
                            onRowDoubleClicked={this.gotoTask}
                        />
                    </div>
                ),
                menu: <TasksMenu/>
            },
        ]
    }

    extraMenu = () => {
        const {rootStore: {langStore: {lang}}} = this.props;
        return (
            <>
                <IconButton id="report" onClick={this.gotoReport}>
                    <FontAwesomeIcon icon={["fad", "print"]}/>
                </IconButton>
                <UncontrolledTooltip placement="bottom" target="report">
                    {lang['REPORT']}
                </UncontrolledTooltip>
            </>
        )
    }


    @computed
    get hideMenu() {
        const {
            rootStore: {
                gembaStore: {ds, dsProject, saveResponsible},
                authStore: {username}
            }
        } = this.props;
        //console.log(dsProject, ds.state, dsProject.actual?.owner)
        return !((username === ds.actual?.responsible && ds.actual.status !== Status.APPROVED) || username === dsProject.actual?.owner || (ds.state === dsState.dsEdit && username === saveResponsible))
    }

    @computed
    get keyTitle() {
        const {rootStore: {gembaStore: {ds}}} = this.props;
        let rv1 = ds.actual?.gembano ?? ""
        let rv2 = ds.actual?.gemba ?? ""
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : ""
    }


    render() {
        const {
            rootStore: {
                gembaStore: {ds, dsGembaFinding, statusOptionsFunc},
                authStore: {username}
            }
        } = this.props;
        return (
            <DetailLayout
                topElement={
                    <TableForm
                        icon="walking"
                        title='GEMBA'
                        ds={ds}
                        keyTitle={this.keyTitle}
                        open={false}
                        extraMenu={this.extraMenu()}
                        hideMenu={this.hideMenu}
                        ownerField={"responsible"}
                    >
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <DbField ds={ds} field="gembano"/>
                                        <DbField ds={ds} field="responsible"/>
                                        <DbField ds={ds} field="startdate"/>
                                    </Col>
                                    <Col>
                                        <DbField ds={ds} field="gemba"/>
                                        <DbField ds={ds} field="status" optionsFunc={statusOptionsFunc}/>
                                        <DbField ds={ds} field="duedate"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <DbField ds={ds} field="comment"/>
                                    </Col>
                                </Row>
                                <DbField ds={ds} field={"gantt"}/>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <DbField ds={ds} field="createdby"/>
                                        <DbField ds={ds} field="projectno" onClickLabel={this.gotoProject}/>
                                    </Col>
                                    <Col>
                                        <DbField ds={ds} field="createdat"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DbField ds={ds} field={"supplierno"} readOnly={true}/>
                                    </Col>
                                    <Col>
                                        <DbField ds={ds} field={"supplier1"} readOnly={true}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TableForm>
                }
                mainLeftElement={
                    <TableGrid
                        icon="list"
                        id="activity"
                        title='GEMBA_FINDINGS'
                        ds={dsGembaFinding}
                        hideInsert={ds.actual?.responsible !== username}
                        hideDelete={ds.actual?.responsible !== username}
                        //onRowDoubleClicked={this.gotoActivity}

                    />
                }
                mainRightElement={<CardWithTab tabs={this.getTabs()}/>}
            />
        )
    }


}