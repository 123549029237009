import * as React from "react";
import {action, observable, runInAction} from 'mobx';
import {inject, observer} from "mobx-react";
import {Button,} from 'reactstrap';
import {Dialog} from '../../dialog/Dialog';
import {DbGrid} from '../../dbgrid/db-grid';
import {Dataset} from '../../dataset/dataset';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IFieldProps} from "../i-db-field-props";
import {MenuInsert} from "../../../views/_base/menu/menu-insert";
import {MenuClear} from "../../../views/_base/menu/menu-clear";


@inject('rootStore')
@observer
export class FieldSelectdlgButton extends React.Component<IFieldProps<any>, any> {

    @observable isDialogOpen: boolean;

    selectDs: Dataset<any>;

    constructor(props: IFieldProps<any>) {
        super(props);
        // warum werden url und columns noch nicht gesetzt?
        this.selectDs = new Dataset<any>('', []);
    }

    componentWillUnmount() {
        this.selectDs.close();
    }


    @action.bound
    onOkClick() {
        let {actual, column} = this.props;
        //console.log(column);
        actual[column.fieldName] = this.selectDs.actual[column.selectdlg.field ? column.selectdlg.field : column.fieldName];
        this.selectDs.close();
        this.isDialogOpen = false;
    };

    @action.bound
    onCancelClick() {
        this.selectDs.close();
        this.isDialogOpen = false;
    };

    @action.bound
    async onOpenClick() {
        let {actual, column, rootStore} = this.props;
        this.selectDs.dataUrl = column.selectdlg.url;
        this.selectDs.columns = column.selectdlg.columns;
        // @ts-ignore
        this.selectDs.filter = typeof column.selectdlg.filter === 'function' ? column.selectdlg.filter(actual, column.fieldName, rootStore) :
            column.selectdlg.filter ? column.selectdlg.filter : {};
        //console.log(toJS(actual),this.selectDs.filter)
        await this.selectDs.open();
        runInAction(() => {
            //console.log(column.selectdlg.field ? column.selectdlg.field : column.fieldName);
            //console.log(ds.actual[column.fieldName])
            this.selectDs.locate([column.selectdlg.field ? column.selectdlg.field : column.fieldName], {[column.selectdlg.field ? column.selectdlg.field : column.fieldName]: actual[column.fieldName]});
            this.isDialogOpen = true;
        });
    };

    render() {
        let {rootStore: {authStore: {hasUserRight}}, readOnly, actual, column} = this.props;
        //console.log(column.selectdlg.InserRight)
        return (
            <>
                <Button
                    onClick={this.onOpenClick}
                    disabled={readOnly}
                    size="sm">
                    <FontAwesomeIcon icon="caret-down"/>
                </Button>
                <Dialog
                    title='Dataselect'
                    isOpen={this.isDialogOpen}
                    onCancel={this.onCancelClick}
                    onOk={this.onOkClick}
                    extraButtons={(
                        <>
                            <div>
                                {column.selectdlg.InserRight ? hasUserRight(column.selectdlg.InserRight) ?
                                    <><MenuInsert ds={this.selectDs} id="selectinsert"/>&nbsp;</> :
                                    null :
                                    null
                                }
                                <MenuClear actual={actual} column={column} onClick={this.onCancelClick}/>
                            </div>
                        </>
                    )}
                >
                    <div style={{height: "300px"}}>
                        <DbGrid ds={this.selectDs} onRowDoubleClicked={this.onOkClick}/>
                    </div>
                </Dialog>
            </>
        );
    }
}

