import * as React from 'react';
import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {action, computed} from "mobx";
import {ProjectActivityTyp} from "../../../../models/ProjectActivity";
import {ProjectActivityGridMenu} from "./project-activity-menu";
import {DetailLayout} from "../../../_base/styled/layout/detail-layout";
import {TableGrid} from "../../../_base/styled/table-grid";
import {DbGrid} from "../../../../components/dbgrid/db-grid";
import {CardWithTab, ITab} from "../../../_base/styled/card-with-tab";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {MenuInsert} from "../../../_base/menu/menu-insert";
import {ProjectDetailForm} from "./project-detail-form";
import {MenuFileUpload} from "../../../_base/menu/menu-file-upload";
import {ProjectDetailMainLeftMenu} from "./project-detail-document-menu";
import {IconButton} from "../../../_base/styled/icon-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UncontrolledTooltip} from "reactstrap";
import {ProjectMemberExtraMenu} from "./project-member-extra-menu";
import {UsergroupRight} from "../../../../models/rights/enum-usergrouprights";


@inject('rootStore')
@observer
export class ProjectDetailView extends React.Component<IBaseView, {}> {

    @action.bound
    goToAction() {
        const {rootStore: {routerStore, projectStore: {dsAction,}}} = this.props;
        routerStore.goTo('action', {no: dsAction.actual.no})
    }

    @action.bound
    goToTask() {
        const {rootStore: {routerStore, projectStore: {dsProjectActivity,}}} = this.props;
        routerStore.goTo('projecttask', {
            projectno: dsProjectActivity.actual.projectno,
            taskno: dsProjectActivity.actual.activityno
        })
    }


    @action.bound
    gotoActivity() {
        const {rootStore: {routerStore, projectStore: {dsProjectActivity}}} = this.props;
        switch (dsProjectActivity.actual?.activitytyp) {
            case ProjectActivityTyp.ACTIVITY:
                routerStore.goTo('projectactivity', {
                    projectno: dsProjectActivity.actual.projectno,
                    activityno: dsProjectActivity.actual.activityno
                });
                break;
            case ProjectActivityTyp.AUDIT:
            case ProjectActivityTyp.DEVELOPMENT:
                routerStore.goTo('audit', {
                    auditno: dsProjectActivity.actual.link,
                });
                break;

            case ProjectActivityTyp.INSPECTION:
                routerStore.goTo('inspection', {
                    inspectionno: dsProjectActivity.actual.link,
                });
                break;
            case ProjectActivityTyp.PPAP:
                routerStore.goTo('ppaporder', {
                    ppapno: dsProjectActivity.actual.link,
                });
                break;
            case ProjectActivityTyp.GEMBA_WALK:
                routerStore.goTo('gemba', {
                    gembano: dsProjectActivity.actual.link,
                });
                break;
        }
    }


    getTabs = (): ITab[] => {

        const {
            rootStore: {
                authStore: {username, hasUserRight},
                langStore: {lang},
                projectStore: {
                    ds,
                    dsProjectActivity,
                    dsAction,
                    dsProjectDocument,
                    dsProjectMember,
                    uploadFile,
                    toggleActionFilter,
                    actionByActivityFilter
                },
            }
        } = this.props;

        return [
            {
                title: 'PROJECT_MEMBER',
                icon: "user-friends",
                content: (<div style={{position: 'relative', height: '100%'}}><DbGrid ds={dsProjectMember}/></div>),
                menu:
                    <div>
                        {username === ds.actual?.owner ?
                            <>
                                <MenuInsert id={"PROJECT_MEMBER_btn-insert"} ds={dsProjectMember}/>
                                <MenuDelete id={"PROJECT_MEMBER_btn-delete"} ds={dsProjectMember}/>
                                {hasUserRight(UsergroupRight.UR_PROJECT_IMPORT_MEMBER) ?
                                    <ProjectMemberExtraMenu/> :
                                    null
                                }
                            </> :
                            null
                        }
                    </div>
            },
            {
                title: 'PROJECT_DOCUMENTS',
                icon: "file",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsProjectDocument}/></div>),
                menu:
                    <div>
                        {
                            (
                                username === dsProjectActivity.actual?.responsible ||
                                username === ds.actual?.owner ||
                                username === ds.actual?.customerresponsible
                            ) ?
                                <>
                                    {dsProjectActivity.cursor !== undefined ?
                                        <MenuFileUpload
                                            id={"PROJECT_DOCUMENTS_btn-insert"}
                                            onDrop={uploadFile}
                                        /> :
                                        null
                                    }
                                    {
                                        dsProjectDocument.cursor !== undefined ?

                                            <MenuDelete
                                                id={"PROJECT_DOCUMENTS_btn-delete"}
                                                ds={dsProjectDocument}
                                            />
                                            :
                                            null


                                    }

                                </> :
                                null
                        }
                        <ProjectDetailMainLeftMenu id="Docfilter" icon="filter"/>
                    </div>
            },
            {
                title: 'ACTIONS',
                icon: "tasks",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsAction} onRowDoubleClicked={this.goToAction}/>
                    </div>)
                ,
                menu: <>
                    <IconButton onClick={toggleActionFilter} id={"btn-action-filter"}>
                        {actionByActivityFilter ? <FontAwesomeIcon icon="filter-list"/> :
                            <FontAwesomeIcon icon="filter"/>}
                    </IconButton>
                    <UncontrolledTooltip placement="bottom-end" target={"btn-action-filter"}>
                        {lang.FILTER}
                    </UncontrolledTooltip>
                </>
            },


        ]
    }

    renderExtraMenu = () => {
        const {rootStore: {projectStore: {ds}, authStore: {username}, langStore: {lang}}} = this.props;
        return ds.actual?.owner === username ?
            <>
                <IconButton id="xx">
                    <FontAwesomeIcon icon={["fad", "key"]}/>
                </IconButton>
                <UncontrolledTooltip placement="bottom-end" target={"xx"}>
                    {lang['HANDOVER']}
                </UncontrolledTooltip>
            </> :
            null
    }

    @computed
    get keyTitle() {
        const {rootStore: {projectStore: {ds},}} = this.props;
        let rv1 = ds.actual?.projectno ?? ""
        let rv2 = ds.actual?.project ?? ""
        return rv1 && rv2 ? rv1 + ' - ' + rv2 : rv1
    }

    render() {
        const {rootStore: {projectStore: {ds, dsProjectActivity}}} = this.props;
        return (
            <DetailLayout
                topElement={
                    <ProjectDetailForm
                        icon="project-diagram"
                        title='PROJECT'
                        ds={ds}
                        keyTitle={this.keyTitle}
                        open={false}
                        /*extraMenu={this.renderExtraMenu()}*/
                    />
                }

                mainLeftElement={
                    <TableGrid
                        icon="chart-network"
                        id="activity"
                        title='PROJECTTASK_ACTIVITIES'
                        ds={dsProjectActivity}
                        onRowDoubleClicked={this.gotoActivity}
                        menu={<ProjectActivityGridMenu id="activity"/>}
                    />
                }

                mainRightElement={
                    <CardWithTab
                        tabs={this.getTabs()}
                    />
                }
            />
        )
    }
}


