import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import styled from "styled-components";


export class _AuditIconRenderer extends React.Component<any, any> {
    render() {
        const {data: {isdevelopment}} = this.props;
        return (
            <FontAwesomeIcon icon={isdevelopment ? ["fad", "chart-line"] : ["fad", "eye"]}/>
        )
    }
}


export const AuditIconRenderer = styled(_AuditIconRenderer)`
  padding: 1px 5px;
  border-radius: 6px;
  display: inline-block;
  width: 26px;
  line-height: 16px;
  text-align: center;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-top: 4px;
  margin-left: -5px;
  margin-right: 0;
`;