import React from 'react';
import {IBaseView} from "../../../_base/base-view";
import ReactEcharts from "echarts-for-react";
import {ICatValue} from "../../../../components/graphic/bar-graph";
import {inject, observer} from "mobx-react";
import {cl_blue} from "../../../_theme/colors";

interface IDashboardPpapElementStatus extends IBaseView {
    catValues: ICatValue[];
}

@inject('rootStore')
@observer
export class DashboardPpapElementStatusChart extends React.Component<IDashboardPpapElementStatus, {}> {


    getOption = (): echarts.EChartOption => {
        const {catValues} = this.props;

        return {
            grid: {
                top: '0%',
                left: '50%',
                right: '13%',
                bottom: '0%',
                containLabel: false,
                width: 'auto',
                height: 'auto'
            },
            yAxis: {
                type: 'category',
                data: catValues.map((cv) => cv.category),
                axisLabel: {
                    fontSize: 9,
                    fontWeight: 400,
                    color: cl_blue,
                    margin: 4,
                    rotate: 0,
                },
                axisTick: {
                    show: true,


                }
            },
            xAxis: {
                type: 'value',
                show: false,

            },
            series: [{
                data: catValues.map((cv) => {
                    return {
                        value: cv.value,
                        label: {
                            show: !!cv.value,
                            position: 'right',
                            fontSize: 9,
                            color: cl_blue,
                            fontWeight: 400,
                        },
                        itemStyle: {
                            color: cv.color
                        }
                    }
                })
                ,
                type: 'bar',
                cursor: 'default',

            }]
        };

    };

    render() {

        return (
            <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                option={this.getOption()}
                style={{height: '100%', width: '100%'}}
                opts={{renderer: 'svg'}}
            />
        )
    }
}