import {BaseStore} from "./base-store";
import {ILogbook, logbookColumns} from "../models/Logbook";
import {action} from "mobx";
import {RouterState} from "mobx-state-router";


export class LogbookStore extends BaseStore<ILogbook> {

    constructor(rootStore: any) {
        super(rootStore, '/gridApi/logbook/', logbookColumns);
    }

    @action.bound
    async onEnter(fromState: RouterState, toState: RouterState) {
        await this.loadFilter('logbook');
        await this.open(this.cdsFilter.actual);
    }

    @action.bound
    async onExit(fromState: RouterState, toState: RouterState) {
        await this.close();
    }

}