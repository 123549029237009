import {IField} from "../components/dataset/IField";
import {productColumns} from "./Product";

export interface IPPAPPartFamily {
    ppapno: string;
    productno: string;
    description1: string;
    description2: string;
    prodgroup: string;
    ppap_family: string;
    drawingno: string;
    spezification: string;
    revision: string;
    revision_date: string;

}

export const ppapPartFamilyColumns: IField<IPPAPPartFamily> [] = [
    {
        fieldName: 'ppapno',
        dataType: 'string',
        label: 'PPAP_ORDERNO',
        primaryKey: true,
        insert: 'hide',
        hide: 'table'
    },

    {
        fieldName: 'productno',
        dataType: 'string',
        label: 'PRODUCT_PRODUCTNO',
        primaryKey: true,
        rules: ['pk'],
        input: 'selectdlg',
        selectdlg: {
            url: '/gridApi/product/',
            columns: productColumns,
            field: 'productno',

        },
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'revision',
        dataType: 'string',
        label: 'PRODUCT_REVISION',
        grid: {
            width: 80
        }
    },
    {
        fieldName: 'revision_date',
        dataType: 'string',
        label: 'PRODUCT_REVISIONDATE',
        hide: 'table'
    },
    {
        fieldName: 'description1',
        dataType: 'string',
        label: 'PRODUCT_DESCRIPTION1',
        grid: {
            flex: 1
        }
    },
    {
        fieldName: 'description2',
        dataType: 'string',
        label: 'PRODUCT_DESCRIPTION2',
        hide: 'always',
    },
    {
        fieldName: 'prodgroup',
        dataType: 'string',
        label: 'PRODUCT_PRODUCTGROUP',
        hide: 'always',
    },
    {
        fieldName: 'ppap_family',
        dataType: 'string',
        label: 'PRODUCT_PPAP_FAMILY',
        input: 'input',
        hide: "table"
    },
    {
        fieldName: 'drawingno',
        dataType: 'string',
        label: 'PRODUCT_DRAWINGNO',
    },
    {
        fieldName: 'spezification',
        dataType: 'string',
        label: 'PRODUCT_SPEZIFICATION',
    },

]