import {ILang, ILangKeys} from "../../language/ILang";


interface IGroupChild {
    field: string;
    label?: ILangKeys;
}

export interface IGroupDef {
    field?: string;
    headerName?: ILangKeys;
    children?: IGroupChild[];
}

// Achtung: die coldef von Ag-Grid, NICHT die IField
export const makeGroupDef = (columns: any[], groups: IGroupDef[], lang: ILang) => {
    return groups.map((groupItem) =>
        groupItem.children ?
            {
                headerName: lang[groupItem.headerName],
                children: groupItem.children.map((groupItem: any) => columns.find((column) => column.field === groupItem.field))
            } :
            columns.find((column) => column.field ===
                groupItem.field)
    )
}