import * as React from 'react';

import {inject, observer} from "mobx-react";
import {IBaseView} from "../../../_base/base-view";
import {action} from "mobx";
import {DetailLayout} from "../../../_base/styled/layout/detail-layout";
import {TableForm} from "../../../_base/styled/table-form";
import {ProjectDetailForm} from "../detail/project-detail-form";
import {Col, Row} from "reactstrap";
import {DbField} from "../../../../components/dbform/db-field";
import {CardWithTab, ITab} from "../../../_base/styled/card-with-tab";
import {DbGrid} from "../../../../components/dbgrid/db-grid";
import {Status} from "../../../../models/Status";
import {MenuInsert} from "../../../_base/menu/menu-insert";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import {
    AuditDetailMainRightToggleDocument
} from "../../../audit/order/order/main-right/audit-detail-main-right-toggle-document";
import {MenuFileUpload} from "../../../_base/menu/menu-file-upload";
import {ProjectDetailMainLeftMenu} from "../detail/project-detail-document-menu";

const TasksMenu = inject('rootStore')(observer((props: IBaseView) => {
        const {rootStore: {projectStore: {dsProjectActivity, dsAction}, authStore: {username}}} = props;
        return (<>
                {username === dsProjectActivity.actual?.responsible && dsProjectActivity.actual.status !== Status.APPROVED ?
                    <>
                        <MenuInsert id={"ACTIONS_btn-insert"} ds={dsAction}/>
                        {
                            dsAction.cursor !== undefined ?
                                <MenuDelete id={"ACTIONS_btn-delete"} ds={dsAction}/> :
                                null
                        }
                    </>
                    : null
                }
                <AuditDetailMainRightToggleDocument id="toggleTaskDoc" icon="filter"/>
            </>


        )
    }
))

@inject('rootStore')
@observer
export class ProjectDetailActivityView extends React.Component<IBaseView, {}> {

    @action.bound
    goToTask() {
        const {rootStore: {routerStore, projectStore: {dsAction,}}} = this.props;
        routerStore.goTo('action', {no: dsAction.actual.no})
    }

    getTabs = (): ITab[] => {

        const {
            rootStore: {
                authStore: {username},
                projectStore: {ds, dsAction, dsProjectActivity, dsProjectDocument, uploadFile},
            }
        } = this.props;

        return [
            {
                title: 'PROJECT_DOCUMENTS',
                icon: "file",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid ds={dsProjectDocument}/></div>),
                menu:
                    <div>
                        {
                            (
                                username === dsProjectActivity.actual?.responsible ||
                                username === ds.actual?.owner ||
                                username === ds.actual?.customerresponsible
                            ) ?
                                <>
                                    {dsProjectActivity.cursor !== undefined ?
                                        <MenuFileUpload
                                            id={"PROJECT_DOCUMENTS_btn-insert"}
                                            onDrop={uploadFile}
                                        /> :
                                        null
                                    }
                                    {
                                        dsProjectDocument.cursor !== undefined ?

                                            <MenuDelete
                                                id={"PROJECT_DOCUMENTS_btn-delete"}
                                                ds={dsProjectDocument}
                                            />
                                            :
                                            null


                                    }

                                </> :
                                null
                        }
                        <ProjectDetailMainLeftMenu id="Docfilter" icon="filter"/>
                    </div>
            },
            {
                title: 'ACTIONS',
                icon: "tasks",
                content: (
                    <div style={{position: 'relative', height: '100%'}}>
                        <DbGrid
                            ds={dsAction}
                            onRowDoubleClicked={this.goToTask}
                        />

                    </div>
                ),
                menu: <TasksMenu/>
            }
        ]
    }

    render() {
        const {
            rootStore: {
                projectStore: {ds, dsProjectActivity, statusActivityOptionsFunc},
                authStore: {username}
            }
        } = this.props;
        return (
            <DetailLayout
                topElement={<ProjectDetailForm
                    icon="project-diagram"
                    title='PROJECT'
                    ds={ds}
                    keyTitle={ds.actual?.projectno + " - " + ds.actual?.project}
                    open={false}
                />}
                mainLeftElement={<TableForm
                    icon="chart-network"
                    title='PROJECTTASK_ACTIVITY'
                    ds={dsProjectActivity}
                    hideMenu={username !== dsProjectActivity.actual?.responsible && username !== ds.actual?.owner}
                    ownerField={"responsible"}
                >
                    <Row>
                        <Col>

                            <DbField ds={dsProjectActivity} field={"activityno"}/>
                            <DbField ds={dsProjectActivity} field={"status"} optionsFunc={statusActivityOptionsFunc}/>
                            <DbField ds={dsProjectActivity} field={"startdate"}/>
                            <DbField ds={dsProjectActivity} field={"duedate"}/>
                            <DbField ds={dsProjectActivity} field="createdby"/>
                            <DbField ds={dsProjectActivity} field="createdat"/>
                        </Col>
                        <Col>
                            <DbField ds={dsProjectActivity} field={"activitytyp"}/>
                            <DbField ds={dsProjectActivity} field={"description1"}/>
                            <DbField ds={dsProjectActivity} field={"responsible"}/>
                            <DbField ds={dsProjectActivity} field={"comment"}/>
                            <DbField ds={dsProjectActivity} field={"gantt"}/>
                        </Col>
                    </Row>
                </TableForm>
                }
                mainRightElement={<CardWithTab tabs={this.getTabs()}/>}

            />
        )
    }
}

