import {observer} from "mobx-react";
import {StyledHeadline} from "./styled-headline";
import {StyledTable} from "./styled-table";
import * as React from "react";
import {IPpapOrderDocument} from "../../../../../models/PpapDocument";
import {DocumentEditor} from "./document-editor";


interface IParticipating {
    document: IPpapOrderDocument;
    readonly: boolean;
}

export const Participating = observer(({document, readonly}: IParticipating) =>
    <>
        <StyledHeadline style={{margin: 0, fontWeight: 'bold'}}>Participating</StyledHeadline>
        <StyledTable width={'80%'}>
            <thead>
            <tr>
                <td style={{width: '33%'}}>Name</td>
                <td style={{width: '33%'}}>Function</td>
                <td style={{width: '34%'}}>Company</td>
            </tr>
            </thead>
            <tbody>
            {
                ['01', '02', '03', '04', '05'].map((zaehler: string, index) =>
                    <tr key={index}>
                        <td>
                            <DocumentEditor
                                document={document}
                                fieldName={"name" + zaehler}
                                readonly={readonly}
                            />
                        </td>
                        <td>
                            <DocumentEditor
                                document={document}
                                fieldName={"function" + zaehler}
                                readonly={readonly}
                            />
                        </td>
                        <td>
                            <DocumentEditor
                                document={document}
                                fieldName={"company" + zaehler}
                                readonly={readonly}
                            />
                        </td>
                    </tr>
                )
            }
            </tbody>
        </StyledTable>
    </>
)