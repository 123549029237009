import {IField} from "../components/dataset/IField";
import {enumToArray} from "../components/lib/enumToArray";
import {ILangKeys} from "../language/ILang";


export enum PpapFamilyTyp {
    PART_FIELD = 0,
    PPAP_INDIVIDUAL = 1
}

const ppapFamilyTypText = (typ: PpapFamilyTyp): ILangKeys => {
    switch (typ) {
        case PpapFamilyTyp.PART_FIELD:
            return 'CONFIG_PPAP_FAMILY_PART_FIELD';
        case PpapFamilyTyp.PPAP_INDIVIDUAL:
            return 'CONFIG_PPAP_FAMILY_PPAP_INDIVIDUAL';
    }

}


export interface IConfig {
    cfgno: number;
    sendemail: boolean;
    emailonaction: boolean;
    emailonppap: boolean;
    emailonproject: boolean;
    emailonactivity: boolean;
    emailonaudit: boolean;
    emailoninspection: boolean;
    emailongemba: boolean;
    ppap_family_typ: number;
}

export const configColumns: IField<IConfig>[] = [
    {
        fieldName: 'cfgno',
        dataType: 'number'
    },
    {
        fieldName: 'sendemail',
        label: 'CONFIG_EMAIL_NOTIFICATION',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailonproject',
        label: 'CONFIG_EMAIL_NEW_PROJECT',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailonactivity',
        label: 'CONFIG_EMAIL_NEW_ACTIVITY',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailonaction',
        label: 'CONFIG_EMAIL_NEW_ACTION',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailonppap',
        label: 'CONFIG_EMAIL_NEW_PPAP',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailonaudit',
        label: 'CONFIG_EMAIL_NEW_AUDIT',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailoninspection',
        label: 'CONFIG_EMAIL_NEW_INSPECTION',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'emailongemba',
        label: 'CONFIG_EMAIL_NEW_GEMBA',
        dataType: 'boolean',
        input: 'checkbox'
    },
    {
        fieldName: 'ppap_family_typ',
        label: 'CONFIG_PPAP_FAMILY',
        dataType: 'number',
        input: 'radio',
        options: enumToArray(PpapFamilyTyp),
        textFunc: ppapFamilyTypText
    },
];