import React from 'react';
import {IBaseView} from "../../_base/base-view";
import {Button, Collapse, ListGroup, ListGroupItem} from "reactstrap";
import {action} from "mobx";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ITreeNode} from "../../../stores/usergroup-store";
import {cl_green, cl_red} from "../../_theme/colors";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";


interface ITreeView extends IBaseView {
    readOnly: boolean;
    nodes: ITreeNode[];
    setRight: (right: UsergroupRight, enabled: boolean) => void;
}

@inject('rootStore')
@observer
export class _TreeView extends React.Component<ITreeView, any> {

    openCloseToggler = (node: ITreeNode) => action(() => {
        node.isOpen = !node.isOpen;
    });

    switcher = (node: ITreeNode) => {
        const {setRight, readOnly} = this.props;
        return () => {
            if (!readOnly) {
                setRight(node.id, !node.isEnabled);
            }
        };
    }


    mapper = (nodes: ITreeNode[], parentId?: ITreeNode, level?: any) => {
        const {rootStore: {langStore: {lang}, authStore: {hasModuleRight}}} = this.props;
        return nodes
            .filter((node: ITreeNode) => node.module ? hasModuleRight(node.module) : true) // blendet nicht lizensierte Module aus.
            .map((node: ITreeNode) =>
                <React.Fragment key={node.id}>
                    <ListGroupItem style={{zIndex: 0}}
                                   className={`${parentId ? `rounded-0  : ''}` : ''}`}>
                        {<div style={{paddingLeft: `${25 * level}px`}}>
                            {node.nodes &&
                            <Button className="pl-0"
                                    onClick={this.openCloseToggler(node)}
                            >
                                {node.isOpen ? <FontAwesomeIcon icon="caret-down"/> :
                                    <FontAwesomeIcon icon="caret-right"/>}
                            </Button>}
                            {lang[node.text]}
                            &nbsp;&nbsp;
                            <Button className="pl-0" onClick={this.switcher(node)}>
                                {node.isEnabled ? <FontAwesomeIcon icon="toggle-on" color={cl_green}/> :
                                    <FontAwesomeIcon icon="toggle-on" color={cl_red} flip="horizontal"/>}
                            </Button>
                        </div>}
                    </ListGroupItem>
                    {node.nodes &&
                    <Collapse isOpen={node.isOpen}>
                        {this.mapper(node.nodes, node, (level || 0) + 1)}
                    </Collapse>}
                </React.Fragment>
            );
    }

    render() {
        const {nodes, className} = this.props;
        return (
            <ListGroup className={className}>
                {this.mapper(nodes)}
            </ListGroup>
        )
    }
}


export const TreeView = styled(_TreeView)`
  .list-group-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    font-size: 12px;
    border-bottom: ${props => props.theme.border};
    border-left: 0;
    border-right: 0;
    border-top: 0;
    color: ${props => props.theme.color};

  }

  .btn {
    background-color: #fff;
    color: #000;
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .btn:focus {
    outline: none;
    box-shadow: none;
  }
`;