import {IBaseView} from "../../../_base/base-view";
import {TableEditMenu} from "../../../_base/styled/table-edit-menu";
import {Status} from "../../../../models/Status";
import {dsState} from "../../../../components/dataset/IDataset";
import {MenuFileUpload} from "../../../_base/menu/menu-file-upload";
import {acceptImageMime} from "../../../../lib/image-mime";
import {MenuDelete} from "../../../_base/menu/menu-delete";
import * as React from "react";
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
export class GembaDetailObservationMenu extends React.Component<IBaseView, {}> {

    render() {
        const {
            rootStore: {
                authStore: {username},
                gembaStore: {ds, dsGembaFinding, dsImage, uploadFile},
            }
        } = this.props;
        return (
            <>
                <TableEditMenu ds={dsGembaFinding}
                               hidden={!(username === ds.actual?.responsible && ds.actual.status !== Status.APPROVED && dsGembaFinding.cursor > -1)}/>

                {
                    dsGembaFinding.state === dsState.dsBrowse && dsGembaFinding.cursor > -1 && username === ds.actual?.responsible ?
                        <>
                            <MenuFileUpload
                                id={"PROJECT_DOCUMENTS_btn-insert"}
                                onDrop={async (acceptFile: any[]) => {
                                    await uploadFile(acceptFile, dsGembaFinding.actual)
                                }}
                                accept={acceptImageMime}
                            />

                            {
                                dsImage.cursor > -1 ?
                                    <MenuDelete
                                        id={"PROJECT_DOCUMENTS_btn-delete"}
                                        ds={dsImage}
                                    /> :
                                    null
                            }

                        </> :
                        null
                }
            </>
        )
    }
}