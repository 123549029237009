import {inject, observer} from "mobx-react";
import * as React from "react";
import {IBaseView} from "../../_base/base-view";
import {action, toJS} from "mobx";
import {DetailOneColLayout} from "../../_base/styled/layout/detail-one-col-layout";
import {TableForm} from "../../_base/styled/table-form";
import {TableGrid} from "../../_base/styled/table-grid";
import {ppapStandardElementGroupDef} from "../../../models/PpapStandardElement";
import {CsvButtonUpload} from "../../_base/csv/csv-button-upload";
import axios from "axios";
import {authorizer} from "../../../components/dataset/authorizer";
import {CsvButtonDownload} from "../../_base/csv/csv-button-download";


@inject('rootStore')
@observer
export class PpapTemplateDetailView extends React.Component<IBaseView, any> {

    @action.bound
    gotoElement() {
        const {rootStore: {routerStore, ppapStandardStore: {dsPpapMatrixDetail}}} = this.props;
        routerStore.goTo('ppapstandardelement', {
            standardno: dsPpapMatrixDetail.actual.standardno,
            elementno: dsPpapMatrixDetail.actual.elementno.toString()
        })
    }

    @action.bound
    async uploadCsv(rawdata: Array<any>) {
        console.log(rawdata)
        const {rootStore: {ppapStandardStore: {ds, dsPpapMatrixDetail}}} = this.props;
        const fields = rawdata[0].data;
        const data = rawdata.filter((zeile: any, i: number) => i > 0)

        let newElements = data.map((record: any) => {
            let rv: any = {}
            fields.forEach((f: string, i: number) => {
                rv[fields[i]] = record.data[i]
            })
            rv['standardno'] = ds.actual.standardno;

            return rv
        })
            .filter((record) => record.standardno);

        newElements.forEach((element) => {
            console.log(toJS(element));
            axios.post('/gridApi/ppapstandardelement/', element, authorizer())
        });

        dsPpapMatrixDetail.close();
        await dsPpapMatrixDetail.open();
    }


    render() {
        const {rootStore: {ppapStandardStore: {ds, dsPpapMatrixDetail}}} = this.props;
        return (
            <DetailOneColLayout
                topElement={
                    <TableForm
                        icon="box-check"
                        title='PPAP_STANDARD'
                        keyTitle={ds.actual?.standardno}
                        ds={ds}
                        hideMenu={true}
                        /*colCount={2}*/
                    />}
                mainElement={
                    <TableGrid
                        id="detail"
                        icon="book-open"
                        title="PPAP_ELEMENTS"
                        ds={dsPpapMatrixDetail}
                        groupDef={ppapStandardElementGroupDef}
                        onRowDoubleClicked={this.gotoElement}
                        rightHeaderMenu={
                            <>
                                {
                                    dsPpapMatrixDetail.data.length > 0 ?
                                        null :
                                        <CsvButtonUpload onUpload={this.uploadCsv}/>
                                }
                                <CsvButtonDownload ds={dsPpapMatrixDetail}/>
                            </>
                        }
                    />
                }
            />
        )
    }
}