import React from "react";
import styled from "styled-components";
import {fileIcon} from "../../../lib/file-icon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as R from 'ramda';


const Image = styled.img`
  padding: 1px;
  width: 100%;
  height: 300px;
  object-fit: contain;
  object-position: 50% 50%;
`;

const Icon = styled.div`
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.color};
`;

const Image2 = ({src, title}: { src: string; title: string }) => {
    const icon = fileIcon(title);
    return (
        <>
            <div style={{height: '304px', border: '1px solid #666666', padding: '1px',}}>
                {src ?
                    icon ?
                        <Icon>
                            <FontAwesomeIcon size="10x" icon={["fad", icon]}/>
                        </Icon> :
                        <Image src={src} alt='img'/>
                    : null
                }
            </div>
            {src ?
                <span style={{textAlign: 'center'}}>
                <a href={src} download>{title}</a>
            </span> :
                null
            }
        </>
    )
}


export interface IReportImage {
    src: string;
    title: string;
}


const _ReportImages = ({images, className}: { images: IReportImage[], className?: string }) => {

    //console.log(R.range(0,(images.length + 1) % 2 + 1))
    //console.log(images)


    const rows = images.length ? R.range(0, Math.floor((images.length - 1) / 2) + 1) : [];
    //console.log('rows', rows, images.length)
    return (
        <table className={className}>
            <tbody>
            {images.length > 0 ?
                rows.map((row, index) => {
                    //console.log(2 * row);
                    return (<tr key={index}>
                            <td>
                                <Image2
                                    src={images[row * 2].src}
                                    title={images[row * 2].title}/>
                            </td>
                            <td>
                                {images.length > 2 * row + 1 ?
                                    <Image2
                                        src={images[2 * row + 1].src}
                                        title={images[2 * row + 1].title}/> :
                                    null
                                }
                            </td>
                        </tr>
                    )
                })
                :
                null
            }
            </tbody>
        </table>
    )

}

export const ReportImages = styled(_ReportImages)`
  width: 100%;

  td {
    padding: 1rem;
    width: 50%;
  }

`;