import * as React from "react";
import {inject, observer} from "mobx-react";
import {action} from "mobx";
import {AuditScore} from "../../../models/AuditQuestion";
import styled from "styled-components";
import {AuditCheckbox} from "./audit-checkbox";
import {IAuditRating} from "./audit-rating";
import {calcVDA61Score} from "../../../stores/audit-lib";


// Todo calculatedScore rauslösen

@inject('rootStore')
@observer
class _AuditRatingVda61 extends React.Component<IAuditRating, {}> {

    onChangeQm = (value: number) => action(() => {
        const {rootStore: {authStore: {username}}, audit, question, readOnly} = this.props;
        if (username === audit?.leadauditor && !readOnly) {
            question.qm = question.qm === value ? -1 : value;
            question.score = calcVDA61Score(question);
            question.notapplicable = false;
        }
    });

    onChangePraxis = (value: number) => action(() => {
        const {rootStore: {authStore: {username}}, audit, question, readOnly} = this.props;
        if (username === audit?.leadauditor && !readOnly) {
            question.praxis = question.praxis === value ? -1 : value;
            question.score = calcVDA61Score(question);
            question.notapplicable = false;
        }
    });

    @action.bound
    onChangeApplicable() {
        const {rootStore: {authStore: {username}}, audit, question, readOnly} = this.props;
        if (username === audit?.leadauditor && !readOnly) {
            question.notapplicable = !question.notapplicable;
            if (question.notapplicable) {
                question.qm = -1;
                question.praxis = -1;
                question.score = AuditScore.NOT_APPLICABLE;
            } else {
                question.score = AuditScore.UNRATED;
            }
        }
    }


    render() {
        const {question, className} = this.props;
        return (
            <div className={className}>
                <table>
                    <thead>
                    <tr>
                        <td/>
                        <th>no</th>
                        <th>predom.</th>
                        <th>yes</th>
                        <th>n/a</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>system</th>
                        <td>
                            <AuditCheckbox checked={question?.qm === 0}
                                           onChange={this.onChangeQm(0)}/>
                        </td>

                        <td/>

                        <td>
                            <AuditCheckbox checked={question?.qm === 2}
                                           onChange={this.onChangeQm(2)}/>
                        </td>
                        <td>
                            {question?.mandatory ?
                                '' :
                                <AuditCheckbox checked={question?.notapplicable}
                                               onChange={this.onChangeApplicable}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>praxis</th>
                        <td>
                            <AuditCheckbox checked={question?.praxis === 0}
                                           onChange={this.onChangePraxis(0)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.praxis === 1}
                                           onChange={this.onChangePraxis(1)}/>
                        </td>
                        <td>
                            <AuditCheckbox checked={question?.praxis === 2}
                                           onChange={this.onChangePraxis(2)}/>
                        </td>

                        <td/>

                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export const AuditRatingVda61 = styled(_AuditRatingVda61)`

  table {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
    vertical-align: middle;
  }

  thead {
    border-bottom: 1px solid black;
  }

  tbody {

    tr {
      height: 2rem;

      th {
        text-align: left;
      }
    }
  }


`;