import * as React from 'react';
import {inject, observer} from "mobx-react";
import {Button, CardHeader, Row} from "reactstrap";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IBaseView} from "../../_base/base-view";
import {historyAdapter} from "../../_layout/app";

@inject('rootStore')
@observer
export class _InspectionCollectHeaderTitle extends React.Component<IBaseView, {}> {

    goBack() {
        historyAdapter.goBack();
    }

    render() {
        const {rootStore: {inspectionStore: {ds, titleOpen, toggleTitleOpen}, langStore: {lang}}, className} = this.props;
        return (
            <CardHeader className={className}>
                <Row>
                    <div className={"left"}>
                        {titleOpen ?
                            <Button
                                color="primary"
                                style={{borderRadius: '50%'}}
                                onClick={this.goBack}
                            >
                                <FontAwesomeIcon icon="arrow-left"/>
                            </Button> :
                            null
                        }
                    </div>
                    <div className="center">
                        <h4>{lang.INSPECTION} -&nbsp;{ds.actual?.inspectionno}</h4>
                    </div>
                    <div className={"right"}>
                        <Button
                            color="primary"
                            style={{borderRadius: '50%'}}
                            onClick={toggleTitleOpen}
                        >
                            {titleOpen ?
                                <FontAwesomeIcon icon="caret-up"/> :
                                <FontAwesomeIcon icon="caret-down"/>
                            }

                        </Button>

                    </div>
                </Row>
            </CardHeader>

        )
    }
}

export const InspectionCollectHeaderTitle = styled(_InspectionCollectHeaderTitle)`
   padding:0.5rem;
   background-color:${props => props.theme.cardHeaderBg};
   color:${props => props.theme.cardHeaderColor};
   
   .center{
       flex: 1 1 auto;
       text-align:center;
   }
   .left{
    flex: 0 0 5rem;
    padding-left:1rem;
   }
   
   .right{
    flex: 0 0 4rem;
   }
   
  
`;