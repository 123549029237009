import * as React from 'react';
import {IBaseView} from "../../_base/base-view";
import {inject, observer} from "mobx-react";
import {TableGrid} from "../../_base/styled/table-grid";
import {TableLayout} from "../../_base/styled/layout/table-layout";
import {UsergroupRight} from "../../../models/rights/enum-usergrouprights";
import {BaseTableFilterMenu} from "../../_base/table-filter/base-table-filter-menu";


@inject('rootStore')
@observer
export class PpapOrderTableView extends React.Component<IBaseView, {}> {

    render() {
        const {rootStore: {ppapStore: {ds}, authStore: {hasUserRight}}} = this.props;
        return (
            <TableLayout>
                <TableGrid
                    id="ppaporder"
                    icon="box-check"
                    title='PPAP_ORDERS'
                    ds={ds}
                    singleRouteName='ppaporder'
                    rightHeaderMenu={
                        hasUserRight(UsergroupRight.UR_FILTER_ALL) ?
                            <BaseTableFilterMenu storeName="ppapStore"
                                                 filtername="ppap"/> :
                            null
                    }
                    hideInsert={true}
                    hideDelete={true}
                />
            </TableLayout>
        )
    }
}